
import { Options, prop, Vue } from "vue-class-component";
import { CnManifestController } from "@/app/ui/controllers/CnManifestController";
import debounce from "lodash/debounce";
import { PosController } from "@/app/ui/controllers/PosController";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";

export type ParentObject = {
  parentName: string;
  posName: string;
  commision: string;
  commisionType: string;
  numeratorCommission: string;
};

class Props {
  modelValue = prop<ParentObject>({
    required: true,
    type: Object,
  });
  error = prop<any>({
    required: true,
  });
  isEdit = prop<boolean>({
    default: false
  })
}

@Options({
  emits: ["update:modelValue", "update:error"],
})
export default class ClientBranchSection extends Vue.with(Props) {
  get isLoadingClient() {
    return CnManifestController.loadingClientPartnerOptions;
  }

  get isLoadingPOS() {
    return PosController.isLoading;
  }

  isOpenNumeratorCommission = false;
  onOpenSelectNumeratorCommission() {
    this.isOpenNumeratorCommission = true;
  }

  onCloseSelectNumeratorCommission() {
    this.isOpenNumeratorCommission = false;
  }

  onSelectNumeratorCommission(key: string, value: string) {
    this.onCloseSelectNumeratorCommission();
    this.modelValue.numeratorCommission = value;
  }

  isOpenCommissionType = false;
  onOpenSelectCommissionType() {
    this.isOpenCommissionType = true;
  }

  onCloseSelectCommissionType() {
    this.isOpenCommissionType = false;
  }

  onSelectCommissionType(key: string, value: string) {
    this.onCloseSelectCommissionType();
    this.modelValue.commisionType = value;
  }

  // numerator commission
  numeratorCommissionList = [
    {
      name: "Pilih pembilang komisi",
      value: "",
    },
    {
      name: "Harga Retail",
      value: "retail-rate",
    },
    {
      name: "Harga Client",
      value: "client-rate",
    },
  ];

  // clients
  filterClient = debounce((search: string) => {
    CnManifestController.fetchListClientOptions({
      page: 1,
      limit: 10,
      search: search,
      type: "client",
      isParent: true,
    });
  }, 250);

  filterPOS = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        search: search,
        cityCode: "",
        type: "pos",
        useLocation: true,
        useDefaultLocation: true,
      })
    );
  }, 250);

  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.|\.,|,,|,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  errorCaptionCommision = "";
  validateCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh lebih dari 100%";
    } else {
      this.error.commision = false;
    }
  }

  // commision types
  commisionTypes = [
    {
      name: "Pilih tipe komisi",
      value: "",
    },
    {
      name: "Shipping surcharge",
      value: "shipping_surcharge",
    },
    {
      name: "Publish Rate",
      value: "publish_rate",
    },
    {
      name: "Publish Rate + Shipping Surcharge",
      value: "publish_rate_shipping_surcharge",
    },
  ];

  get posList() {
    return PosController.posList.data.map((e) => ({
      id: e.id,
      name: `${e.name}`,
    }));
  }

  get clientList() {
    return CnManifestController.clientPartnerOptions.map((e) => ({
      id: e.id,
      name: `${e.name}`,
    }));
  }

  mounted(): void {
    this.filterClient("");
    this.filterPOS("");
  }
}
