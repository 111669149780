import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "my-2" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_DownloadAttachment = _resolveComponent("DownloadAttachment")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, { label: "Informasi Billing" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "No. WhatsApp",
        value: _ctx.detailData.clientBilling.formattedWhatsappNumber
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12 capitalize",
        label: "Jadwal Penagihan",
        value: _ctx.detailData.clientBilling.billingSchedule
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Jangka Waktu Pembayaran",
      value: _ctx.detailData.clientBilling.timeRangePayment + ' Hari'
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Email",
      value: _ctx.detailData.clientBilling.formattedEmail
    }, null, 8, ["value"]),
    _createVNode(_component_Title, { label: "Perjanjian Kerjasama" }),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Dimulai Dari",
        value: _ctx.detailData.formattedStartDateBilling()
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Sampai",
        value: _ctx.detailData.formattedEndDateBilling()
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Berkas Lampiran"
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_3, [
          (_ctx.detailData.clientBilling.attachmentUrl)
            ? (_openBlock(), _createBlock(_component_DownloadAttachment, {
                key: 0,
                dash: false,
                name: _ctx.detailData.clientBilling.fileNameAttachment,
                url: _ctx.detailData.clientBilling.attachmentUrl,
                maxLength: 100
              }, null, 8, ["name", "url"]))
            : (_openBlock(), _createBlock("p", _hoisted_4, "-"))
        ])
      ]),
      _: 1
    })
  ], 64))
}