
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";

class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement()
  });
  isForm = prop<boolean>({
    default: false,
    type: Boolean
  })
}
@Options({
  components: {
    Title
  }
})
export default class DetailDeliveryOrder extends Vue.with(Props) {
  get deliveryOrderPaymentType() {
    return new RegExp(/free/).exec(this.detailData.clientBranchDoPaymentType)
      ? "Gratis"
      : "Berbayar";
  }

  // delivery order
  dOOptions = [
    {
      name: "Gratis",
      value: "free",
    },
    {
      name: "Berbayar",
      value: "paid",
    },
  ];
  onSelectDO(option: any) {
    this.detailData.clientBranchDoPaymentType = option.value;
  }
}
