
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { Options, prop, Vue } from "vue-class-component";

export type AgreementSectionProp = {
  startDate: Date | string;
  endDate: Date | string;
  attachment: string;
  fileNameAttachment: string;
  attachmentUrl: string;
};

export type AgreementSectionErrorProp = {
  invalidDate: boolean;
};

class Props {
  modelValue = prop<AgreementSectionProp>({
    default: {
      attachment: "",
      attachmentUrl: "",
      endDate: "",
      fileNameAttachment: "",
      startDate: ""
    },
    required: true,
    type: Object
  });
  error = prop<AgreementSectionErrorProp>({
    default: {
      invalidDate: false
    },
    type: Object
  });
}

@Options({
  components: {
    UploadFile
  }
})
export default class AgreementSection extends Vue.with(Props) {
  get formModel() {
    return this.modelValue;
  }

  set formModel(val) {
    this.$emit("update:modelValue", val);
  }

  get formErrorModel() {
    return this.error;
  }

  set formErrorModel(val) {
    this.$emit("update:error", val);
  }

  get invalidDate() {
    const dateStart = new Date(this.modelValue.startDate);
    const dateEnd = new Date(this.modelValue.endDate);
    const isValid = Boolean(dateStart.getTime() > dateEnd.getTime());
    this.formErrorModel.invalidDate = isValid;
    return isValid;
  }
}
