
import { Options, prop, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import BankModal from "../client-registration/component/bank-modal.vue";
import { BankDetail } from "@/domain/entities/ClientRegistration";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";

class Props {
  isClearDefault = prop<boolean>({
    type: Boolean,
    default: true
  });
  type = prop<string>({
    type: String,
    default: "registration"
  });
  formType = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  components: {
    BankModal,
    Title
  }
})
export default class BankSettlementSection extends Vue.with(Props) {
  created() {
    this.setBankDetailSettlement();
    if (this.isClearDefault) {
      this.onDeleteBank();
    }
  }
  get isAddBank() {
    return ClientRegistrationController.isAddBankSettlement;
  }
  onAddBank() {
    ClientRegistrationController.setAddBankSettlement(true);
  }
  get bankDetail() {
    return ClientRegistrationController.bankDetailSettlement;
  }

  // delete
  isDelete = false;
  onConfirmDelete(value: boolean) {
    this.isDelete = value;
  }
  onDeleteBank() {
    this.onConfirmDelete(false);
    ClientRegistrationController.setBankDetailSettlement(new BankDetail());
  }

  setBankDetailSettlement() {
    ClientRegistrationController.setBankDetailSettlement(
      this.type === "registration"
        ? ClientRegistrationController.clientRegistrationDetail.bankSettlement
        : ClientManagementController.detailClientManagement.bankSettlement
    );
  }
}
