
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import CardSelector from "@/app/ui/components/card-selector/index.vue";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import debounce from "lodash/debounce";
import Checkbox from "primevue/checkbox";
import { PosController } from "@/app/ui/controllers/PosController";
import { CnManifestController } from "@/app/ui/controllers/CnManifestController";
import BankSection from "./bank-section.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import TaxSection from "../../component/tax-section.vue";
import BillingSection from "../../component/billing-section.vue";
import BankSettlementSection from "../../component/bank-settlement-section.vue";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import { BankDetail } from "@/domain/entities/ClientRegistration";
import convertArrayToString from "@/app/infrastructures/misc/common-library/ConvertArrayToString";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import capitalize from "lodash/capitalize";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import DetailDeliveryOrder from "@/app/ui/views/sales/clients/client-registration/detail/detail-delivery-order.vue";
import RadioButton from "primevue/radiobutton";
import ToggleV2 from "@/app/ui/components/toggle-v2/index.vue";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";

@Options({
  components: {
    DetailLayout,
    Title,
    CardSelector,
    UploadFile,
    Tooltip,
    Checkbox,
    BankSection,
    TaxSection,
    BillingSection,
    BankSettlementSection,
    DetailDeliveryOrder,
    RadioButton,
    ToggleV2,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess && !this.isErrorDocument) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ClientRegistrationController.setOpenModalSuccess(false);
      ClientRegistrationController.setErrorDocument(false);
      next();
    }
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    getClientName() {
      return this.$route.meta.form === "parent"
        ? "Detail Induk Client"
        : "Detail Cabang Client";
    },
    formType() {
      return this.$route.meta.form;
    }
  }
})
export default class Add extends Vue {
  mounted() {
    this.fetchVersionRates("");
    this.fetchDistrictList("");
    this.filterPOS("");
    this.filterClient("");
    this.form.priceRateStartDate = new Date(
      this.$moment()
        .add(1, "days")
        .toDate()
    );
    this.form.priceRateEndDate = new Date(
      this.$moment()
        .add(2, "days")
        .toDate()
    );
    ClientRegistrationController.setBankDetail(new BankDetail());
    ClientRegistrationController.setBankDetailSettlement(new BankDetail());
    PodDexController.getReasonList(new ReasonListDex({statusCode: '', reasonStatus: 'active', isCreateTicketSf: true}))
  }
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }
  // misc variable
  toolTipDiscount = false;
  form: any = {
    companyName: "",
    companySize: "",
    email: "",
    phone: "",
    address: "",
    taxNumber: "", //client branch
    tax: {
      NIK: "",
      NIKName: "",
      NIKAddress: "",
      isTaxPayer: false,
      taxNumber: "",
      transactionCode: "05 Besaran Tertentu"
    },
    billingInformation: {
      noWhatsApp: "",
      billingSchedule: "",
      timeRangePayment: "",
      email: [],
      startDate: "",
      endDate: "",
      attachment: ""
    },
    versionBasic: "",
    district: "",
    isCustomRate: 0,
    discount: "",
    priceRateStartDate: "",
    priceRateEndDate: "",
    versionName: "",
    archiveFile: "",
    contact: {
      name: "",
      level: "",
      email: "",
      phone: ""
    },
    clientCategory: "",
    parent: {
      parentName: "",
      posName: "",
      commision: "",
      commisionType: "",
      numeratorCommission: ""
    },
    cod: false,
    do: false,
    codRej: {
      receiverName: "",
      receiverAddress: "",
      district: "",
      commision: "",
      padCommision: "",
      phone: "",
      codAmountType: "",
      codShipmentDiscount: ""
    },
    paymentMethod: "",
    clientBranchDoPaymentType: "",
    clientIsDfod: false,
    isReturnConfig: false,
    returnConfigLimit: 7,
    returnConfigReasonIds: [{ "name": "Semua alasan", "value": "all" }],
  };
  error = {
    companyName: false,
    email: false,
    phone: false,
    address: false,
    district: false,
    versionBasic: false,
    discount: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    versionName: false,
    archiveFile: false,
    contactEmail: false,
    contactPhone: false,
    contactWhatsApp: false,
    commision: false,
    codRejdistrict: false,
    codRejCommision: false,
    codRejPhone: false,
    padCommision: false,
    emailBilling: false,
    NIKNumber: false,
    taxNumber: false,
    invalidDate: false,
    codRejShipmentDiscount: false
  };

  templateFile = "Download.csv";
  templateFileName = "template-client-custom-rate.csv";
  templateFileLink = `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-client-custom-rate-20210628.csv`;

  // download file
  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    downloadFile(url, newFileName, ".csv");
  }

  customPriceOption = [
    {
      label: "Harga Normal",
      value: 0,
      description:
        "Harga akan menyesuaikan dengan versi harga normal yang tersedia."
    },
    {
      label: "Harga Khusus",
      value: 1,
      description:
        "Versi harga normal dengan harga khusus untuk rute-rute tertentu."
    },
    {
      label: "Harga Kombinasi",
      value: 2,
      description: "Penggabungan harga normal dan harga khusus."
    }
  ];

  get bankDetail(): any {
    return ClientRegistrationController.bankDetail;
  }

  get bankDetailSettlement(): any {
    return ClientRegistrationController.bankDetailSettlement;
  }
  /* eslint-disable @typescript-eslint/camelcase */
  async _onAdd() {
    this.onCloseSave();
    this.handleErrorClose();
    let startDate = this.form.priceRateStartDate;
    startDate = new Date(
      startDate.setDate(startDate.getDate())
    ).toLocaleDateString("fr-CA");
    let endDate = this.form.priceRateEndDate;
    endDate = new Date(endDate.setDate(endDate.getDate())).toLocaleDateString(
      "fr-CA"
    );
    const add = await ClientRegistrationController.addClientRegistration({
      companyName: this.form.companyName,
      email: this.form.email,
      phone: convertPhoneNumber(this.form.phone),
      address: this.form.address,
      district: this.form.district,
      taxNumber:
        this.$route.meta.form === "parent"
          ? this.form.tax.taxNumber
          : this.form.taxNumber,
      versionBasic:
        this.form.isCustomRate === 1
          ? { rateVersionName: "" }
          : this.form.versionBasic,
      isCustomRate: this.form.isCustomRate === 2,
      isCustomRateOnly: this.form.isCustomRate === 1,
      discount: Number(this.form.discount.replace(/,/g, ".")),
      priceRateStartDate: startDate,
      priceRateEndDate: endDate,
      versionName: this.form.isCustomRate ? this.form.versionName : "",
      archiveFile: this.form.isCustomRate ? this.form.archiveFile : "",
      category: this.form.clientCategory,
      bankName: this.bankDetail.bankName.value || this.bankDetail.bankName,
      bankAccountName: this.bankDetail.accountName,
      bankAccountNumber: this.bankDetail.accountNo,
      bankAccountEmail: this.bankDetail.email,
      bankAccountLabel: this.bankDetail.bankLabel,
      contactName: this.form.contact.name,
      contactJobTitle: this.form.contact.level,
      contactEmail: this.form.contact.email,
      contactPhone: convertPhoneNumber(this.form.contact.phone),
      type: this.$route.meta.form === "parent" ? "parent" : "",
      company_size: capitalize(this.form.companySize),
      is_efaktur_with_nik: this.form.tax.isTaxPayer,
      nik_id: this.form.tax.NIK,
      nik_name: this.form.tax.NIKName,
      nik_address: this.form.tax.NIKAddress,
      taxpayer_name: this.form.tax.NIKName,
      taxpayer_address: this.form.tax.NIKAddress,
      billing_wa_number: convertPhoneNumber(
        this.form.billingInformation.noWhatsApp
      ),
      billing_email: convertArrayToString(
        this.form.billingInformation.email,
        "name"
      ),
      billing_schedule_so: this.form.billingInformation.billingSchedule.startsWith(
        "bi"
      )
        ? "Bi-Weekly"
        : capitalize(this.form.billingInformation.billingSchedule),
      billing_payment_periode: `${this.form.billingInformation.timeRangePayment} Days`,
      contract_start_date: formatDateNumber(
        this.form.billingInformation.startDate
      ),
      contract_end_date: formatDateNumber(this.form.billingInformation.endDate),
      contract_attachment: this.form.billingInformation.attachment,
      va_bank_name: this.bankDetailSettlement.bankName.name ?? "",
      va_bank_number: this.bankDetailSettlement.accountNo ?? "",
      va_bank_account_name: this.bankDetailSettlement.accountName ?? "",
      va_bank_label: this.bankDetailSettlement.bankLabel
        ? `${this.bankDetailSettlement.bankLabel.toUpperCase()} Virtual Account`
        : "",
      va_bank_email: this.bankDetailSettlement.email,
      client_type: this.$route.meta.form === "parent" ? "parent" : "",
      payment_method: this.form.paymentMethod,
      transaction_code: "",
      custom_price_type: "",
      price_type: "",
      insurance_type: "",
      insurance_percentage: 0,
      lc_origin: "",
    });

    if (add) {
      this.openSuccess = true;
      await this.goBack();
      MainAppController.setSnackbarMessage(
        `Pembuatan Client “${this.form.companyName}” berhasil !`
      );
      MainAppController.setAutoClose(true);
      MainAppController.setSnackbar(true);
      ClientRegistrationController.setBankDetailSettlement(new BankDetail({}));
      ClientRegistrationController.setBankDetail(new BankDetail({}));
    }
  }

  openSuccess = false;

  get isFormValid() {
    let result = false;
    if (
      this.form.companyName &&
      this.form.address &&
      this.form.email &&
      this.form.phone &&
      this.form.priceRateStartDate &&
      this.form.priceRateEndDate &&
      !this.isExist &&
      this.form.clientCategory &&
      this.form.contact.name &&
      this.form.contact.level &&
      this.form.contact.email &&
      this.form.contact.phone
    ) {
      result = true;
      if (this.form.isCustomRate === 0) {
        result = !!(this.form.discount && this.form.versionBasic);
      }

      if (this.form.isCustomRate === 1) {
        result = !!(
          this.form.isCustomRate &&
          this.form.versionName &&
          this.form.archiveFile
        );
      }

      if (this.form.isCustomRate === 2) {
        result = !!(
          this.form.discount &&
          this.form.versionBasic &&
          this.form.isCustomRate &&
          this.form.versionName &&
          this.form.archiveFile
        );
      }
    }
    return result && !this.anyFormError;
  }

  get isFormValidBranch() {
    let result = false;
    if (
      this.form.companyName &&
      this.form.district &&
      this.form.address &&
      this.form.email &&
      this.form.phone &&
      this.form.parent.parentName &&
      this.form.contact.name &&
      this.form.contact.email &&
      this.form.contact.phone &&
      this.form.contact.level &&
      this.isCodMandatory()
    ) {
      result = true;
      if (this.form.parent.posName) {
        result = !!(
          this.form.parent.posName &&
          this.form.parent.commision &&
          this.form.parent.commisionType &&
          this.form.parent.numeratorCommission
        );
      }
    }
    const isDO = this.form.do ? !!this.form.clientBranchDoPaymentType : true;
    return result && !this.anyFormError && isDO;
  }

  get anyFormError() {
    let result = false;
    Object.entries(this.error).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }

  formatted(value: string) {
    return formattedNPWP(value.replace(/[\D]+/g, ""));
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value) || value.length < 20;
  }

  //return config
  searchReason = "";
  onSearchAddObject(value: string) {
    this.searchReason = value;
  }

  get reasonList() {
    if (!PodDexController.reasonList.length) return []

    const ListReason = [{
      name: "Semua alasan",
      value: "all"
    }]

    return [...ListReason, ...PodDexController.reasonList.map(item => {
      return {
        name: item.statusCode + ' - ' + item.reasonDescription,
        value: item.reasonId,
      }
    })].filter(reason => reason.name.toLocaleLowerCase().includes(this.searchReason.toLocaleLowerCase()));
  }

  toggleReturnConfig() {
    this.form.isReturnConfig = !this.form.isReturnConfig;
  }

  //Misc Logic

  get validateStartEndDate() {
    const endDate = new Date(
      this.form.priceRateEndDate.getFullYear(),
      this.form.priceRateEndDate.getMonth(),
      this.form.priceRateEndDate.getDate()
    );
    const startDate = new Date(
      this.form.priceRateStartDate.getFullYear(),
      this.form.priceRateStartDate.getMonth(),
      this.form.priceRateStartDate.getDate()
    );
    const value =
      startDate.getTime() > endDate.getTime() ||
      startDate.getTime() === endDate.getTime() ||
      endDate.getTime() < startDate.getTime() ||
      endDate.getTime() === startDate.getTime();
    this.error.priceRateStartDate = value;
    this.error.priceRateEndDate = value;
    return value;
  }

  validateEmail(value: string) {
    this.error.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validateContactEmail(value: string) {
    this.error.contactEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validatePhone(value: string) {
    this.error.phone = !/^\d+$/.test(value) || value.length < 8;
    if (this.isFill) {
      this.form.codRej.phone = value;
    }
  }

  validateContactPhone(value: string) {
    this.error.contactPhone = !/^\d+$/.test(value) || value.length < 8;
  }

  validateDiscount(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0 || Number(value) > 30) {
      this.error.discount = true;
    } else {
      this.error.discount = false;
    }
  }

  errorCaptionCommision = "";
  validateCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh lebih dari 100%";
    } else {
      this.error.commision = false;
    }
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }
  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.|\.,|,,|,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  // version rates
  fetchVersionRates = debounce((search: string) => {
    ClientRegistrationController.getVersionRateList({
      search: search
    });
  }, 250);

  get versionRates() {
    return ClientRegistrationController.versionRateList;
  }

  get loadingVersionRates() {
    return ClientRegistrationController.loadingVersionRateList;
  }

  // district list
  fetchDistrictList = debounce((search: string) => {
    ClientRegistrationController.getDistrictList({
      search: search
    });
  }, 250);

  get districtList() {
    return ClientRegistrationController.districtList;
  }

  get loadingDistrictList() {
    return ClientRegistrationController.loadingDistrictList;
  }

  // route navigation
  async goBack() {
    await this.$router.push("/client/client-registration");
  }

  // Save data
  get openSave() {
    return ClientRegistrationController.openModal;
  }
  onOpenSave() {
    ClientRegistrationController.setOpenModal(true);
  }
  onCloseSave() {
    ClientRegistrationController.setOpenModal(false);
  }

  // Handle Error
  // Close when changed
  isAddTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return !!(
      this.form.companyName ||
      this.form.address ||
      this.form.email ||
      this.form.archiveFile ||
      this.form.discount ||
      this.form.phone ||
      this.form.tax.taxNumber ||
      this.form.taxNumber ||
      this.form.versionBasic ||
      this.form.priceRateStartDate ||
      this.form.priceRateEndDate ||
      this.form.versionName
    );
  }

  onOpenClosePage(to: any) {
    this.isAddTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isAddTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isAddTerminated = false;
  }

  //Error Add
  get isErrorEdit() {
    return ClientRegistrationController.isErrorEdit;
  }

  get errorCause() {
    return ClientRegistrationController.isErrorCause;
  }

  handleErrorClose() {
    ClientRegistrationController.setErrorEdit(false);
  }

  // Error Document
  get isErrorDocument() {
    return ClientRegistrationController.isErrorDocument;
  }

  // Error exist
  get isExist() {
    return ClientRegistrationController.isExist;
  }

  setIsExist(val: boolean) {
    ClientRegistrationController.setIsExist(val);
  }

  // client category
  clientCategoryList = [
    {
      name: "Pilih kategori client",
      value: ""
    },
    {
      name: "Business to Business",
      value: "B2B"
    },
    {
      name: "Business to Customer",
      value: "B2C"
    },
    {
      name: "Customer to Customer",
      value: "C2C"
    },
    {
      name: "Small Medium Business",
      value: "SMB"
    }
  ];

  isOpenClientCategory = false;
  onOpenSelectClientCategory() {
    this.isOpenClientCategory = true;
  }

  onCloseSelectClientCategory() {
    this.isOpenClientCategory = false;
  }

  onSelectClientCategory(key: string, value: string) {
    this.onCloseSelectClientCategory();
    this.form.clientCategory = value;
  }

  //company size
  companySizeList = [
    {
      name: "Pilih company size",
      value: ""
    },
    {
      name: "Small",
      value: "small"
    },
    {
      name: "Medium",
      value: "medium"
    },
    {
      name: "Big",
      value: "big"
    },
    {
      name: "Micro",
      value: "micro"
    }
  ];

  isOpenCompanySize = false;
  onOpenSelectCompanySize() {
    this.isOpenCompanySize = true;
  }

  onCloseSelectCompanySize() {
    this.isOpenCompanySize = false;
  }

  onSelectCompanySize(key: string, value: string) {
    this.onCloseSelectCompanySize();
    this.form.companySize = value;
  }

  // pos
  cityCode = "";
  filterPOS = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        search: search,
        cityCode: this.cityCode === undefined ? "" : this.cityCode,
        type: "pos",
        useLocation: true,
        useDefaultLocation: true
      })
    );
  }, 250);

  get posList() {
    return PosController.posList.data.map(e => ({
      id: e.id,
      name: `${e.name}`
    }));
  }

  get isLoadingPOS() {
    return PosController.isLoading;
  }

  getPOSByCity(obj: any) {
    this.form.parent.posName = "";
    this.cityCode = obj.cityCode;

    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        search: "",
        cityCode: this.cityCode === undefined ? "" : this.cityCode,
        type: "pos",
        useLocation: true,
        useDefaultLocation: true
      })
    );

    if (this.isFill) {
      this.form.codRej.district = obj;
    }
  }

  // commision types
  commisionTypes = [
    {
      name: "Pilih tipe komisi",
      value: ""
    },
    {
      name: "Shipping surcharge",
      value: "shipping_surcharge"
    },
    {
      name: "Publish Rate",
      value: "publish_rate"
    },
    {
      name: "Publish Rate + Shipping Surcharge",
      value: "publish_rate_shipping_surcharge"
    }
  ];

  // clients
  filterClient = debounce((search: string) => {
    CnManifestController.fetchListClientOptions({
      page: 1,
      limit: 10,
      search: search,
      type: "client",
      isParent: true
    });
  }, 250);

  get clientList() {
    return CnManifestController.clientPartnerOptions.map(e => ({
      id: e.id,
      name: `${e.name}`
    }));
  }

  get isLoadingClient() {
    return CnManifestController.loadingClientPartnerOptions;
  }

  replaceComma(str: string) {
    return str?.replace(/,/g, ".");
  }

  // Convert Value
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }

  payloadReturnConfigReasonIds(val: Array<any>) {
    let formattedVal = "";
    if (val.includes("all")) formattedVal = "all";
    else formattedVal = this.convertToString(val, "value").split(",").toString();
    return formattedVal;
  }

  async createClientBranch() {
    const payload = {
      branchName: this.form.companyName,
      branchAddress: this.form.address,
      branchDistrictCode: this.form.district
        ? this.form.district.code
        : this.form.district,
      branchEmail: this.form.email,
      branchPhoneNumber: convertPhoneNumber(this.form.phone),
      branchTaxNumber: this.form.taxNumber,
      branchParentId: this.form.parent.parentName
        ? this.form.parent.parentName.id
        : this.form.parent.parentName,
      branchPartnerPosId: this.form.parent.posName
        ? this.form.parent.posName.id
        : this.form.parent.posName,
      branchPartnerPosCommission: Number(
        this.replaceComma(this.form.parent.commision)
      ),
      branchPartnerPosCommissionType: this.form.parent.commisionType,
      branchContactName: this.form.contact.name,
      branchContactJobTitle: this.form.contact.level,
      branchContactEmail: this.form.contact.email,
      branchContactPhone: convertPhoneNumber(this.form.contact.phone),
      branchIsCod: this.form.cod,
      branchIsDo: this.form.do,
      branchReturnCodName: this.form.codRej.receiverName,
      branchReturnCodPhone: convertPhoneNumber(this.form.codRej.phone),
      branchReturnCodAddress: this.form.codRej.receiverAddress,
      branchReturnCodCity: this.form.codRej.district
        ? this.form.codRej.district.cityCode
        : "",
      branchReturnCodDistrict: this.form.codRej.district
        ? this.form.codRej.district.code
        : "",
      branchReturnCodFee: Number(this.replaceComma(this.form.codRej.commision)),
      clientBranchPartnerPosCommissionNumerator: this.form.parent
        .numeratorCommission,
      branchPadFee: this.form.codRej.padCommision,
      clientCodConfigAmount: this.form.codRej.codAmountType,
      clientCodShipmentDiscount: Number(
        this.replaceComma(this.form.codRej.codShipmentDiscount)
      ),
      clientBranchDoPaymentType: this.form.clientBranchDoPaymentType,
      clientIsDfod: this.form.clientIsDfod,
      isReturnConfig: this.form.isReturnConfig,
      returnConfigLimit: this.form.isReturnConfig ? this.form.returnConfigLimit : 0,
      returnConfigReasonIds: this.form.isReturnConfig ? this.payloadReturnConfigReasonIds(this.form.returnConfigReasonIds) : "",
    };
    const add = await ClientRegistrationController.createClientBranch(payload);
    if (add) {
      this.openSuccess = true;
      await this.goBack();
      MainAppController.setSnackbarMessage(
        `Pembuatan Client “${this.form.companyName}” berhasil. Mohon menunggu sampai Admin menyetuji permintaan Anda.`
      );
      MainAppController.setAutoClose(true);
      MainAppController.setSnackbar(true);
      this.openSuccess = false;
      this.onCloseSave();
    }
  }

  autoFillColumnDisabled = false;
  openFill = false;
  isFill = false;
  autoFill() {
    this.error.codRejPhone = false;
    this.openFill = false;
    this.isFill = !this.isFill;

    if (this.isFill) {
      this.autoFillColumnDisabled = true;
      this.form.codRej.receiverName = this.form.companyName;
      this.form.codRej.receiverAddress = this.form.address;
      this.form.codRej.district = this.form.district;
      this.form.codRej.phone = this.form.phone;
    } else {
      this.autoFillColumnDisabled = false;
      this.form.codRej.receiverName = "";
      this.form.codRej.receiverAddress = "";
      this.form.codRej.district = "";
      this.form.codRej.phone = "";
    }
  }

  fullFilledCodReceiver(value: string) {
    if (this.isFill) {
      this.form.codRej.receiverName = value;
    }
  }

  fullFilledCodAddressReceiver(value: string) {
    if (this.isFill) {
      this.form.codRej.receiverAddress = value;
    }
  }

  errorCaptionCodRejCommision = "";
  validateCodRejCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.codRejCommision = true;
      this.errorCaptionCodRejCommision = "Biaya COD tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.codRejCommision = true;
      this.errorCaptionCodRejCommision =
        "Biaya COD tidak boleh lebih dari 100%";
    } else {
      this.error.codRejCommision = false;
    }
  }

  errorCaptionPadCommision = "";
  validatePadCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.padCommision = true;
      this.errorCaptionPadCommision = "Biaya PAD tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.padCommision = true;
      this.errorCaptionPadCommision = "Biaya PAD tidak boleh lebih dari 100%";
    } else {
      this.error.padCommision = false;
    }
  }

  validateCodRejPhone(value: string) {
    this.error.codRejPhone = !/^\d+$/.test(value) || value.length < 8;
  }

  isCodMandatory() {
    if (this.form.cod) {
      return this.form.codRej.receiverName &&
        this.form.codRej.receiverAddress &&
        this.form.codRej.district &&
        this.form.codRej.phone &&
        this.form.codRej.codAmountType
        ? true
        : false;
    } else {
      return true;
    }
  }

  isFisrtStep = true;
  switchStep(step: number) {
    if (step === 0) {
      this.isFisrtStep = true;
    } else {
      this.isFisrtStep = false;
    }
  }

  get isFormFirstStepValid() {
    let result = false;
    if (
      this.form.companyName &&
      this.form.address &&
      this.form.district &&
      this.form.companySize &&
      this.form.email &&
      this.form.phone &&
      this.isFormValidTaxSection &&
      this.isFormValidBillingSection &&
      this.form.priceRateStartDate &&
      this.form.priceRateEndDate &&
      !this.isExist &&
      this.form.clientCategory &&
      this.form.contact.name &&
      this.form.contact.level &&
      this.form.contact.email &&
      this.form.contact.phone &&
      this.form.paymentMethod
    ) {
      result = true;
    }
    return result && !this.anyFormError;
  }

  get isFormValidTaxSection() {
    let res = true;
    if (this.form.tax.isTaxPayer) {
      res = this.form.tax.NIK;
    }
    return (
      this.form.tax.NIKName &&
      this.form.tax.NIKAddress &&
      this.form.tax.taxNumber &&
      res
    );
  }

  get isFormValidBillingSection() {
    return (
      this.form.billingInformation.noWhatsApp &&
      this.form.billingInformation.billingSchedule &&
      this.form.billingInformation.timeRangePayment &&
      this.form.billingInformation.email.length &&
      this.form.billingInformation.startDate &&
      this.form.billingInformation.endDate &&
      this.form.billingInformation.attachment
    );
  }

  // numerator commission
  numeratorCommissionList = [
    {
      name: "Pilih pembilang komisi",
      value: ""
    },
    {
      name: "Harga Retail",
      value: "retail-rate"
    },
    {
      name: "Harga Client",
      value: "client-rate"
    }
  ];

  isOpenNumeratorCommission = false;
  onOpenSelectNumeratorCommission() {
    this.isOpenNumeratorCommission = true;
  }

  onCloseSelectNumeratorCommission() {
    this.isOpenNumeratorCommission = false;
  }

  onSelectNumeratorCommission(key: string, value: string) {
    this.onCloseSelectNumeratorCommission();
    this.form.parent.numeratorCommission = value;
  }

  isOpenCommissionType = false;
  onOpenSelectCommissionType() {
    this.isOpenCommissionType = true;
  }

  onCloseSelectCommissionType() {
    this.isOpenCommissionType = false;
  }

  onSelectCommissionType(key: string, value: string) {
    this.onCloseSelectCommissionType();
    this.form.parent.commisionType = value;
  }

  paymentMethodList = [
    {
      name: "Pilih metode pembayaran",
      value: ""
    },
    {
      name: "Invoice",
      value: "invoice"
    },
    {
      name: "Split Bill",
      value: "split_bill"
    }
  ];

  isOpePaymentMethod = false;
  onOpenPaymentMethod() {
    this.isOpePaymentMethod = true;
  }

  onCloseSelectPaymentMethod() {
    this.isOpePaymentMethod = false;
  }

  onSelectPaymentMethod(key: string, value: string) {
    this.onCloseSelectPaymentMethod();
    this.form.paymentMethod = value;
  }
  codAmountTypes = [
    {
      name: "Pilih COD amount",
      value: ""
    },
    {
      name: "Harga Barang",
      value: "goods_price"
    },
    {
      name: "Harga Barang & Ongkir Klien",
      value: "goods_price,total_tarif"
    }
  ];
  isOpenCODAmount = false;
  onOpenCODAmount() {
    this.isOpenCODAmount = true;
  }
  onCloseCODAmount() {
    this.isOpenCODAmount = false;
  }
  onSelectCODAmount(key: string, value: string) {
    this.onCloseCODAmount();
    this.form.codRej.codAmountType = value;
  }

  errorCaptionCodRejShipmentDiscount = "";
  validateCodRejShipmentDiscount(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) > 100) {
      this.error.codRejShipmentDiscount = true;
      this.errorCaptionCodRejShipmentDiscount =
        "Persentase Diskon COD tidak boleh lebih dari 100%";
    } else {
      this.error.codRejShipmentDiscount = false;
      this.errorCaptionCodRejShipmentDiscount = "";
    }
  }

  tooltipCODShipmentDiscount = `<ol style="list-style: number; padding-left: 15px;">
    <li>Klien akan dibebankan biaya booking STT COD sebesar <strong>ongkir klien setelah <br />diskon.</strong></li>
    <li>Tipe COD amount harga barang + ongkir akan menggunakan <strong>ongkir klien <br/>sebelum diskon</strong></li>
  </ol>`;


  get columnToValidate() {
    return [
      {
        column: "start_weight",
        type: "number",
      },
      {
        column: "end_weight",
        type: "number",
      },
      {
        column: "publish_rate",
        type: "number",
      },
      {
        column: "shipping_surcharge",
        type: "number",
      },
      {
        column: "document_surcharge",
        type: "number",
      },
    ];
  };

  onChangeDeliveryFeeType(val: string) {
    this.form.codRej.codAmountType = val;
  }
}
