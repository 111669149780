
import { Options, prop, Vue } from "vue-class-component";
import PriceTypeForm from "../global-components/price-type-form.vue";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { AddRenewRatesClientManagementApiRequestV2 } from "@/data/payload/api/ClientManagementApiRequest";
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

class Props {
  modelValue = prop<any>({
    required: false,
    type: Object
  });
  clientName = prop<string>({
    type: String,
    default: ""
  });
  detailClient = prop<any>({
    type: Object,
    default: ""
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {
    PriceTypeForm
  }
})
export default class PopupRenewRate extends Vue.with(Props) {
  form = {
    isCustomRate: "retail",
    isInsurance: false,
    insurance: "",
    customRates: "",
    priceRateStartDate: "",
    priceRateEndDate: "",
    originCities: [] as OptionsClass[],
    discount: "",
    versionName: "" as any,
    archiveFile: "" as any,
    retailBasedDistrict: false,
    versionBasic: "" as any,
    rateVersionId: ""
  };

  error = {
    isCustomRate: false,
    isInsurance: false,
    insurance: false,
    customRates: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    originCities: false,
    discount: false,
    versionName: false,
    archiveFile: false,
    retailBasedDistrict: false,
    versionBasic: false
  };

  customPriceOption = [
    {
      disabled: false,
      label: "Harga retail",
      description: "Harga akan mengikuti harga retail",
      value: "retail"
    },
    {
      disabled: false,
      label: "Harga normal",
      description: "Harga akan mengikat selama periode harga yang ditentukan.",
      value: "normal"
    },
    {
      disabled: false,
      label: "Harga khusus",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload.",
      value: "khusus"
    },
    {
      disabled: false,
      label: "Harga kombinasi",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload & harga retail.",
      value: "kombinasi"
    }
  ];

  confirnation = false;

  onShowHidePopup() {
    this.$emit("update:modelValue", !this.modelValue);
  }

  get insurancePercentage() {
    return this.form.isInsurance && this.form.insurance === "custom-rates"
      ? Number(Number("0." + this.form.customRates).toFixed(2))
      : 0;
  }

  get id() {
    return this.$route.params.id;
  }

  get priceType() {
    let priceTypeVal = "";
    const lowerCustomRate = this.form.isCustomRate.toLowerCase();
    if (lowerCustomRate === "retail") priceTypeVal = "retail";
    if (
      lowerCustomRate === "normal" &&
      this.form.originCities[0].value === "All"
    )
      priceTypeVal = "normal";
    if (
      lowerCustomRate === "normal" &&
      this.form.originCities[0].value !== "All"
    )
      priceTypeVal = "normal_custom_3lc";
    if (lowerCustomRate === "khusus" && !this.form.retailBasedDistrict)
      priceTypeVal = "custom_client";
    if (lowerCustomRate === "khusus" && this.form.retailBasedDistrict)
      priceTypeVal = "custom_client_retail_district";
    if (
      lowerCustomRate === "kombinasi" &&
      this.form.originCities[0].value === "All"
    )
      priceTypeVal = "combination";
    if (
      lowerCustomRate === "kombinasi" &&
      this.form.originCities[0].value !== "All"
    )
      priceTypeVal = "combination_custom_3lc";
    return priceTypeVal;
  }
  get priceRateRetailStartDate() {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    return startDate;
  }

  async addRenewRateV2() {
    MainAppController.setSnackbarPositivity(true);
    MainAppController.setSnackbar(false);
    const payload = new AddRenewRatesClientManagementApiRequestV2({
      clientId: Number(this.id),
      versionBasic:
        this.form.isCustomRate === "khusus"
          ? ""
          : this.form.isCustomRate === "retail"
          ? ""
          : this.form.versionBasic?.rateVersionName,
      isCustomRate: this.isMixRateBool,
      discount: this.form.discount
        ? Number(this.form.discount.replace(/,/g, "."))
        : 0,
      startDate: formatDateNormal(
        this.form.isCustomRate === "retail"
          ? this.priceRateRetailStartDate
          : this.form.priceRateStartDate,
        "YYYY-MM-DD"
      ),
      endDate: formatDateNormal(
        this.form.isCustomRate === "retail"
          ? this.detailClient.clientBilling.endDate
          : this.form.priceRateEndDate,
        "YYYY-MM-DD"
      ),
      versionName:
        typeof this.form.versionName === "object"
          ? this.form.versionName.rateVersionName
          : this.form.versionName,
      archiveFile:
        typeof this.form.archiveFile === "object" ? this.form.archiveFile : "",
      isCustomRateOnly: this.isCustomRateBool,
      priceType: this.priceType,
      insuranceType: this.form.isInsurance
        ? this.form.insurance === "custom-rates"
          ? "custom"
          : "bundling"
        : "",
      insurancePercentage: this.insurancePercentage,
      "3lcOrigin":
        this.form.originCities[0]?.value === "All"
          ? ""
          : this.form.originCities.map((oc: any) => oc.name).join(", ")
    });
    const resp = await ClientManagementController.addRenewRatesV2(payload);
    if (resp) {
      ClientManagementController.getDetailV2(Number(this.id));
      this.onShowHidePopup();
      this.$router.push(`/client/client-management/${this.id}`).then(() => {
        MainAppController.setSnackbarMessage(
          `Penambahan harga klien induk “${this.clientName}” berhasil!`
        );
        MainAppController.setSnackbarTimeout(4000);
        MainAppController.setSnackbar(true);
      });
    }
  }

  get isBasicRateBool() {
    return this.form.isCustomRate === "normal";
  }
  get isMixRateBool() {
    return this.form.isCustomRate === "kombinasi";
  }
  get isCustomRateBool() {
    return this.form.isCustomRate === "khusus";
  }

  get handleRetail() {
    return (
      !this.form.isInsurance ||
      (this.form.isInsurance && this.form.insurance && !this.error.customRates)
    );
  }

  get handleNormal() {
    return (
      (this.form.versionBasic &&
        this.form.originCities.length &&
        this.form.discount &&
        !this.error.discount &&
        !this.form.isInsurance) ||
      (this.form.versionBasic &&
        this.form.originCities.length &&
        this.form.discount &&
        !this.error.discount &&
        this.form.isInsurance &&
        this.form.insurance &&
        !this.error.customRates)
    );
  }

  get handleKhusus() {
    return (
      (this.form.versionName &&
        this.form.archiveFile.name &&
        !this.form.isInsurance) ||
      (this.form.versionName &&
        this.form.archiveFile.name &&
        this.form.isInsurance &&
        this.form.insurance &&
        !this.error.customRates)
    );
  }

  get handleKombinasi() {
    return (
      (this.form.versionName &&
        this.form.versionBasic &&
        this.form.originCities.length &&
        this.form.discount &&
        !this.error.discount &&
        this.form.archiveFile.name &&
        !this.form.isInsurance) ||
      (this.form.versionName &&
        this.form.versionBasic &&
        this.form.originCities.length &&
        this.form.discount &&
        !this.error.discount &&
        this.form.archiveFile.name &&
        this.form.isInsurance &&
        this.form.insurance &&
        !this.error.customRates)
    );
  }

  get isValidRenewRate() {
    let result = false;
    if (this.form.isCustomRate === "retail") {
      if (this.handleRetail) result = true;
    }
    if (this.form.isCustomRate === "normal") {
      if (this.handleNormal) result = true;
    }
    if (this.form.isCustomRate === "khusus") {
      if (this.handleKhusus) result = true;
    }
    if (this.form.isCustomRate === "kombinasi") {
      if (this.handleKombinasi) result = true;
    }
    return (
      result && !this.error.priceRateStartDate && !this.error.priceRateEndDate
    );
  }

  onConfirmRenewal() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Ubah harga klien induk?",
        message: "Harga yang sedang diajukan akan diganti sesuai kebutuhan yang baru.",
        image: "badge-confirmation-general",
        textSuccess: "Ya, ubah",
        textCancel: "Kembali",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.addRenewRateV2();
        }
      })
    );
  }
}
