
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";

class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement(),
  });
}
@Options({
  components: {
    Title,
  },
})
export default class DetailBankInformation extends Vue.with(Props) {
  mounted() {
    PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "",
        reasonStatus: "active",
        isCreateTicketSf: true,
      })
    );
  }

  getReasonList(val: string) {
    const ListReason = PodDexController.reasonList.map((item) => {
      return {
        name: item.statusCode + " - " + item.reasonDescription,
        value: item.reasonId,
      };
    });

    if (val === "all") {
      return ["Semua alasan"];
    } else {
      const ids = val.split(",").map(Number);
      const reasons = ListReason.filter((item) => ids.includes(item.value));
      return reasons.map((item) => item.name);
    }
  }
}
