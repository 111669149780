
import { OptionsClass } from "@/domain/entities/MainApp";
import { Options, prop, Vue } from "vue-class-component";
import Multiselect from "@/app/ui/components/multiselect-search-tag/index.vue";
import {
  optionBillingSchedule,
  optionRangeTimePayment
} from "@/app/infrastructures/misc/Constants";

export type BillingInformationSectionProp = {
  noWhatsApp: string;
  billingSchedule: OptionsClass;
  timeRangePayment: OptionsClass;
  email: string[];
};

export type BillingInformationSectionErrorProp = {
  contactWhatsApp: boolean;
  emailBilling: boolean;
};

class Props {
  modelValue = prop<BillingInformationSectionProp>({
    default: {
      billingSchedule: new OptionsClass(),
      email: [],
      noWhatsApp: "",
      timeRangePayment: new OptionsClass()
    },
    required: true,
    type: Object
  });
  error = prop<BillingInformationSectionErrorProp>({
    default: {
      contactWhatsApp: false,
      emailBilling: false
    },
    required: true,
    type: Object
  });
}

@Options({
  components: {
    Multiselect
  }
})
export default class BillingInformationSection extends Vue.with(Props) {
  //billing schedule
  billingScheduleList = optionBillingSchedule;

  //range time payment
  rangeTimePaymentList = optionRangeTimePayment;

  emailOptions = [];

  get formModel() {
    return this.modelValue;
  }

  set formModel(val) {
    this.$emit("update:modelValue", { ...val });
  }

  get formErrorModel() {
    return this.error;
  }

  set formErrorModel(val) {
    this.$emit("update:error", { ...val });
  }

  get errorEmail() {
    const res = this.formModel.email?.map((key: any) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(key.name);
    });
    this.formErrorModel.emailBilling = res?.includes(false);
    return this.formModel.email?.length && this.formErrorModel.emailBilling;
  }

  validatePhone(value: string) {
    this.formErrorModel.contactWhatsApp =
      !/^\d+$/.test(value) || value.length < 8;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }
}
