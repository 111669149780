
import { prop, Vue } from "vue-class-component";

export type ContactInformationSectionProp = {
  name: string;
  level: string;
  phone: string;
  email: string;
};

export type ContactInformationSectionErrorProp = {
  contactPhone: boolean;
  contactEmail: boolean;
};

class Props {
  modelValue = prop<ContactInformationSectionProp>({
    default: {
      email: "",
      level: "",
      name: "",
      phone: ""
    }
  });
  error = prop<ContactInformationSectionErrorProp>({
    default: {
      contactEmail: false,
      contactPhone: false
    }
  });
}

export default class ContactInformationSection extends Vue.with(Props) {
  get formModel() {
    return this.modelValue;
  }

  set formModel(val) {
    this.$emit("update:modelValue", val);
  }

  get formErrorModel() {
    return this.error;
  }

  set formErrorModel(val) {
    this.$emit("update:error", val);
  }

  validatePhone(value: string) {
    this.formErrorModel.contactPhone = !/^\d+$/.test(value) || value.length < 8;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }

  validateEmail(value: string) {
    this.formErrorModel.contactEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
      value
    );
  }
}
