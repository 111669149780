
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";

class Props {
  isManagement = prop<boolean>({
    default: false,
    required: false,
    type: Boolean
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailBankSettlementInformation extends Vue.with(Props) {
  get bankDetail() {
    return ClientRegistrationController.bankDetail;
  }
}
