
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  checkRolePermission,
  formatDateWithoutTime,
  formatDate,
  ellipsisString,
  downloadFile,
  formatDateNumber,
  formatPrice
} from "@/app/infrastructures/misc/Utils";
import { CLIENT } from "@/app/infrastructures/misc/RolePermission";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Confirmation from "../component/confirmation.vue";
import EditBasicInformation from "../component/edit-basic-information.vue";
import EditCurrentPeriode from "../component/edit-current-rate-periode.vue";
import RenewRates from "../component/popup-renew-rates.vue";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import DetailParentClient from "./detail-client-information/detail-parent-client.vue";
import DetailParentBranch from "./detail-client-information/detail-parent-branch.vue";
import DetailBranchClient from "./detail-client-information/detail-branch-client.vue";
import DetailContactInformation from "./detail-client-information/detail-contact-information.vue";
import DetailConfigRetur from "./detail-client-information/detail-config-retur.vue";
import DetailBankInformation from "./detail-client-information/detail-bank-information.vue";
import DetailCodrej from "./detail-client-information/detail-codrej-section.vue";
import DetailDeliveryOrder from "./detail-client-information/detail-delivery-order.vue";
import FreezeUnfreezePopup from "@/app/ui/views/sales/clients/client-management/component/freeze-unfreeze-popup.vue";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { translateStatusClient } from "@/app/ui/views/sales/clients/client-management/utils-client-management";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ClientController } from "@/app/ui/controllers/ClientController";
import DetailTaxInformation from "@/app/ui/views/sales/clients/client-management/detail/detail-client-information/detail-tax-information.vue";
import DetailBillingInformation from "@/app/ui/views/sales/clients/client-management/detail/detail-client-information/detail-billing-information.vue";
import DetailBankSettlementInformation from "@/app/ui/views/sales/clients/client-management/detail/detail-client-information/detail-bank-settlement-information.vue";
import {
  ClientTax,
  ClientBilling,
  BankDetail
} from "@/domain/entities/ClientManagement";
import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";

@Options({
  components: {
    DetailBankSettlementInformation,
    DetailBillingInformation,
    DetailTaxInformation,
    DetailLayout,
    CreateUpdate,
    Title,
    Confirmation,
    EditBasicInformation,
    EditCurrentPeriode,
    RenewRates,
    DetailParentClient,
    DetailContactInformation,
    DetailBankInformation,
    DetailBranchClient,
    DetailParentBranch,
    FreezeUnfreezePopup,
    DetailCodrej,
    Skeleton,
    DetailDeliveryOrder,
    DetailConfigRetur
  },
  computed: {
    isEditAble() {
      return checkRolePermission(CLIENT.MANAGEMENT_EDIT);
    },
    isApproveAble() {
      return checkRolePermission(CLIENT.MANAGEMENT_APPROVAL);
    },
    statusRatesCaption() {
      switch (this.statusRates) {
        case "active":
          return "Permintaan Client berhasil disetujui. Diskon & Harga Khusus sudah memenuhi syarat.";
        case "nearly_expired":
          return "Harga sudah mendekati tanggal kadaluwarsa atau periode akan berakhir. Segera ajukan pembaruan harga.";
        case "pending":
          return `Pengajuan harga telah disetujui. Harga akan aktif pada ${formatDateWithoutTime(
            this.detailData.clientCurrentRate.clientRateversionStartDate
          )}.`;
        case "expired":
          return "Harga Anda sudah kadaluwarsa atau periode sudah berakhir.\n";
        default:
          return "";
      }
    }
  }
})
export default class DetailClient extends Vue {
  get isParentClient() {
    return this.detailData.clientType === "Client Induk";
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  // Get Data
  created() {
    this.getDetail();
  }

  getDetail() {
    ClientManagementController.getDetail(this.id);
  }

  ellipsis(value: string, long: number) {
    return ellipsisString(value, long);
  }

  // Detail Data
  get detailData() {
    return ClientManagementController.detailClientManagement;
  }

  get loadingBalance() {
    return ClientManagementController.loadingBalance;
  }
  get convertClientBalance() {
    return formatPrice(this.detailData.clientBalance);
  }

  get basicRatePeriod() {
    return `${formatDateWithoutTime(
      this.detailData.clientCurrentRate.clientRateversionStartDate
    )} - ${formatDateWithoutTime(
      this.detailData.clientCurrentRate.clientRateVersionEndDate
    )}`;
  }

  get customRatePeriod() {
    return `${formatDateWithoutTime(
      this.detailData.clientRenewalRate?.clientRateversionStartDate
    )} - ${formatDateWithoutTime(
      this.detailData.clientRenewalRate?.clientRateVersionEndDate
    )}`;
  }

  // misc variable
  get statusRates() {
    return this.detailData?.clientCurrentRate?.clientStatusCustomRate;
  }
  get statusRenewal() {
    return this.detailData?.clientRenewalRate?.clientStatusCustomRate;
  }
  get statusRenewalCaptions() {
    if (this.statusRenewal === "rejected") {
      return this.detailData.clientRenewalRate?.clientStatusUpdatedReason;
    }
    return "";
  }
  get unableToRenewRates() {
    if (
      !FlagsPermissionClient.permission_client_management_renew_rate.isEnabled()
    ) {
      return true;
    }
    return !(
      (this.statusRates === "expired" ||
        this.statusRates === "active" ||
        this.statusRates === "nearly_expired") &&
      this.statusRenewal === ""
    );
  }

  get createdAt() {
    return formatDate(this.detailData.clientCreatedAt);
  }

  get updatedAt() {
    return formatDate(this.detailData.clientUpdatedAt);
  }

  get approvedAt() {
    return formatDate(this.detailData.clientCurrentRate?.clientStatusUpdatedAt);
  }

  get statusRenewalUpdateAt() {
    return formatDate(this.detailData.clientRenewalRate?.clientStatusUpdatedAt);
  }

  // Loading
  get isLoading() {
    return ClientManagementController.isLoading;
  }

  // route navigation
  goBack() {
    this.$router.push("/client/client-management");
  }
  goEdit() {
    this.$router.push(
      `/client/client-management/${this.$route.params.id}/edit`
    );
  }

  // Handle Error
  get isError() {
    return ClientManagementController.isError;
  }
  get errorCause() {
    return ClientManagementController.isErrorCause;
  }

  // download file
  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    const fileExt = fileName.split(".").pop();
    downloadFile(url, newFileName, `.${fileExt}`);
  }

  // confirmation
  isConfirmShow = false;
  typeConfirm = 0;

  confirmation(type: number) {
    this.isConfirmShow = true;
    this.typeConfirm = type;
  }
  // edit basic information
  showEditBasicInformation = false;
  detailClient = {
    clientType: "",
    clientId: 0,
    clientCode: "",
    clientName: "",
    clientCategory: "",
    clientAddress: "",
    clientDistrict: {},
    clientCityCode: "",
    clientEmail: "",
    clientPhone: "",
    clientNpwp: "",
    clientContactName: "",
    clientContactJobTitle: "",
    clientContactEmail: "",
    clientContactPhone: "",
    clientBankAccountName: "",
    clientBankName: "",
    clientBankAccountNumber: "",
    clientBankEmail: "",
    clientIsCod: false,
    clientIsDeliveryOrder: false,
    clientParentName: "",
    clientParentPos: {},
    clientParentPOSCommision: "",
    clientParentCommisionType: {},
    clientParentNumeratorCommision: "",
    clientOdooId: 0,
    clientCompanySize: "",
    clientTax: new ClientTax(),
    clientBilling: new ClientBilling(),
    bankSettlement: new BankDetail(),
    clientBranchDoPaymentType: "",
    isReturnConfig: false,
    returnConfigLimit: 0,
    returnConfigReasonIds: ""
  };
  getBeneficiaryAccount() {
    BeneficiaryAccountController.getBeneficiaryAccList({
      page: 1,
      limit: 1,
      bankAccType: "beneficiary",
      actortype: "client",
      actorId: this.detailData.id
    });
  }
  setShowEditBasicInformation() {
    MainAppController.setSnackbar(false);
    this.getBeneficiaryAccount();
    this.detailClient.clientType =
      this.detailData.clientType.toLowerCase() === "client induk"
        ? "Induk Client"
        : "Cabang Client";
    this.detailClient.clientId = this.detailData.id;
    this.detailClient.clientCode = this.detailData.clientCode;
    this.detailClient.clientName = this.detailData.clientName;
    this.detailClient.clientCategory = this.detailData.clientCategory;
    this.detailClient.clientAddress = this.detailData.clientAddress;
    this.detailClient.clientDistrict = this.detailData.clientDistrict;
    this.detailClient.clientCityCode = this.detailData.clientCity.code;
    this.detailClient.clientEmail = this.detailData.clientEmail;
    this.detailClient.clientPhone = this.detailData.clientPhone.replace(
      /^0|^62/g,
      ""
    );
    this.detailClient.clientNpwp = this.detailData.clientTaxNumber;
    this.detailClient.clientContactName = this.detailData.clientContactName;
    this.detailClient.clientContactJobTitle = this.detailData.clientContactJobTitle;
    this.detailClient.clientContactEmail = this.detailData.clientContactEmail;
    this.detailClient.clientContactPhone = this.detailData.clientContactPhone.replace(
      /^0|^62/g,
      ""
    );
    this.detailClient.clientBankAccountName = this.detailData.clientAccountName;
    this.detailClient.clientBankName = this.detailData.clientBankName;
    this.detailClient.clientBankAccountNumber = this.detailData.clientAccountNumber;
    this.detailClient.clientBankEmail = this.detailData.clientBeneficiaryEmail;
    this.detailClient.clientIsCod = this.detailData.clientIsCod;
    this.detailClient.clientIsDeliveryOrder = this.detailData.clientIsDo;
    this.detailClient.clientParentName = this.detailData.clientParentName;
    this.detailClient.clientParentPos = this.detailData.clientPartnerName
      ? {
          name: this.detailData.clientPartnerName,
          id: this.detailData.clientPartnerId
        }
      : "";
    this.detailClient.clientParentPOSCommision = this.detailData.clientPartnerCommissionAmountPercentage;
    this.detailClient.clientParentCommisionType = this.detailData.clientPartnerQuantifierCommission;
    this.detailClient.clientParentNumeratorCommision = this.detailData.clientPartnerNumeratorCommission;
    this.showEditBasicInformation = true;
    this.detailClient.clientOdooId = this.detailData.clientOdooId;
    this.detailClient.clientCompanySize = this.detailData.clientCompanySize;
    this.detailClient.clientTax = this.detailData.clientTax;
    this.detailClient.clientBilling = this.detailData.clientBilling;
    this.detailClient.bankSettlement = this.detailData.bankSettlement;
    this.detailClient.clientBranchDoPaymentType = this.detailData.clientBranchDoPaymentType;
    this.detailClient.isReturnConfig = this.detailData.isReturnConfig;
    this.detailClient.returnConfigLimit = this.detailData.returnConfigLimit;
    this.detailClient.returnConfigReasonIds = this.detailData.returnConfigReasonIds;

  }

  // handle Edit Current rates periode
  isEditCurrentRate = false;
  setEditCurrentRate(value: boolean) {
    this.isEditCurrentRate = value;
  }

  // handle renew rates
  isAddRenewRates = false;
  setRenewRates() {
    ClientManagementController.setRenewalRateStart(
      new Date(
        this.detailData.clientRenewalRate?.clientRateversionStartDate ||
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 1
          )
      )
    );
    ClientManagementController.setRenewalRateEnd(
      new Date(
        this.detailData.clientRenewalRate?.clientRateVersionEndDate ||
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 2
          )
      )
    );
  }

  onAddRenewRates(value: boolean) {
    MainAppController.setSnackbar(false);
    this.isAddRenewRates = value;
    this.setRenewRates();
  }

  // update current rates
  isUpdateRates = false;
  onUpdateRates(value: boolean) {
    this.isUpdateRates = value;
    this.setRenewRates();
  }

  // update renewal rates
  isUpdateRenewalRates = false;
  onUpdatRenewaleRates(value: boolean) {
    this.isUpdateRenewalRates = value;
    this.setRenewRates();
  }
  onCloseRates(value: boolean) {
    this.onAddRenewRates(value);
    this.onUpdateRates(value);
    this.onUpdatRenewaleRates(value);
  }
  get isDisableUpdateRates() {
    if (
      !FlagsPermissionClient.permission_client_management_update_live_rate.isEnabled()
    ) {
      return true;
    }
    return (
      !!this.detailData.clientRenewalRate?.clientCustomRateId ||
      !(this.statusRates === "active" || this.statusRates === "nearly_expired")
    );
  }

  // Edit Terminated
  isEditTerminated = false;
  setEditTerminated(value: boolean) {
    this.isEditTerminated = value;
  }

  // Confirm Save
  isConfirmSave = false;
  setConfirmSave(value: boolean) {
    this.isConfirmSave = value;
  }

  // Save data
  detailRenewRates: any = {
    id: 0,
    companyName: "",
    email: "",
    phone: "",
    address: "",
    taxNumber: "",
    versionBasic: "",
    isCustomRate: false,
    discount: "",
    versionName: "",
    archiveFile: "",
    rateVersionId: ""
  };

  onEditRenewRates() {
    MainAppController.setSnackbar(false);
    const data: any = this.detailData.clientRenewalRate;
    this.onUpdatRenewaleRates(true);
    ClientRegistrationController.getVersionRateList({
      search: data.clientVersionBaseRate
    });
  }

  get errorAddRenewRates() {
    return (
      ClientManagementController.errorAddRenewRates ||
      ClientManagementController.isErrorEdit
    );
  }

  setErrorAddRenewRates(val: boolean) {
    ClientManagementController.setErrorAddRenewRates(val);
    ClientManagementController.setErrorEdit(val);
  }

  get clientName() {
    return ClientManagementController.detailClientManagement.clientName;
  }
  get rateVersionId() {
    return ClientManagementController.detailClientManagement.clientCurrentRate
      .clientCustomRateId;
  }
  // Modal Success
  get openSuccess() {
    return ClientManagementController.successCurrentRate;
  }
  onCloseSuccess() {
    ClientManagementController.setSuccessCurrentRate(false);
    this.getDetail();
  }
  // Save data
  get openSave() {
    return ClientManagementController.openModal;
  }
  onOpenSave() {
    MainAppController.setSnackbar(false);
    ClientManagementController.setOpenModal(true);
    this.setEditCurrentRate(false);
  }
  onCloseSave() {
    ClientManagementController.setOpenModal(false);
    this.setEditCurrentRate(true);
  }
  get priceRateStartDate() {
    return ClientManagementController.convertPeriodeStart;
  }
  get priceRateEndDate() {
    return ClientManagementController.convertPeriodeEnd;
  }

  async _onEditPeriode() {
    ClientManagementController.setOpenModal(false);
    const resp = await ClientManagementController.editCurrentRatePeriode({
      id: this.rateVersionId,
      endDate: formatDateNumber(this.priceRateEndDate)
    });
    if (resp) {
      MainAppController.setSnackbarMessage("Perubahan periode harga berhasil");
      MainAppController.setSnackbarTimeout(2000);
      MainAppController.setSnackbar(true);
    }
  }

  // freeze unfreeze
  freezeUnFreezeModal = false;
  detailClientFreezeUnFreeze: any = "";
  handleFreezeUnFreeze() {
    MainAppController.setSnackbar(false);
    this.freezeUnFreezeModal = true;
    this.detailClientFreezeUnFreeze = {
      id: this.detailData.id,
      name: this.detailData.clientName,
      type: this.detailData.clientType,
      status: this.detailData.clientIsBanned
    };
  }

  translateStatusClient(status: string) {
    return translateStatusClient(status);
  }

  // tab
  tabActive = "detail-client";
  tabs = [
    { name: "detail-client", title: "Detail Induk Client" },
    { name: "detail-rates-client", title: "Pilihan Harga" }
  ];
  changeTabs(value: string) {
    this.tabActive = value;
  }
  isTabActive(name: string) {
    return name === this.tabActive;
  }

  // get style button edit renew rates
  get styleButtonEditRenewRates() {
    if (this.statusRenewal === "requested") {
      return {
        outline: true,
        textColor: "red-lp-300",
        iconLeft: "pencil-alt",
        title: "Ubah"
      };
    }
    return {
      title: "Perbaiki",
      textColor: "white"
    };
  }

  get priceTypeRenewRates() {
    if (
      this.detailData.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "mix";
    }
    if (
      !this.detailData.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "custom";
    }
    return "basic";
  }

  async retryUploadClientRate() {
    const resp = await ClientController.retryUploadClientRate({
      clientId: this.detailData.id,
      rateVersionId: this.detailData.clientRenewalRate?.clientCustomRateId || 0
    });
    if (resp) {
      MainAppController.setSnackbarMessage("Upload ulang client rate berhasil");
      MainAppController.setSnackbarTimeout(2000);
      MainAppController.setSnackbar(true);
      this.getDetail();
    }
  }

  formatDateWithoutTime(val: string) {
    return formatDateWithoutTime(val);
  }
}
