
import { optionTaxTransactionCode } from "@/app/infrastructures/misc/Utils";
import { OptionsClass } from "@/domain/entities/MainApp";
import { prop, Vue } from "vue-class-component";

export type TaxSectionProp = {
  taxNumber: string;
  NIKName: string;
  NIKAddress: string;
  transactionCode: OptionsClass;
};

export type TaxSectionErrorProp = {
  NIKNumber: boolean;
};

class Props {
  modelValue = prop<TaxSectionProp>({
    default: {
      NIKAddress: "",
      NIKName: "",
      taxNumber: "",
      transactionCode: new OptionsClass()
    },
    required: true,
    type: Object
  });
  error = prop<TaxSectionErrorProp>({
    default: {
      taxNumber: false
    },
    required: true,
    type: Object
  });
}

export default class TaxSection extends Vue.with(Props) {
  transactionCodeList = optionTaxTransactionCode;

  get formModel() {
    return this.modelValue;
  }

  set formModel(val) {
    this.$emit("update:modelValue", val);
  }

  get formErrorModel() {
    return this.error;
  }

  set formErrorModel(val) {
    this.$emit("update:error", val);
  }

  validateNIK(value: string) {
    this.formErrorModel.NIKNumber =
      !/^\d+$/.test(value) || value.length > 16 || value.length < 15;
  }

  formatNumber(str: string) {
    return str.replace(/\D+/g, "");
  }
}
