import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-3 py-5 rounded-xl border border-gray-lp-1300" }
const _hoisted_2 = { class: "w-3/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("section", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Title, {
        class: "mb-4",
        fontSize: 20,
        label: "Pengembalian Delivery Order"
      }),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          label: "Tipe Pembayaran Delivery Order",
          value: _ctx.deliveryOrderPaymentType,
          asterisk: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomDropdown, {
              selectedItem: _ctx.modelValue,
              options: _ctx.dOOptions,
              onSelect: _ctx.onSelectDO,
              placeholder: "Pilih Tipe DO",
              class: "w-full my-2"
            }, null, 8, ["selectedItem", "options", "onSelect"])
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ])
  ]))
}