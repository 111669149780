import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-3 py-5 rounded-xl border border-gray-lp-1300" }
const _hoisted_2 = { class: "w-full flex flex-row gap-4" }
const _hoisted_3 = { class: "w-full flex-1" }
const _hoisted_4 = { class: "w-full flex-1" }
const _hoisted_5 = { class: "w-full flex-1" }
const _hoisted_6 = { class: "w-full flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("section", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Title, {
        class: "mb-4",
        fontSize: 20,
        label: "Informasi kontak"
      }),
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_DataWrapper, {
            asterisk: "",
            label: "Nama",
            fontSize: 14
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                placeholder: "Masukkan nama",
                modelValue: _ctx.modelValue.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue.name = $event)),
                error: _ctx.error.contactName
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DataWrapper, {
            asterisk: "",
            label: "Jabatan",
            fontSize: 14
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                placeholder: "Masukkan jabatan",
                modelValue: _ctx.modelValue.position,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.modelValue.position = $event)),
                error: _ctx.error.position
              }, null, 8, ["modelValue", "error"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_DataWrapper, {
            label: "No. telepon",
            fontSize: 14,
            asterisk: "",
            style: {"flex":"1"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                placeholder: "Contoh: 8123456789012",
                prefix: "+62",
                format: _ctx.formatNumber,
                debounce: 500,
                error: _ctx.error.contactPhone,
                maxLength: "12",
                errorCaption: "Masukan No. Telepon dengan benar",
                modelValue: _ctx.modelValue.phone,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.modelValue.phone = $event)),
                onChange: _ctx.validatePhone
              }, null, 8, ["format", "error", "modelValue", "onChange"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_DataWrapper, {
            label: "Email",
            fontSize: 14,
            asterisk: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                type: "email",
                placeholder: "Contoh: nama@email.com",
                modelValue: _ctx.modelValue.email,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.modelValue.email = $event)),
                error: _ctx.error.contactEmail,
                errorCaption: "Masukan Email dengan benar",
                onChange: _ctx.validateEmail
              }, null, 8, ["modelValue", "error", "onChange"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}