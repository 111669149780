
import { prop, Vue, Options } from "vue-class-component";
import { convertPhoneNumber } from "@/app/infrastructures/misc/Utils";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import startCase from "lodash/startCase";
import CardTabDetail from "../../card-tab-detail.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";

class Props {
  isManagement = prop<boolean>({
    default: false,
    required: true,
    type: Boolean
  });
}

@Options({
  components: {
    CardTabDetail
  }
})
export default class ClientDetailCard extends Vue.with(Props) {
  mounted() {
    PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "",
        reasonStatus: "active",
        isCreateTicketSf: true
      })
    );
  }

  getReasonList(val: string) {
    const ListReason = PodDexController.reasonList.map(item => {
      return {
        name: item.statusCode + " - " + item.reasonDescription,
        value: item.reasonId
      };
    });

    if (val === "all") {
      return ["Semua alasan"];
    } else {
      const ids = val.split(",").map(Number);
      const reasons = ListReason.filter(item => ids.includes(item.value));
      return reasons.map(item => item.name);
    }
  }

  tabs = [
    {
      name: "Klien",
      slotName: "client-info"
    },
    {
      name: "Konfigurasi retur",
      slotName: "config-return"
    },
    {
      name: "Kontak",
      slotName: "contact"
    },
    {
      name: "Pengembalian",
      slotName: "return"
    },
    {
      name: "COD",
      slotName: "cod"
    },
    {
      name: "Detail induk klien",
      slotName: "detail-parent-client"
    }
  ];

  convertPhoneNumber: any = convertPhoneNumber;

  get detailDataManagement() {
    return ClientManagementController.detailClientManagement;
  }

  get detailDataRegistration() {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  get detailData() {
    if (this.isManagement) {
      return this.detailDataManagement;
    } else {
      return this.detailDataRegistration;
    }
  }

  get posCommision() {
    if (this.isManagement) {
      return String(
        this.detailDataManagement.clientPartnerCommissionAmount
      );
    } else {
      return String(
        this.detailDataRegistration.clientPartner.clientPartnerCommissionAmount
      );
    }
  }

  get clientContact() {
    if (this.isManagement) {
      return {
        name: this.detailDataManagement.clientContactName,
        jobLevel: this.detailDataManagement.clientContactJobTitle,
        email: this.detailDataManagement.clientContactEmail,
        phone: this.detailDataManagement.clientContactPhone
      };
    } else {
      return {
        name: this.detailDataRegistration.clientContact.clientContactName,
        jobLevel: this.detailDataRegistration.clientContact
          .clientContactJobTitle,
        email: this.detailDataRegistration.clientContact.clientContactEmail,
        phone: this.detailDataRegistration.clientContact.clientContactPhone
      };
    }
  }

  get clientPartner() {
    if (this.isManagement) {
      return {
        clientPartnerName: this.detailDataManagement.clientPartnerName,
        clientPartnerNumeratorCommission: this.detailDataManagement
          .clientPartnerNumeratorCommission === 'client-rate'
          ? 'Harga Klien'
          : 'Harga Retail',
        clientPartnerQuantifierCommission: this.detailDataManagement
          .clientPartnerQuantifierCommission
          ? this.detailDataManagement.clientPartnerQuantifierCommission ===
            "publish_rate_shipping_surcharge"
            ? "Publish Rate + Shipping Surcharge"
            : startCase(
                this.detailDataManagement.clientPartnerQuantifierCommission.replace(
                  /_/g,
                  " "
                )
              )
          : "-"
      };
    } else {
      return {
        clientPartnerName: this.detailDataRegistration.clientPartner
          .clientPartnerName,
        clientPartnerNumeratorCommission: this.detailDataRegistration
          .clientPartner.clientPartnerNumeratorCommision === 'client-rate'
          ? 'Harga Klien'
          : 'Harga Retail',
        clientPartnerQuantifierCommission: this.detailDataRegistration
          .clientPartner.clientPartnerQuantifierCommission
          ? this.detailDataRegistration.clientPartner
              .clientPartnerQuantifierCommission ===
            "publish_rate_shipping_surcharge"
            ? "Publish Rate + Shipping Surcharge"
            : startCase(
                this.detailDataRegistration.clientPartner.clientPartnerQuantifierCommission.replace(
                  /_/g,
                  " "
                )
              )
          : "-"
      };
    }
  }

  startCase(str: string) {
    return startCase(str);
  }
}
