import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, { label: "Informasi Pajak" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "No. NIK",
        value: _ctx.detailData.clientTax.NIK
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Nama NIK",
        value: _ctx.detailData.clientTax.NIKName
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Alamat",
      value: _ctx.detailData.clientTax.NIKAddress
    }, null, 8, ["value"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "No. Registrasi Pajak (NPWP)",
        value: _ctx.detailData.clientTax.taxNumber
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Nama Wajib Pajak",
        value: _ctx.detailData.clientTax.taxPayerName
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Alamat Wajib Pajak",
      value: _ctx.detailData.clientTax.taxPayerAddress
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Kode Transaksi",
      value: _ctx.detailData.clientTax.transactionCode
    }, null, 8, ["value"])
  ], 64))
}