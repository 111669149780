
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement()
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailBankInformation extends Vue.with(Props) {
  created() {
    this.getBeneficiaryAccount();
  }

  getBeneficiaryAccount() {
    BeneficiaryAccountController.getBeneficiaryAccList({
      page: 1,
      limit: 1,
      bankAccType: "beneficiary",
      actortype: "client",
      actorId: this.detailData.id
    });
  }

  get beneficiaryDetail() {
    return BeneficiaryAccountController.beneficiaryList.data;
  }
}
