import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4" }
const _hoisted_2 = {
  class: "flex flex-row",
  style: {"gap":"20px"}
}
const _hoisted_3 = {
  class: "flex flex-row",
  style: {"gap":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_Title, {
      class: "mb-4",
      fontSize: 20,
      label: "Informasi pajak"
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          label: "No. registrasi pajak (NPWP)/KTP",
          asterisk: "",
          fontSize: 14,
          style: {"flex":"1"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LpInput, {
              placeholder: "0/16",
              showMaxLength: !!_ctx.formModel.taxNumber,
              maxLength: "16",
              modelValue: _ctx.formModel.taxNumber,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formModel.taxNumber = $event)),
              error: _ctx.formErrorModel.NIKNumber,
              errorCaption: "Masukan No. NIK/NPWP dengan benar",
              format: _ctx.formatNumber,
              onChange: _ctx.validateNIK
            }, null, 8, ["showMaxLength", "modelValue", "error", "format", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Nama sesuai NPWP/KTP",
          asterisk: "",
          fontSize: 14,
          style: {"flex":"1"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LpInput, {
              placeholder: "Masukkan nama sesuai KTP",
              modelValue: _ctx.formModel.NIKName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formModel.NIKName = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_DataWrapper, {
          label: "Alamat sesuai NPWP/KTP",
          asterisk: "",
          fontSize: 14,
          style: {"flex":"1"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_LpInput, {
              placeholder: "Masukkan alamat sesuai KTP",
              type: "textarea",
              modelValue: _ctx.formModel.NIKAddress,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formModel.NIKAddress = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Kode transaksi faktur pajak",
          asterisk: "",
          fontSize: 14,
          style: {"flex":"1"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomDropdown, {
              class: "mt-2",
              placeholder: "Pilih kode transaksi",
              customValueStyle: "text-sm",
              customOptionStyle: "text-sm",
              options: _ctx.transactionCodeList,
              selectedItem: _ctx.formModel.transactionCode,
              modelValue: _ctx.formModel.transactionCode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formModel.transactionCode = $event))
            }, null, 8, ["options", "selectedItem", "modelValue"])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}