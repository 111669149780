
import {
  optionClientCategory,
  optionCompanySize
} from "@/app/infrastructures/misc/Constants";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { OptionsClass } from "@/domain/entities/MainApp";
import debounce from "lodash/debounce";
import { prop, Vue } from "vue-class-component";

export type ClientParentSectionProp = {
  companyName: string;
  clientCategory: OptionsClass;
  companySize: OptionsClass;
  address: string;
  district: string;
  email: string;
  phone: string;
  isNeedAssessment: boolean;
};

export type ClientParentErrorSectionProp = {
  email: boolean;
  phone: boolean;
};

class Props {
  modelValue = prop<ClientParentSectionProp>({
    default: {
      address: "",
      clientCategory: new OptionsClass(),
      companyName: "",
      companySize: new OptionsClass(),
      district: "",
      email: "",
      phone: ""
    },
    required: true,
    type: Object
  });
  error = prop<ClientParentErrorSectionProp>({
    default: {
      email: false,
      phone: false
    }
  });
}

export default class ClientParentSection extends Vue.with(Props) {
  // client category
  clientCategoryList = optionClientCategory;

  //company size
  companySizeList = optionCompanySize;

  get formModel() {
    return this.modelValue;
  }

  set formModel(val) {
    this.$emit("update:modelValue", val);
  }

  get formErrorModel() {
    return this.error;
  }

  set formErrorModel(val) {
    this.$emit("update:error", val);
  }

  mounted(): void {
    this.fetchDistrictList("");
  }

  // district list
  fetchDistrictList = debounce((search: string) => {
    ClientRegistrationController.getDistrictList({
      search: search
    });
  }, 250);

  get districtList() {
    return ClientRegistrationController.districtList;
  }

  get loadingDistrictList() {
    return ClientRegistrationController.loadingDistrictList;
  }

  validatePhone(value: string) {
    this.formErrorModel.phone = !/^\d+$/.test(value) || value.length < 8;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }

  validateEmail(value: string) {
    this.formErrorModel.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }
}
