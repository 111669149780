
import { OptionsClass } from "@/domain/entities/MainApp";
import { prop, Vue } from "vue-class-component";

export type PaymentInformationSectionProp = {
  paymentMethod: OptionsClass;
};

class Props {
  modelValue = prop<PaymentInformationSectionProp>({
    default: {
      paymentMethod: new OptionsClass()
    },
    required: true,
    type: Object
  });
}

export default class PaymentInformationSection extends Vue.with(Props) {
  paymentMethodList = [
    {
      name: "Invoice",
      value: "invoice"
    },
    {
      name: "Split Bill",
      value: "split_bill"
    }
  ];

  get formModel() {
    return this.modelValue;
  }

  set formModel(val) {
    this.$emit("update:modelValue", val);
  }
}
