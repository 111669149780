import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, { label: "Informasi Bank Settlement" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Nama Rekening",
        value: _ctx.detailData.bankSettlement.accountName
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12 capitalize",
        label: "Nama Bank",
        value: _ctx.detailData.bankSettlement.bankName
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Nomor Rekening Virtual Account",
      value: _ctx.detailData.bankSettlement.accountNo
    }, null, 8, ["value"])
  ], 64))
}