
import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";
import ListClientManagementV1 from "../clients/client-management/index.vue";
import ListClientManagementV2 from "../clients-v2/management/index.vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    ListClientManagementV1,
    ListClientManagementV2,
  },
})
export default class EntryClient extends Vue {
  get isClientVersionV2() {
    return FlagsPermissionClient.client_v2_enable.isEnabled();
  }

  get renderedPage(): string {
    return this.isClientVersionV2
      ? "ListClientManagementV2"
      : "ListClientManagementV1";
  }
}
