
import { Options, Vue, prop } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { BankDetail } from "@/domain/entities/ClientRegistration";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { ValidateBankAccountApiRequest } from "@/data/payload/api/BeneficiaryApiRequest";
import { maskBankAccountName } from "@/app/infrastructures/misc/Utils";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";

class Props {
  type = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  components: {
    ModalForm
  }
})
export default class BankModal extends Vue.with(Props) {
  get form() {
    if (this.type === "bank") {
      return ClientRegistrationController.bankDetail;
    } else {
      return ClientRegistrationController.bankDetailSettlement;
    }
  }
  error = {
    accountNo: false,
    email: false,
    accountNoSettlement: false
  };
  errorMessage = "";
  disableValidate = false;
  disableSave = true;

  validateAccountNo(value: string) {
    this.disableValidate = false;
    this.disableSave = true;
    this.errorMessage = "";
    this.error.accountNo = !/^\d+$/.test(value);
  }
  validateBankEmail(value: string) {
    this.error.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }
  get bankList() {
    return PartnerController.partnerBank.map(bank => {
      return { name: bank.bankName, value: bank.bankCode };
    });
  }
  fetchListBank() {
    PartnerController.getListPartnerBank();
  }
  get loadingBank() {
    return PartnerController.isLoadingBank;
  }
  get isLoadingAddBank() {
    return BeneficiaryAccountController.isLoadingValidationBank;
  }
  onAddBank() {
    this.onCloseAddBank();
    this.type === "bank"
      ? ClientRegistrationController.setBankDetail(
          new BankDetail({
            bankLabel: this.form.bankLabel,
            bankName: this.form.bankName,
            accountNo: this.form.accountNo,
            accountName: this.form.accountName,
            email: this.form.email,
            logo: this.form.logo
          })
        )
      : ClientRegistrationController.setBankDetailSettlement(
          new BankDetail({
            bankLabel: this.form.bankLabel,
            bankName: this.form.bankName,
            accountNo: this.form.accountNo,
            accountName: this.form.accountName,
            email: this.form.email,
            logo: this.form.logo
          })
        );
  }
  onResetAddBank() {
    this.onCloseAddBank();
    this.type === "bank"
      ? ClientRegistrationController.setBankDetail(new BankDetail())
      : ClientRegistrationController.setBankDetailSettlement(new BankDetail());
  }
  onCloseAddBank() {
    ClientRegistrationController.setAddBank(false);
    ClientRegistrationController.setAddBankSettlement(false);
  }
  get isDisableCheck() {
    return !this.form.bankName || !this.form.accountNo || this.error.accountNo;
  }
  get isDisableFormBank() {
    if (this.type === "bank") {
      return (
        this.isDisableCheck ||
        !this.form.accountName ||
        this.error.email ||
        !!this.errorMessage ||
        this.disableSave
      );
    }
    return (
      !this.formSettlement.accountNo ||
      !this.formSettlement.accountName ||
      !this.formSettlement.bankName?.name ||
      this.error.accountNoSettlement
    );
  }
  async onCheckValidationBank() {
    if (this.errorCauseValidationBank === "internet") {
      BeneficiaryAccountController.setErrorCauseValidationBank("");
      return;
    }

    const bankName: any = this.form.bankName;
    const result: any = await BeneficiaryAccountController.validateBankAccount(
      new ValidateBankAccountApiRequest(bankName.value, this.form.accountNo)
    );

    if (result?.accountName) {
      this.errorMessage = "";
      this.form.accountName = result?.accountName;
      this.form.bankLabel = bankName.name;
      this.form.bankName = bankName.value;
      this.disableValidate = true;
      this.disableSave = false;
      ClientRegistrationController.setBankDetail(this.form);
    } else {
      this.errorMessage = result?.response?.data?.message.id || "";
    }
  }
  get maskedBankAccountName() {
    return maskBankAccountName(this.form.accountName);
  }

  // error
  get errorCauseValidationBank() {
    return BeneficiaryAccountController.errorCauseValidationBank;
  }

  onChangeBankName() {
    this.form.accountNo = "";
    this.form.accountName = "";
  }

  //bank settlement
  get formSettlement(): any {
    return ClientRegistrationController.bankDetailSettlement;
  }
  //nama bank
  vaBankNameSettlementList = [
    {
      name: "Pilih bank",
      value: {
        name: "",
        logo: ""
      }
    },
    {
      name: "VA BCA",
      value: {
        name: "bca",
        logo: `${ConfigBucketStorage.config_bucket_bank_logo.getValue()}bca.png`
      }
    },
    {
      name: "VA Mandiri",
      value: {
        name: "mandiri",
        logo: `${ConfigBucketStorage.config_bucket_bank_logo.getValue()}mandiri.png`
      }
    }
  ];

  isOpenBankSettlement = false;
  onOpenSelectRangeBankSettlement() {
    this.isOpenBankSettlement = true;
  }

  onCloseSelectRangeBankSettlement() {
    this.isOpenBankSettlement = false;
  }

  onSelectRangeBankSettlement(key: string, value: any) {
    this.onCloseSelectRangeBankSettlement();
    this.formSettlement.bankName = value;
    this.formSettlement.logo = value.logo;
    this.formSettlement.bankLabel = key;
  }

  validateAccountNoSettlement(value: string) {
    this.error.accountNoSettlement = !/^\d+$/.test(value);
  }

  formatNumber(str: string) {
    return str.replace(/[\D]+/g, "");
  }
}
