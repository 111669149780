
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { Options, prop, Vue } from "vue-class-component";
class Props {
  approvalType = prop<string>({
    type: String,
    default: "", //renewal|current
  });
  id = prop<number>({
    type: Number,
    default: 0, //renewal id atau client id
  });
  clientName = prop<string>({
    type: String,
    default: "",
  });
  clientType = prop<string>({
    type: String,
    default: "", // Induk atau Cabang
  });
  errorMessage = prop<string>({
    type: String,
    default: "", // error message
  });
}
@Options({
  emits: ["onGetDetail"],
  components: {},
})
export default class ConfirmationApprovalRejectClient extends Vue.with(Props) {
  openRejectConfirmation() {
    this.rejectConfirmation = true;
  }
  openApprovalConfirmation() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-confirmation-general",
        title: `Terima pengajuan klien ${this.clientType.toLowerCase()}?`,
        message:
          this.clientType.toLowerCase() === "induk"
            ? "Pastikan seluruh detail klien & pengajuan harga sudah benar dan sesuai."
            : "Sebelum menyetujui permintaan ini, pastikan seluruh detail klien sudah benar dan sesuai.",
        textCancel: "Kembali",
        textSuccess: "Ya, Terima",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: () => {
          this.onSubmitApproval();
        },
      })
    );
  }

  rejectConfirmation = false;

  messageSuccess = "";
  titleSuccess = "";
  openSelect = false;

  rejectedReasons = [
    {
      name: "Pilih alasan penolakan",
      value: "",
    },
    {
      name: "Diskon Tidak Disetujui",
      value: "Diskon Tidak Disetujui",
    },
    {
      name: "Custom Rates Tidak Disetujui",
      value: "Custom Rates Tidak Disetujui",
    },
    {
      name: "Diskon & Custom Rate Tidak Disetujui",
      value: "Diskon & Custom Rate Tidak Disetujui",
    },
  ];
  selectedReason = "";

  async onSubmitApproval() {
    let resp = false;
    MainAppController.closeMessageModal();
    if (this.approvalType === "renewal") {
      resp = await ClientManagementController.approveRenewRates({
        id: this.id,
      });
      this.$emit("success-approve");
    } else {
      await ClientRegistrationController.approveClientRegistration({
        id: this.id,
      });
      this.$emit("success-approve");
    }
    if (resp) {
      this.onShowSnackbarSuccess();
      this.$emit("onGetDetail"); // please call get detail
    }
  }

  onShowSnackbarSuccess() {
    if (this.approvalType === "renewal") {
      MainAppController.setSnackbarMessage(
        `Persetujuan pembaruan harga ${this.clientType.toLowerCase()} “${
          this.clientName
        }” berhasil diterima!`
      );
    } else {
      MainAppController.setSnackbarMessage(
        `Pengajuan klien ${this.clientType.toLowerCase()} “${
          this.clientName
        }” berhasil diterima!`
      );
    }
    MainAppController.setSnackbarPositivity(true);
    MainAppController.setSnackbar(true);
  }

  async onRejected() {
    let resp = false;
    MainAppController.closeMessageModal();
    this.rejectConfirmation = false;
    if (this.approvalType === "renewal") {
      resp = await ClientManagementController.rejectRenewRates({
        id: this.id,
        rejectReason: this.selectedReason,
      });
      this.$emit("success-reject");
    } else {
      await ClientRegistrationController.rejectClientRegistration({
        id: this.id,
        rejectReason: this.selectedReason,
      }).then(this.onShowSnackbarError);
      this.$emit("success-reject");
    }
    if (resp) {
      this.onShowSnackbarError();
    }
  }

  onShowSnackbarError() {
    MainAppController.setSnackbar(false);
    if (this.approvalType === "renewal") {
      MainAppController.setSnackbarMessage(
        `Penolakan pembaruan harga klien ${this.clientType.toLowerCase()} “${
          this.clientName
        }” berhasil ditolak!`
      );
    } else {
      MainAppController.setSnackbarMessage(
        `Permintaan klien ${this.clientType.toLowerCase()} “${
          this.clientName
        }” berhasil ditolak!`
      );
    }
    MainAppController.setSnackbarTimeout(4000);
    MainAppController.setSnackbarPositivity(false);
    MainAppController.setSnackbar(true);
  }

  closeRejectConfirmation() {
    this.rejectConfirmation = false;
    this.selectedReason = "";
  }

  onSelectReason(_: string, val: string) {
    this.selectedReason = val;
    this.openSelect = false;
  }

  get isDisableReject() {
    return this.selectedReason === "";
  }
}
