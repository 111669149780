
import { Options, prop, Vue } from "vue-class-component";
import BankModal from "../../../clients/client-registration/component/bank-modal.vue";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { BankDetail } from "@/domain/entities/ClientRegistration";

class Props {
  isClearDefault = prop<boolean>({
    default: false
  });
  type = prop<string>({
    default: "registration"
  });
}

@Options({
  components: {
    BankModal
  }
})
export default class BankInformationSection extends Vue.with(Props) {
  created() {
    this.setBankDetail();
    if (this.isClearDefault) {
      this.onDeleteBank();
    }
  }
  get isAddBank() {
    return ClientRegistrationController.isAddBank;
  }

  get isAddSettlement() {
    return ClientRegistrationController.isAddBankSettlement;
  }

  onAddBank() {
    ClientRegistrationController.setAddBank(true);
  }

  onAddSettlement() {
    ClientRegistrationController.setAddBankSettlement(true);
  }

  get bankDetail() {
    return ClientRegistrationController.bankDetail;
  }

  get settlementDetail() {
    return ClientRegistrationController.bankDetailSettlement;
  }

  get isShowBankLogo() {
    return ["bni", "bca", "bri"].includes(this.bankDetail.bankName);
  }

  get isShowSettlemenBankLogo() {
    return this.settlementDetail.bankName === "bca";
  }

  // delete
  isDelete = false;
  isDeleteSettlement = false;

  onConfirmDelete(value: boolean) {
    this.isDelete = value;
  }

  onConfirmDeleteSettlement(value: boolean) {
    this.isDeleteSettlement = value;
  }

  onDeleteBank() {
    this.onConfirmDelete(false);
    ClientRegistrationController.setBankDetail(new BankDetail());
  }

  onDeleteSettlement() {
    this.onConfirmDeleteSettlement(false);
    ClientRegistrationController.setBankDetailSettlement(new BankDetail());
  }

  setBankDetail() {
    ClientRegistrationController.setBankDetail(
      this.type === "registration"
        ? ClientRegistrationController.bankDetail
        : ClientManagementController.detailClientManagement.bankDetail
    );
  }

  setBankDetailSettlement() {
    ClientRegistrationController.setBankDetailSettlement(
      this.type === "registration"
        ? ClientRegistrationController.clientRegistrationDetail.bankSettlement
        : ClientManagementController.detailClientManagement.bankSettlement
    );
  }
}
