
import { Options, Vue } from "vue-class-component";
import CheckCircle from "@/app/ui/components/icons/modules/check-circle.vue";
import Card from "@/app/ui/components/card/index.vue";
import DetailClientParent from "../../global-components/form-sections/client-parent.vue";
import Tax from "../../global-components/form-sections/tax.vue";
import ContactInfo from "../../global-components/form-sections/contact-information.vue";
import PaymentInfo from "../../global-components/form-sections/payment-information.vue";
import BillingInfo from "../../global-components/form-sections/billing-information.vue";
import Agreement from "../../global-components/form-sections/agreement.vue";
import BankInfo from "../../global-components/form-sections/bank-information.vue";
import PriceTypeForm from "../../global-components/price-type-form.vue";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { convertArrayToString, convertPhoneNumber, formatDateNormal, formatDateNumber } from "@/app/infrastructures/misc/Utils";
import { capitalize } from "vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { BankDetail } from "@/domain/entities/ClientRegistration";

@Options({
  components: {
    CheckCircle,
    Card,
    DetailClientParent,
    Tax,
    ContactInfo,
    PaymentInfo,
    BillingInfo,
    Agreement,
    BankInfo,
    PriceTypeForm,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess && !this.isErrorDocument) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ClientRegistrationController.setOpenModalSuccess(false);
      ClientRegistrationController.setErrorDocument(false);
      next();
    }
  },
})
export default class AddClientRegistration extends Vue {
  mounted() {
    this.form.priceRateStartDate = new Date(
      this.$moment()
        .add(1, "days")
        .toDate()
    );
    this.form.priceRateEndDate = new Date(
      this.$moment()
        .add(2, "days")
        .toDate()
    );
    ClientRegistrationController.setBankDetail(new BankDetail());
    ClientRegistrationController.setBankDetailSettlement(new BankDetail());
  }

  // route navigation
  async goBack() {
    await this.$router.push("/client/client-registration");
  }

  get getTitle() {
    return this.$route.meta.name;
  }

  isFirstStep = true;
  switchStep(step: number) {
    if (step === 0) {
      this.isFirstStep = true;
    } else {
      this.isFirstStep = false;
    }
  }

  get isFormFirstStepValid() {
    let result = false;
    if (
      this.form.clientParent.companyName &&
      this.form.clientParent.clientCategory &&
      this.form.clientParent.companySize &&
      this.form.clientParent.address &&
      this.form.clientParent.district &&
      this.form.clientParent.email &&
      this.form.clientParent.phone &&
      this.form.tax.NIKAddress &&
      this.form.tax.NIKName &&
      this.form.tax.taxNumber &&
      this.form.tax.transactionCode &&
      this.form.contact.name &&
      this.form.contact.level &&
      this.form.contact.email &&
      this.form.contact.phone &&
      this.form.payment.paymentMethod &&
      this.form.billing.noWhatsApp &&
      this.form.billing.billingSchedule &&
      this.form.billing.timeRangePayment &&
      this.form.billing.email &&
      this.form.agreement.startDate &&
      this.form.agreement.endDate &&
      this.form.agreement.attachment
    ) {
      result = true;
    }
    return result;
  }

  get isFormSecondStepValid() {
    let result = false;

    if (this.form.isCustomRate === "retail") {
      if (!this.form.isInsurance || (this.form.isInsurance && this.form.insurance && !this.error.customRates)) result = true;
    }

    if (this.form.isCustomRate === "normal") {
      if (
        (this.form.versionBasic && this.form.originCities.length && this.form.discount && !this.error.discount && !this.form.isInsurance) ||
        (this.form.versionBasic && this.form.originCities.length && this.form.discount && !this.error.discount && this.form.isInsurance && this.form.insurance && !this.error.customRates)
      ) result = true;
    }

    if (this.form.isCustomRate === "khusus") {
      if (
        this.form.versionName && this.form.archiveFile.name && !this.form.isInsurance ||
        (this.form.versionName && this.form.archiveFile.name && this.form.isInsurance && this.form.insurance && !this.error.customRates)
      ) result = true;
    }

    if (this.form.isCustomRate === "kombinasi") {
      if (
        this.form.versionName && this.form.versionBasic && this.form.originCities.length && this.form.discount && !this.error.discount && this.form.archiveFile.name && !this.form.isInsurance ||
        (this.form.versionName && this.form.versionBasic && this.form.originCities.length && this.form.discount && !this.error.discount && this.form.archiveFile.name && this.form.isInsurance && this.form.insurance && !this.error.customRates)
      ) result = true;
    }

    return result && !this.error.priceRateStartDate && !this.error.priceRateEndDate;
  }

  form: any = {
    clientParent: {
      companyName: "",
      clientCategory: "",
      companySize: "",
      address: "",
      district: "",
      email: "",
      phone: "",
      isNeedAssessment: false
    },
    tax: {
      NIKAddress: "",
      NIKName: "",
      taxNumber: "",
      transactionCode: ""
    },
    contact: {
      name: "",
      level: "",
      email: "",
      phone: ""
    },
    payment: {
      paymentMethod: "",
    },
    billing: {
      noWhatsApp: "",
      billingSchedule: "",
      timeRangePayment: "",
      email: [],
    },
    agreement: {
      startDate: "",
      endDate: "",
      attachment: "",
      fileNameAttachment: "",
      attachmentUrl: "",
    },
    isInsurance: false,
    customRates: 2,
    insurance: "",
    originCities: [{ name: "Semua 3LC", value: "All" }],
    retailBasedDistrict: false,
    versionBasic: "",
    isCustomRate: "retail",
    discount: "",
    priceRateStartDate: "",
    priceRateEndDate: "",
    versionName: "",
    archiveFile: "",
    parent: {
      parentName: "",
      posName: "",
      commision: "",
      commisionType: "",
      numeratorCommission: ""
    },
    cod: false,
    do: false,
    codRej: {
      receiverName: "",
      receiverAddress: "",
      district: "",
      commision: "",
      padCommision: "",
      phone: "",
      codAmountType: "",
      codShipmentDiscount: ""
    },
    clientBranchDoPaymentType: "",
    clientIsDfod: false,
    isReturnConfig: false,
    returnConfigLimit: 7,
    returnConfigReasonIds: [{ "name": "Semua alasan", "value": "all" }],
  };

  error = {
    clientParent: {
      email: false,
      phone: false,
    },
    tax: {
      taxNumber: false,
    },
    contact: {
      contactEmail: false,
      contactPhone: false,
    },
    billing: {
      emailBilling: false,
      contactWhatsApp: false,
    },
    agreement: {
      invalidDate: false,
    },
    customRates: false,
    versionBasic: false,
    discount: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    versionName: false,
    archiveFile: false,
    commision: false,
    codRejdistrict: false,
    codRejCommision: false,
    codRejPhone: false,
    padCommision: false,
    codRejShipmentDiscount: false
  };

  customPriceOption = [
    {
      label: "Harga Retail",
      value: "retail",
      description: "Harga akan mengikuti harga retail.",
      disabled: false,
    },
    {
      label: "Harga Normal",
      value: "normal",
      description: "Harga akan mengikat selama periode harga yang ditentukan.",
      disabled: false,
    },
    {
      label: "Harga Khusus",
      value: "khusus",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload.",
      disabled: false,
    },
    {
      label: "Harga Kombinasi",
      value: "kombinasi",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload & harga retail.",
      disabled: false,
    },
  ];

  get validateStartEndDate() {
    const endDate = new Date(
      this.form.priceRateEndDate.getFullYear(),
      this.form.priceRateEndDate.getMonth(),
      this.form.priceRateEndDate.getDate()
    );
    const startDate = new Date(
      this.form.priceRateStartDate.getFullYear(),
      this.form.priceRateStartDate.getMonth(),
      this.form.priceRateStartDate.getDate()
    );
    const value =
      startDate.getTime() > endDate.getTime() ||
      startDate.getTime() === endDate.getTime() ||
      endDate.getTime() < startDate.getTime() ||
      endDate.getTime() === startDate.getTime();
    this.error.priceRateStartDate = value;
    this.error.priceRateEndDate = value;
    return value;
  };

  // close/back from create
  isAddTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return !!(
      this.form.clientParent.companyName ||
      this.form.clientParent.address ||
      this.form.clientParent.email ||
      this.form.archiveFile ||
      this.form.discount ||
      this.form.clientParent.phone ||
      this.form.tax.taxNumber ||
      this.form.versionBasic ||
      this.form.priceRateStartDate ||
      this.form.priceRateEndDate ||
      this.form.versionName
    );
  }

  onOpenClosePage(to: any) {
    this.isAddTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isAddTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isAddTerminated = false;
  }

  handleErrorClose() {
    ClientRegistrationController.setErrorEdit(false);
  }

  // Error Document
  get isErrorDocument() {
    return ClientRegistrationController.isErrorDocument;
  }

  get bankDetail(): any {
    return ClientRegistrationController.bankDetail;
  }

  get bankDetailSettlement(): any {
    return ClientRegistrationController.bankDetailSettlement;
  }

  // get Price Type
  get priceType() {
    let priceTypeVal = "";
    const lowerCustomRate = this.form.isCustomRate.toLowerCase();
    if (lowerCustomRate === 'retail') priceTypeVal = 'retail';
    if (lowerCustomRate === 'normal' && this.form.originCities[0].value === 'All') priceTypeVal = 'normal';
    if (lowerCustomRate === 'normal' && this.form.originCities[0].value !== 'All') priceTypeVal = 'normal_custom_3lc';
    if (lowerCustomRate === 'khusus' && !this.form.retailBasedDistrict) priceTypeVal = 'custom_client';
    if (lowerCustomRate === 'khusus' && this.form.retailBasedDistrict) priceTypeVal = 'custom_client_retail_district';
    if (lowerCustomRate === 'kombinasi' && this.form.originCities[0].value === 'All') priceTypeVal = 'combination';
    if (lowerCustomRate === 'kombinasi' && this.form.originCities[0].value !== 'All') priceTypeVal = 'combination_custom_3lc';
    return priceTypeVal;
  }

  get insurancePercentage() {
    return this.form.isInsurance && this.form.insurance === 'custom-rates' ? Number(Number('0.' + this.form.customRates).toFixed(2)) : 0
  }

  // Save data
  /* eslint-disable @typescript-eslint/camelcase */
  async _onAdd() {
    this.onCloseSave();
    this.handleErrorClose();
    let startDate = this.form.priceRateStartDate;
    startDate = new Date(
      startDate.setDate(startDate.getDate())
    ).toLocaleDateString("fr-CA");
    let endDate = this.form.priceRateEndDate;

    if (this.form.isCustomRate === "retail") {
      endDate = this.form.agreement.endDate;
    }

    endDate = new Date(endDate.setDate(endDate.getDate())).toLocaleDateString(
      "fr-CA"
    );

    const payload = {
      //clientParent
      companyName: this.form.clientParent.companyName,
      category: this.form.clientParent.clientCategory.value,
      company_size: capitalize(this.form.clientParent.companySize.value),
      address: this.form.clientParent.address,
      district: this.form.clientParent.district,
      email: this.form.clientParent.email,
      phone: convertPhoneNumber(this.form.clientParent?.phone),
      isNeedAssessment: this.form.clientParent.isNeedAssessment,
      //tax
      taxpayer_name: this.form.tax.NIKName,
      taxpayer_address: this.form.tax.NIKAddress,
      taxNumber:this.form.tax.taxNumber,
      transaction_code:this.form.tax.transactionCode.value,
      // //contact
      contactName: this.form.contact.name,
      contactJobTitle: this.form.contact.level,
      contactEmail: this.form.contact.email,
      contactPhone: convertPhoneNumber(this.form.contact?.phone),
      // //payment
      payment_method: this.form.payment.paymentMethod.value,
      // //billing
      billing_wa_number: convertPhoneNumber(this.form.billing?.noWhatsApp),
      billing_schedule_so: this.form.billing.billingSchedule.value.startsWith("bi") ? "Bi-Weekly" : capitalize(this.form.billing.billingSchedule.value),
      billing_payment_periode: `${this.form.billing.timeRangePayment.value} Days`,
      billing_email: convertArrayToString(this.form.billing.email, "name"),
      //agreement
      contract_start_date: formatDateNumber(this.form.agreement.startDate),
      contract_end_date: formatDateNumber(this.form.agreement.endDate),
      contract_attachment: this.form.agreement.attachment,

      //price
      versionBasic:
        this.form.isCustomRate === 'khusus' || this.form.isCustomRate === 'retail'
          ? { rateVersionName: "" }
          : this.form.versionBasic,
      isCustomRate: this.form.isCustomRate === 'kombinasi',
      isCustomRateOnly: this.form.isCustomRate === 'khusus',
      discount: Number(this.form.discount.replace(/,/g, ".")),
      priceRateStartDate: startDate,
      priceRateEndDate: endDate,
      versionName: this.form.isCustomRate ? this.form.versionName : "",
      archiveFile: this.form.isCustomRate ? this.form.archiveFile : "",
      custom_price_type: this.form.isCustomRate.toUpperCase(),
      price_type: this.priceType,
      insurance_type: this.form.isInsurance ? this.form.insurance === 'custom-rates' ? 'custom' : 'bundling' : '',
      insurance_percentage: this.insurancePercentage,
      lc_origin: this.form.originCities[0].value === 'All' ? '' : this.form.originCities.map((oc: any) => oc.name).join(', '),

      //other
      type: "parent",

      //bank    
      bankName: this.bankDetail.bankName.value || this.bankDetail.bankName,
      bankAccountName: this.bankDetail.accountName,
      bankAccountNumber: this.bankDetail.accountNo,
      bankAccountEmail: this.bankDetail.email,
      bankAccountLabel: this.bankDetail.bankLabel,
      //bank settlememt
      va_bank_name: this.bankDetailSettlement.bankName.name ?? "",
      va_bank_account_name: this.bankDetailSettlement.accountName ?? "",
      va_bank_number: this.bankDetailSettlement.accountNo ?? "",
      va_bank_email: this.bankDetailSettlement.email,
      va_bank_label: this.bankDetailSettlement.bankLabel
        ? `${this.bankDetailSettlement.bankLabel.toUpperCase()} Virtual Account`
        : "",

      client_type: "parent",

      is_efaktur_with_nik: false,
      nik_id: "",
      nik_name: "",
      nik_address: "",
      version: "v2"
    };
    const add = await ClientRegistrationController.addClientRegistration(payload);

    if (add) {
      this.openSuccess = true;
      await this.goBack();
      MainAppController.setSnackbarMessage(
        `Pembuatan klien induk “${this.form.clientParent.companyName}” berhasil! Tunggu Admin menyetujui permintaan Anda.`
      );
      MainAppController.setAutoClose(true);
      MainAppController.setSnackbar(true);
      MainAppController.setSnackbarTimeout(3000);
      ClientRegistrationController.setBankDetailSettlement(new BankDetail({}));
      ClientRegistrationController.setBankDetail(new BankDetail({}));
    }
  }
  openSuccess = false;

  get openSave() {
    return ClientRegistrationController.openModal;
  }
  onOpenSave() {
    ClientRegistrationController.setOpenModal(true);
  }
  onCloseSave() {
    ClientRegistrationController.setOpenModal(false);
  }

  formateRatePeriod(start: string, end: string) {
    const isSameMonthYear =
      formatDateNormal(start, "MMMM-YYYY") ===
      formatDateNormal(end, "MMMM-YYYY");

    return `${formatDateNormal(
      start,
      isSameMonthYear ? "DD" : "DD MMMM YYYY"
    )} - ${formatDateNormal(end, "DD MMMM YYYY")}`;
  }

  get periodeHarga() {
    let endDate = this.form.priceRateEndDate;

    if (this.form.isCustomRate === "retail") {
      endDate = this.form.agreement.endDate;
    }

    return this.formateRatePeriod(this.form.priceRateStartDate, endDate);
  }

  get customConfirmMesage() {
    let template = '';
    if (this.form.isCustomRate === 'retail') {
      template = `
        <div class='font-semibold text-base mb-4'>Konfirmasi pendaftaran klien induk?</div>
        <div class='bg-gray-lp-1000 rounded-xl py-4 px-6 flex text-sm justify-between text-left flex-wrap'>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Periode harga</div>
          <div class ='mr-2'>${this.periodeHarga || '-'}</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Harga</div>
          <div class='ml-2'>Harga retail</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Perhitungan asuransi</div>
          <div class='mr-2'>${this.form.isInsurance ? this.form.insurance === 'custom-rates' ? 'Custom Rates 0,' + this.form.customRates + '%' : 'Free' : 'Retail (Tiering)'}</div>
        </div>
      `
    }
    if (this.form.isCustomRate === 'normal') {
      template = `
        <div class='font-semibold text-base mb-4'>Konfirmasi pendaftaran klien induk?</div>
        <div class='bg-gray-lp-1000 rounded-xl py-4 px-6 flex text-sm justify-between text-left flex-wrap'>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Periode harga</div>
          <div class='mr-2'>${this.periodeHarga}</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Tipe kustomisasi harga</div>
          <div class='ml-2'>Harga normal</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Harga</div>
          <div class='mr-2'>Harga normal</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Harga Normal</div>
          <div class='ml-2'>${this.form.versionBasic?.rateVersionName}</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Perhitungan asuransi</div>
          <div class='mr-2'>${this.form.isInsurance ? this.form.insurance === 'custom-rates' ? 'Custom Rates 0,' + this.form.customRates + '%' : 'Free' : 'Retail (Tiering)'}</div>
        </div>
      `
    }
    if (this.form.isCustomRate === 'khusus') {
      template = `
        <div class='font-semibold text-base mb-4'>Konfirmasi pendaftaran klien induk?</div>
        <div class='bg-gray-lp-1000 rounded-xl py-4 px-6 flex text-sm justify-between text-left flex-wrap'>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Periode harga</div>
          <div class='mr-2'>${this.periodeHarga}</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Tipe kustomisasi harga</div>
          <div class='ml-2'>Harga khusus</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Harga</div>
          <div class='mr-2'>Diupload</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Harga khusus</div>
          <div class='ml-2'>${this.form.archiveFile.name}</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Perhitungan berat</div>
          <div class='mr-2'>Chargeable Weight (Pembulatan aktif)</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Perhitungan asuransi</div>
          <div class='ml-2'>${this.form.isInsurance ? this.form.insurance === 'custom-rates' ? 'Custom Rates 0,' + this.form.customRates + '%' : 'Free' : 'Retail (Tiering)'}</div>
        </div>
      `
    }
    if (this.form.isCustomRate === 'kombinasi') {
      template = `
        <div class='font-semibold text-base mb-4'>Konfirmasi pendaftaran klien induk?</div>
        <div class='bg-gray-lp-1000 rounded-xl py-4 px-6 flex text-sm justify-between text-left flex-wrap'>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Periode harga</div>
          <div class='mr-2'>${this.periodeHarga}</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Tipe kustomisasi harga</div>
          <div class='ml-2'>Harga kombinasi</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Harga</div>
          <div class='mr-2'>Diupload</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold ml-2'>Harga khusus</div>
          <div class='ml-2'>${this.form.archiveFile.name}</div>
        </div>
        <div class='w-1/2 mb-2'>
          <div class='font-semibold mr-2'>Perhitungan asuransi</div>
          <div class='mr-2'>${this.form.isInsurance ? this.form.insurance === 'custom-rates' ? 'Custom Rates 0,' + this.form.customRates + '%' : 'Free' : 'Retail (Tiering)'}</div>
        </div>
      `
    }

    return template;
  }
}
