export const translateStatusClient = (status: string) => {
  switch (status.toLowerCase()) {
    case "freeze":
      return {
        color: "bg-red-300",
        textColor: "text-red-lp-200",
        label: "Freezed"
      };
    case "active":
      return {
        color: "bg-green-lp-300",
        textColor: "text-green-lp-200",
        label: "Active"
      };
    case "pending":
      return {
        color: "bg-yellow-lp-200",
        textColor: "text-yellow-lp-100",
        label: "Pending"
      };
    case "nearly_expired":
      return {
        color: "bg-yellow-lp-100",
        textColor: "text-yellow-200",
        label: "Nearly Expired"
      };
    case "expired":
      return {
        color: "bg-red-300",
        textColor: "text-red-lp-200",
        label: "Expired"
      };
    case "approved":
      return {
        color: "bg-green-lp-300",
        textColor: "text-green-lp-200",
        label: "Approved"
      };
    case "rejected":
      return {
        color: "bg-red-300",
        textColor: "text-red-lp-200",
        label: "Rejected"
      };
    case "requested":
      return {
        color: "bg-yellow-lp-200",
        textColor: "text-yellow-lp-100",
        label: "Requested"
      };
    case "uploading":
      return {
        color: "bg-yellow-lp-300",
        textColor: "text-white",
        label: "Uploading"
      };
    default:
      return {
        color: "bg-green-lp-300",
        textColor: "text-green-lp-200",
        label: status
      };
  }
};
