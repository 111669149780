
import { Options, prop, Vue } from "vue-class-component";

export type CardTab = {
  name: string;
  slotName: string;
};

class Props {
  tabs = prop<CardTab[]>({
    default: () => [],
    required: true,
    type: Array,
  });
  title = prop<string>({
    default: "Detail",
    required: true,
    type: String,
  });
  buttonLabel = prop<string>({
    default: "Ubah detail",
    required: false,
    type: String,
  });
  detailClient = prop<any>({
    default: "",
    type: Object,
  });
}

@Options({
  emits: ["action"],
})
export default class CardTabDetail extends Vue.with(Props) {
  activeTab = "";
  beforeMount() {
    if (this.tabs.length) {
      this.activeTab = this.tabs[0].name;
    }
  }

  isTabActive(tab: string) {
    return this.activeTab === tab;
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }

  get routerForm() {
    return this.$route.meta.form;
  }

  get isParent() {
    return this.detailClient.clientType.toLowerCase() === "client induk";
  }

  onClickDetail() {
    this.$router.push(
      `/client/${this.detailClient.id}/edit-client-${
        this.isParent ? "parent" : "branch"
      }/${this.routerForm === "management" ? "management" : "registration"}`
    );
    this.$emit("action");
  }
}
