
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";

class Props {
  isManagement = prop<boolean>({
    required: false,
    default: false,
    type: Boolean
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailBillingInformation extends Vue.with(Props) {
  get detailData() {
    if (this.isManagement) {
      return ClientManagementController.detailClientManagement;
    }

    return ClientRegistrationController.clientRegistrationDetail;
  }
}
