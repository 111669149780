
import { Options, prop, Vue } from "vue-class-component";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Multiselect from "@/app/ui/components/multiselect-search-tag/index.vue";

class Props {
  form = prop<any>({
    type: Object,
    default: {}
  });
  back = prop<boolean>({
    type: Boolean,
    default: false
  });
  error = prop<any>({
    type: Object,
    default: {}
  });
  type = prop<string>({
    type: String,
    default: "create"
  });
  styleWrapper = prop<string>({
    type: String,
    default: "w-10/12"
  });
}
@Options({
  components: {
    Title,
    UploadFile,
    Multiselect
  }
})
export default class BillingSection extends Vue.with(Props) {
  mounted() {
    if (!this.back) {
      this.setObject();
    }
  }

  value = [];
  options = [];

  //billing schedule
  billingScheduleList = [
    {
      name: "Pilih Jadwal Penagihan",
      value: ""
    },
    {
      name: "Daily",
      value: "daily"
    },
    {
      name: "Weekly",
      value: "weekly"
    },
    {
      name: "Bi-Weekly",
      value: "bi-weekly"
    },
    {
      name: "Monthly",
      value: "monthly"
    }
  ];

  isOpenBillingSchedule = false;
  onOpenSelectBillingSchedule() {
    this.isOpenBillingSchedule = true;
  }

  onCloseSelectBillingSchedule() {
    this.isOpenBillingSchedule = false;
  }

  onSelectBillingSchedule(key: string, value: string) {
    this.onCloseSelectBillingSchedule();
    this.form.billingSchedule = value;
  }

  //range time payment
  rangeTimePaymentList = [
    {
      name: "Pilih jangka waktu",
      value: ""
    },
    {
      name: " 7 Hari",
      value: "07"
    },
    {
      name: "14 Hari",
      value: "14"
    },
    {
      name: "30 Hari",
      value: "30"
    },
    {
      name: "40 Hari",
      value: "40"
    },
    {
      name: "45 Hari",
      value: "45"
    },
    {
      name: "60 Hari",
      value: "60"
    }
  ];

  isOpenRangeTimePayment = false;
  onOpenSelectRangeTimePayment() {
    this.isOpenRangeTimePayment = true;
  }

  onCloseSelectRangeTimePayment() {
    this.isOpenRangeTimePayment = false;
  }

  onSelectRangeTimePayment(key: string, value: string) {
    this.onCloseSelectRangeTimePayment();
    this.form.timeRangePayment = value;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue?.length)
      : newValue;
  }

  validateContactWhatsApp(value: string) {
    this.error.contactWhatsApp = !/^\d+$/.test(value) || value?.length < 8;
  }

  validateDateStartEnd() {
    const dateStart = new Date(this.form.startDate);
    const dateEnd = new Date(this.form.endDate);
    this.error.invalidDate = dateStart.getTime() > dateEnd.getTime();
  }

  public setPeriodeStart(date: Date) {
    this.form.startDate = new Date(
      date?.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.validateDateStartEnd();
  }

  public setPeriodeEnd(date: any) {
    this.form.endDate = new Date(
      date?.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.validateDateStartEnd();
  }

  setObject() {
    const tempData = this.form.email;
    if (this.form.email?.length && this.type === "edit") {
      this.form.email = [];
      tempData.forEach((key: any) => {
        this.form.email.push({
          name: key?.trim(),
          id: key?.trim(),
          isError: false
        });
      });
    }
    if (this.form.startDate && this.form.endDate) {
      this.form.startDate = new Date(this.form.startDate);
      this.form.endDate = new Date(this.form.endDate);
    }
  }

  get errorEmail() {
    const res = this.form.email?.map((key: any) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(key.name);
    });
    this.error.emailBilling = res?.includes(false);
    return this.form.email?.length && this.error.emailBilling;
  }
  onChangeAttachment() {
    this.form.attachmentUrl = "";
  }
}
