
import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";
import DetailClientRegistrationV1 from "../clients/client-registration/detail/index.vue";
import DetailClientRegistrationV2 from "../clients-v2/registration/detail/index.vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    DetailClientRegistrationV1,
    DetailClientRegistrationV2,
  },
})
export default class EntryClient extends Vue {
  get isClientVersionV2() {
    return FlagsPermissionClient.client_v2_enable.isEnabled();
  }

  get renderedPage(): string {
    return this.isClientVersionV2
      ? "DetailClientRegistrationV2"
      : "DetailClientRegistrationV1";
  }
}
