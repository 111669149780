
import { Options, prop, Vue } from "vue-class-component";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";

export type ReturnConfigProp = {
  isActive: boolean;
  limitConfirm: string;
  returnConfigReasonIds: Array<any>; // [{ name: "Semua alasan"; value: "all" }]
};

class Props {
  modelValue = prop<ReturnConfigProp>({
    required: true,
    default: Object,
  });
}

@Options({
  emits: ["update:modelValue", "update:error"],
})
export default class ClientBranchSection extends Vue.with(Props) {
  mounted() {
    PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "",
        reasonStatus: "active",
        isCreateTicketSf: true,
      })
    );
  }

  onUpdateStatus() {
    this.modelValue.isActive = !this.modelValue.isActive;
    this.$emit("update:modelValue", {
      ...this.modelValue,
      isActive: this.modelValue.isActive,
    });
  }

  //return config
  searchReason = "";
  onSearchAddObject(value: string) {
    this.searchReason = value;
  }

  get reasonList() {
    if (!PodDexController.reasonList.length) return [];

    const ListReason = [
      {
        name: "Semua alasan",
        value: "all",
      },
    ];

    return [
      ...ListReason,
      ...PodDexController.reasonList.map((item) => {
        return {
          name: item.statusCode + " - " + item.reasonDescription,
          value: item.reasonId,
        };
      }),
    ].filter((reason) =>
      reason.name
        .toLocaleLowerCase()
        .includes(this.searchReason.toLocaleLowerCase())
    );
  }
}
