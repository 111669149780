
import { Options, prop, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Checkbox from "primevue/checkbox";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
class Props {
  form = prop<any>({
    type: Object,
    default: true
  });
  type = prop<string>({
    type: String,
    default: "create"
  });
  error = prop<any>({
    type: Object,
    default: {}
  });
  styleWrapper = prop<string>({
    type: String,
    default: "w-10/12"
  });
}
@Options({
  components: {
    Title,
    Checkbox
  }
})
export default class TaxSection extends Vue.with(Props) {
  mounted() {
    this.setTaxInfo();
  }
  formatted(value: string) {
    return formattedNPWP(value.replace(/\D+/g, ""));
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value) || value.length < 20;
  }

  validateNIK(value: string) {
    this.error.NIKNumber = !/^\d+$/.test(value) || value.length < 16;
  }

  onChangeCheckbox() {
    this.form.NIK = this.type === "edit" ? this.form?.NIK : "";

    if (this.form.isTaxPayer) {
      this.form.taxNumber = "00.000.000.0-000.000";
    }
    if (!this.form.isTaxPayer) {
      this.error.NIKNumber = false;
    }
  }

  setTaxInfo() {
    if (
      !this.form.isTaxPayer &&
      this.form.taxPayerName &&
      this.form.taxPayerAddress
    ) {
      this.form.NIKName = this.form.taxPayerName;
      this.form.NIKAddress = this.form.taxPayerAddress;
    }
  }

  formatNumber(str: string) {
    return str.replace(/\D+/g, "");
  }
}
