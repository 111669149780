
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import debounce from "lodash/debounce";
import { Options, prop, Vue } from "vue-class-component";

export type ClientBranchSectionProp = {
  companyName: string;
  address: string;
  district: string;
  email: string;
  phone: string;
  taxNumber: string;
};

class Props {
  modelValue = prop<ClientBranchSectionProp>({
    required: true,
    type: Object,
  });
  error = prop<any>({
    required: true,
  });
}

@Options({
  emits: ["update:modelValue", "update:error"],
})

export default class ClientBranchSection extends Vue.with(Props) {

  mounted(): void {
    this.fetchDistrictList("");
  }

  // district list
  fetchDistrictList = debounce((search: string) => {
    ClientRegistrationController.getDistrictList({
      search: search,
    });
  }, 250);

  get districtList() {
    return ClientRegistrationController.districtList;
  }

  get loadingDistrictList() {
    return ClientRegistrationController.loadingDistrictList;
  }

  validatePhone(value: string) {
    this.error.phone = !/^\d+$/.test(value) || value.length < 8;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/[^0-9.-]/g, "");
    return newValue;
  }
  
  validateTaxNumber(value: string) {
    this.error.taxNumber = value.length > 20 || value.length < 15;
  }

  validateEmail(value: string) {
    this.error.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    this.$emit("update:modelValue", {
      ...this.modelValue, email: value
    });
  }
}
