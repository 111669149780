
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
@Options({
  components: {
    Title,
    ModalForm
  },
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    onSubmit: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    ModalShow: {
      default: false,
      type: Boolean
    }
  }
})
export default class extends Vue {
  props: any = this.$props;
  error = {
    priceRateStartDate: false,
    priceRateEndDate: false
  };
  get priceRateStartDate() {
    return ClientManagementController.convertPeriodeStart;
  }
  get priceRateEndDate() {
    return ClientManagementController.convertPeriodeEnd;
  }
  get cachePriceRateEndDate() {
    return ClientManagementController.cachePeriodeEnd;
  }
  // date
  startDateSelected(value: any) {
    ClientManagementController.setPeriodeStart(value);
    this.validateStartEndDate();
  }
  endDateSelected(value: any) {
    ClientManagementController.setPeriodeEnd(value);
    this.validateStartEndDate();
  }
  validateStartEndDate() {
    const endDate = new Date(this.priceRateEndDate);
    const startDate = new Date(this.priceRateStartDate);
    this.error.priceRateStartDate =
      startDate.getTime() > endDate.getTime() ||
      startDate.getTime() === endDate.getTime();
    this.sameDate(endDate)
    this.error.priceRateEndDate =
      endDate.getTime() < startDate.getTime() ||
      endDate.getTime() === startDate.getTime();
  }

  sameDate(endDate: any) {
    return endDate === new Date();
  }
}
