
import { Options, Vue } from "vue-class-component";
import ClientBranch from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/client-branch.vue";
import ReturnConfig from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/return-config.vue";
import ContactInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/contact-info.vue";
import DeliveryService from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/delivery-service.vue";
import DOReturn from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/delivery-order-return.vue";
import STTReturn from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/stt-return.vue";
import ParentClientDetail from "@/app/ui/views/sales/clients-v2/global-components/form-sections-branch/parent-client-detail.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import {
  convertArrayToString,
  convertPhoneNumber,
} from "@/app/infrastructures/misc/Utils";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { EditClientManagementBasicInformationRequest } from "@/data/payload/api/ClientManagementApiRequest";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
@Options({
  components: {
    ClientBranch,
    ReturnConfig,
    ContactInfo,
    DeliveryService,
    DOReturn,
    ParentClientDetail,
    STTReturn,
  },
})
export default class EditBranch extends Vue {
  clientBranchData = {
    companyName: "",
    address: "",
    district: "" as any,
    email: "",
    phone: "",
    taxNumber: "",
  };

  parentClientDetailModel = {
    parent: {
      parentName: "" as any,
      posName: "" as any,
      commision: "",
      commisionType: "",
      numeratorCommission: "",
    },
  };

  contactInfoData = {
    name: "",
    position: "",
    phone: "",
    email: "",
  };

  returnConfigData = {
    isActive: false,
    limitConfirm: "7",
    returnConfigReasonIds: [{ name: "Semua alasan", value: "all" }] as any,
  };

  sttReturnData = {
    isCod: false,
    isDo: false,
    clientIsDfod: false,
    codRej: {
      receiverName: "",
      receiverAddress: "",
      district: "" as any,
      commision: "",
      padCommision: "",
      phone: "",
      codAmountType: "",
      codShipmentDiscount: "",
    },
  };

  clientBranchDoPaymentType = "";

  replaceComma(str: string) {
    return str?.replace(/,/g, ".");
  }

  onResetFormCOD() {
    if (!this.sttReturnData.isCod) {
      this.sttReturnData.clientIsDfod = false;
      this.sttReturnData.codRej.commision = "";
      this.sttReturnData.codRej.codAmountType = "";
      this.sttReturnData.codRej.codShipmentDiscount = "";
    }
  }

  showPopupRegistBranch() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-confirmation-general",
        title: "Ubah Detail Klien Cabang?",
        message: `Pastikan data yang Anda ubah sudah sesuai untuk menghindari data ditolak.`,
        textCancel: "Kembali",
        textSuccess: "Ya, Ubah",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: async () => {
          MainAppController.closeMessageModal();
          await this.createClientBranch();
        },
      })
    );
  }

  async created() {
    await this.fetchDetail();
  }

  getReasonList(val: string) {
    const ListReason = PodDexController.reasonList.map((item) => {
      return {
        name: item.statusCode + " - " + item.reasonDescription,
        value: item.reasonId,
      };
    });

    if (val === "all") {
      return [{
        name: "Semua alasan",
        value: "all",
      }]
    } else {
      const ids = val.split(",").map(Number);
      const reasons = ListReason.filter((item) => ids.includes(item.value));
      return reasons.map((item) => {
        return { name: item.name, value: item.value };
      });
    }
  }

  async fetchDetail() {
    const res = !this.isClientManagement
      ? await ClientRegistrationController.getDetailV2(Number(this.id))
      : await ClientManagementController.getDetailV2(Number(this.id));
    if (res) {
      if (!this.isClientManagement) {
        this.onSetValueClientRegistration();
      } else {
        this.onSetValueClientManagement();
      }
    }
  }

  onSetValueClientRegistration() {
    this.clientBranchData.companyName = this.detailClient.clientName;
    this.clientBranchData.phone = this.detailClient.clientPhone.replace(
      /^0|^62/g,
      ""
    );
    this.clientBranchData.email = this.detailClient.clientEmail;
    this.clientBranchData.address = this.detailClient.clientAddress;
    this.clientBranchData.district = this.detailClient.clientDistrict;
    this.clientBranchData.taxNumber = this.detailClient.clientTaxNumber;
    this.contactInfoData.name = this.detailClient.clientContact.clientContactName;
    this.contactInfoData.position = this.detailClient.clientContact.clientContactJobTitle;
    this.contactInfoData.phone = this.detailClient.clientContact.clientContactPhone.replace(
      /^0|^62/g,
      ""
    );
    this.contactInfoData.email = this.detailClient.clientContact.clientContactEmail;
    this.returnConfigData.isActive = this.detailClient.isReturnConfig;
    this.returnConfigData.limitConfirm = this.detailClient.returnConfigLimit.toString();
    this.returnConfigData.returnConfigReasonIds = !this.detailClient
      .returnConfigReasonIds
      ? []
      : this.getReasonList(this.detailClient.returnConfigReasonIds);
    this.parentClientDetailModel.parent.parentName = this.detailClient.clientParentName;
    this.parentClientDetailModel.parent.posName = this.modelPartnerPos;
    this.parentClientDetailModel.parent.commision = this.detailClient.clientPartner.clientPartnerCommissionAmount.toString();
    this.parentClientDetailModel.parent.commisionType = this.detailClient.clientPartner.clientPartnerQuantifierCommission;
    this.parentClientDetailModel.parent.numeratorCommission = this.detailClient.clientPartner.clientPartnerNumeratorCommision;
    this.clientBranchDoPaymentType = this.detailClient.clientBranchDoPaymentType;
    this.sttReturnData.codRej.receiverName = this.detailClient.clientCodReject.clientCodName;
    this.sttReturnData.codRej.receiverAddress = this.detailClient.clientCodReject.clientCodAddress;
    this.sttReturnData.codRej.district = this.detailClient.clientCodReject.clientCodDistrict;
    this.sttReturnData.codRej.phone = this.detailClient.clientCodReject.clientCodPhone.replace(
      /^0|^62/g,
      ""
    );
    this.sttReturnData.codRej.codAmountType = this.detailClient.clientCodConfigAmount?.value;
    this.sttReturnData.codRej.commision = this.detailClient.clientCodReject.clientCodCommission;
    this.sttReturnData.codRej.codShipmentDiscount = this.detailClient.clientCodShipmentDiscount;
    this.sttReturnData.isCod = this.detailClient.clientIsCod;
    this.sttReturnData.isDo = this.detailClient.clientIsDo;
    this.sttReturnData.clientIsDfod = this.detailClient.clientIsDfod;
  }

  get modelPartnerPos() {
    if (this.detailClient.clientPartner.clientPartnerId !== 0) {
      return {
        id: this.detailClient.clientPartner.clientPartnerId,
        name: this.detailClient.clientPartner.clientPartnerName,
      };
    }
    return "";
  }

  get cachedEditSTTReturn() {
    return {
      receiverName: this.isClientManagement
        ? this.detailClientManagement.clientCodReject.clientCodName
        : this.detailClient.clientCodReject.clientCodName,
      receiverAddress: this.isClientManagement
        ? this.detailClientManagement.clientCodReject.clientCodAddress
        : this.detailClient.clientCodReject.clientCodAddress,
      district: this.isClientManagement
        ? this.detailClientManagement.clientCodReject.clientCodDistrict
        : this.detailClient.clientCodReject.clientCodDistrict,
      phone: this.isClientManagement
        ? this.detailClientManagement.clientCodReject.clientCodPhone
        : this.detailClient.clientCodReject.clientCodPhone,
    };
  }

  onSetValueClientManagement() {
    this.clientBranchData.companyName = this.detailClientManagement.clientName;
    this.clientBranchData.phone = this.detailClientManagement.clientPhone.replace(
      /^0|^62/g,
      ""
    );
    this.clientBranchData.email = this.detailClientManagement.clientEmail;
    this.clientBranchData.address = this.detailClientManagement.clientAddress;
    this.clientBranchData.district = this.detailClientManagement.clientDistrict;
    this.clientBranchData.taxNumber = this.detailClientManagement.clientTaxNumber;
    this.contactInfoData.name = this.detailClientManagement.clientContactName;
    this.contactInfoData.position = this.detailClientManagement.clientContactJobTitle;
    this.contactInfoData.phone = this.detailClientManagement.clientContactPhone.replace(
      /^0|^62/g,
      ""
    );
    this.contactInfoData.email = this.detailClientManagement.clientContactEmail;
    this.returnConfigData.isActive = this.detailClientManagement.isReturnConfig;
    this.returnConfigData.limitConfirm = this.detailClientManagement.returnConfigLimit.toString();
    this.returnConfigData.returnConfigReasonIds = !this.detailClientManagement
      .returnConfigReasonIds
      ? []
      : this.getReasonList(this.detailClientManagement.returnConfigReasonIds);
    this.parentClientDetailModel.parent.parentName = this.detailClientManagement.clientParentName;
    this.parentClientDetailModel.parent.posName = this.detailClientManagement
      .clientPartnerName
      ? {
          name: this.detailClientManagement.clientPartnerName,
          id: this.detailClientManagement.clientPartnerId,
        }
      : "";
    this.parentClientDetailModel.parent.commision = this.detailClientManagement.clientPartnerCommissionAmountPercentage.toString();
    this.parentClientDetailModel.parent.commisionType = this.detailClientManagement.clientPartnerQuantifierCommission;
    this.parentClientDetailModel.parent.numeratorCommission = this.detailClientManagement.clientPartnerNumeratorCommission;
    this.clientBranchDoPaymentType = this.detailClientManagement.clientBranchDoPaymentType;
    this.sttReturnData.codRej.receiverName = this.detailClientManagement.clientCodReject.clientCodName;
    this.sttReturnData.codRej.receiverAddress = this.detailClientManagement.clientCodReject.clientCodAddress;
    this.sttReturnData.codRej.district = this.detailClientManagement.clientCodReject.clientCodDistrict;
    this.sttReturnData.codRej.phone = this.detailClientManagement.clientCodReject.clientCodPhone.replace(
      /^0|^62/g,
      ""
    );
    this.sttReturnData.codRej.codAmountType = this.detailClientManagement.clientCodConfigAmount?.value;
    this.sttReturnData.codRej.commision = this.detailClientManagement.clientCodReject.clientCodCommission;
    this.sttReturnData.codRej.codShipmentDiscount = this.detailClientManagement.clientCodShipmentDiscountPercentage;
    this.sttReturnData.isCod = this.detailClientManagement.clientIsCod;
    this.sttReturnData.isDo = this.detailClientManagement.clientIsDo;
    this.sttReturnData.clientIsDfod = this.detailClientManagement.clientIsDfod;
  }

  showSnackbarSuccess() {
    MainAppController.setSnackbarMessage(
      `Pembuatan klien cabang “${this.clientBranchData.companyName}” berhasi! Tunggu Admin menyetujui permintaan Anda.`
    );
    MainAppController.setSnackbarTimeout(4000);
    MainAppController.setAutoClose(true);
    MainAppController.setSnackbar(true);
  }

  async createClientBranch() {
    const payload = new EditClientManagementBasicInformationRequest({
      id: +this.id,
      companyName: this.clientBranchData.companyName,
      email: this.clientBranchData.email,
      category: !this.isClientManagement
        ? this.detailClient.clientCategory
        : this.detailClientManagement.clientCategory,
      phone: convertPhoneNumber(this.clientBranchData.phone),
      address: this.clientBranchData.address,
      districtCode: this.clientBranchData.district?.code,
      taxNumber: this.clientBranchData.taxNumber,
      type: "branch",
      contactName: this.contactInfoData.name,
      contactJobTitle: this.contactInfoData.position,
      contactEmail: this.contactInfoData.email,
      contactPhone: convertPhoneNumber(this.contactInfoData.phone),
      partnerPosId: this.parentClientDetailModel.parent.posName?.id || "",
      partnerPosCommission: Number(
        this.replaceComma(`${this.parentClientDetailModel.parent.commision}`)
      ),
      partnerPosQuantifierCommission: this.parentClientDetailModel.parent
        .commisionType,
      partnerPosNumeratorCommission: this.parentClientDetailModel.parent
        .numeratorCommission,
      isDo: this.sttReturnData.isDo,
      isCod: this.sttReturnData.isCod,
      isDfod: this.sttReturnData.isCod && this.sttReturnData.clientIsDfod,
      receiverName: this.sttReturnData.codRej.receiverName,
      receiverPhone: convertPhoneNumber(this.sttReturnData.codRej.phone),
      receiverAddress: this.sttReturnData.codRej.receiverAddress,
      receiverCity: this.sttReturnData.codRej.district.cityCode,
      receiverDistrict: this.sttReturnData.codRej.district.code,
      codFee: Number(
        this.replaceComma(`${this.sttReturnData.codRej.commision}`)
      ),
      clientCodConfigAmount: !this.sttReturnData.isCod
        ? ""
        : this.sttReturnData.codRej.codAmountType,
      clientCodShipmentDiscount:
        +this.sttReturnData.codRej.codShipmentDiscount.replace(/,/g, ".") || "",
      clientBranchDoPaymentType: this.clientBranchDoPaymentType,
      isReturnConfig: this.returnConfigData.isActive,
      returnConfigLimit: this.returnConfigData.isActive
        ? this.returnConfigData.limitConfirm
        : 0,
      returnConfigReasonIds: this.returnConfigData.isActive
        ? this.payloadReturnConfigReasonIds(
            this.returnConfigData.returnConfigReasonIds
          )
        : "",
    });
    await ClientRegistrationController.editClientManagementBasicInformationV2(
      payload
    ).then(() => {
      this.$router
        .push(
          `/client/client-${
            this.isClientManagement ? "management" : "registration"
          }/${this.id}`
        )
        .then(() => {
          MainAppController.setSnackbarMessage(
            `Perubahan data klien cabang "${this.clientBranchData.companyName}" berhasil!`
          );
          MainAppController.setSnackbarTimeout(4000);
          MainAppController.setSnackbarPositivity(true);
          MainAppController.setSnackbar(true);
        });
    })
  }

  isCodMandatory() {
    if (this.sttReturnData.isCod) {
      return this.sttReturnData.codRej.receiverName &&
        this.sttReturnData.codRej.receiverAddress &&
        this.sttReturnData.codRej.district &&
        this.sttReturnData.codRej.phone &&
        this.sttReturnData.codRej.codAmountType
        ? true
        : false;
    } else {
      return true;
    }
  }

  get validationBranch() {
    return (
      this.clientBranchData.companyName &&
      this.clientBranchData.district &&
      this.clientBranchData.address &&
      this.clientBranchData.email &&
      this.clientBranchData.phone &&
      this.parentClientDetailModel.parent.parentName &&
      this.contactInfoData.name &&
      this.contactInfoData.email &&
      this.contactInfoData.phone &&
      this.contactInfoData.position
    );
  }

  get isFormValidBranch() {
    let result = false;
    if (this.validationBranch && this.isCodMandatory()) {
      result = true;
      if (this.parentClientDetailModel.parent.posName) {
        result = !!(
          this.parentClientDetailModel.parent.posName &&
          this.parentClientDetailModel.parent.commision &&
          this.parentClientDetailModel.parent.commisionType &&
          this.parentClientDetailModel.parent.numeratorCommission
        );
      }
    }
    const isDO = this.sttReturnData.isDo
      ? !!this.clientBranchDoPaymentType
      : true;
    return result && !this.anyFormError && isDO;
  }

  get anyFormError() {
    let result = false;
    Object.entries(this.error).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }

  error = {
    companyName: false,
    email: false,
    phone: false,
    address: false,
    district: false,
    versionBasic: false,
    discount: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    versionName: false,
    archiveFile: false,
    contactEmail: false,
    contactPhone: false,
    contactWhatsApp: false,
    commision: false,
    codRejdistrict: false,
    codRejCommision: false,
    codRejPhone: false,
    padCommision: false,
    emailBilling: false,
    NIKNumber: false,
    taxNumber: false,
    invalidDate: false,
    codRejShipmentDiscount: false,
  };
  // Convert Value
  convertToString(data: Array<any>, key: string) {
    return convertArrayToString(data, key);
  }

  payloadReturnConfigReasonIds(val: Array<any>) {
    let formattedVal = "";
    if (val.includes("all")) formattedVal = "all";
    else
      formattedVal = this.convertToString(val, "value")
        .split(",")
        .toString();
    return formattedVal;
  }

  get isClientManagement() {
    return this.$route.params?.params === "management";
  }
  // route navigation
  goBack() {
    this.$router.back();
  }
  getTitle() {
    return this.$route.meta.name;
  }

  get id() {
    return this.$route.params.id;
  }

  get isLoading() {
    return (
      ClientRegistrationController.isLoading ||
      ClientManagementController.isLoading
    );
  }

  get isError() {
    return (
      ClientRegistrationController.isError || ClientManagementController.isError
    );
  }

  get errorCause() {
    return (
      ClientRegistrationController.isErrorCause ||
      ClientManagementController.isErrorCause
    );
  }

  get detailClient() {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  get detailClientManagement() {
    return ClientManagementController.detailClientManagement;
  }
}
