import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PriceTypeForm = _resolveComponent("PriceTypeForm")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_ModalV2 = _resolveComponent("ModalV2")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_component_ModalV2, {
        key: 0,
        width: "w-10/12",
        showFooter: "",
        title: "Tambah harga baru klien induk",
        onClose: () => _ctx.onShowHidePopup()
      }, {
        content: _withCtx(() => [
          _createVNode(_component_PriceTypeForm, {
            modelValue: _ctx.form,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form = $event)),
            error: _ctx.error,
            "onUpdate:error": _cache[2] || (_cache[2] = ($event: any) => (_ctx.error = $event)),
            customPriceOption: _ctx.customPriceOption
          }, null, 8, ["modelValue", "error", "customPriceOption"])
        ]),
        footer: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createVNode(_component_LpButton, {
              title: "Simpan",
              textColor: "white",
              disabled: !_ctx.isValidRenewRate,
              onClick: _ctx.onConfirmRenewal,
              customClass: "px-4 py-2"
            }, null, 8, ["disabled", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}