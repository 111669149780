
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { ClientRegistrationDetail } from "@/domain/entities/ClientRegistration";

class Props {
  detailData = prop<ClientRegistrationDetail>({
    required: true,
    default: new ClientRegistrationDetail()
  });
  isForm = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailDeliveryOrder extends Vue.with(Props) {
  get deliveryOrderPaymentType() {
    return this.detailData.clientBranchDoPaymentType?.match(/free/g)
      ? "Gratis"
      : "Berbayar";
  }

  dOOptions = [
    {
      name: "Gratis",
      value: "free"
    },
    {
      name: "Berbayar",
      value: "paid"
    }
  ];
  onSelectDO(option: any) {
    this.detailData.clientBranchDoPaymentType = option.value;
  }
}
