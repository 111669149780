
import { Options, prop, Vue } from "vue-class-component";

class Props {
  modelValue = prop<any>({
    required: true
  });
  options = prop<Array<any>>({
    type: Array,
    default: []
  });
  disabledKey = prop<string>({
    type: String,
    default: ""
  });
  disabledValue = prop<string>({
    type: String,
    default: ""
  });
}
@Options({
  emits: ["update:modelValue", "input"]
})
export default class CardSelector extends Vue.with(Props) {
  isSelected(option: any) {
    return option.value === this.modelValue;
  }
  selectOption(option: any) {
    if (!this.isDisabled(option)) {
      this.$emit("input", option.value);
      this.$emit("update:modelValue", option.value);
    }
  }
  isDisabled(item: any) {
    if (this.disabledKey && this.disabledValue) {
      return item[this.disabledKey] !== this.disabledValue;
    }
    return false;
  }
}
