
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement(),
  });
}
@Options({
  components: {
    Title,
  },
})
export default class DetailCodrejSection extends Vue.with(Props) {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }
  get isEnableCodOngkir() {
    return FlagsMedusa.config_cod_ongkir_enable.isEnabled();
  }
}
