import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DataWrapper, {
      class: "w-10/12",
      label: "Tipe Client",
      value: _ctx.detailData.clientType
    }, null, 8, ["value"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "ID Client",
        value: _ctx.idClient
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Kode Client",
        value: _ctx.detailData.clientCode
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Nama Cabang Client",
        value: _ctx.detailData.clientName
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      class: "w-10/12",
      label: "Alamat",
      value: _ctx.detailData.clientAddress
    }, null, 8, ["value"]),
    _createVNode(_component_DataWrapper, {
      class: "w-7/12",
      label: "Kecamatan, Kota",
      value: _ctx.detailData.clientDistrict?.name
    }, null, 8, ["value"]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Email",
        value: _ctx.detailData.clientEmail
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "No. Telepon",
        value: _ctx.formatPhoneNumber(_ctx.detailData.clientPhone)
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_DataWrapper, {
      label: "No. Registrasi Pajak (NPWP)",
      value: _ctx.detailData.clientTaxNumber
    }, null, 8, ["value"]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "COD",
        value: _ctx.detailData.clientIsCod ? 'Ya' : 'Tidak'
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Delivery Order",
        value: _ctx.detailData.clientIsDo ? 'Ya' : 'Tidak'
      }, null, 8, ["value"])
    ])
  ], 64))
}