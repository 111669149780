
import { Options, prop, Vue } from "vue-class-component";
import DetailBranchClient from "./detail-client-info/detail-branch.client.vue";
import DetailTaxInfo from "./detail-client-info/detail-tax-information.vue";
import DetailContactInfo from "./detail-client-info/detail-contact-information.vue";
import DetailBillingInfo from "./detail-client-info/detail-billing-info.vue";
import DetailCoopAgreement from "./detail-client-info/detail-coop-agreement.vue";
import DetailBankInfo from "./detail-client-info/detail-bank-info.vue";
import DetailBankSettlement from "./detail-client-info/detail-bank-settlement.vue";
import CardTabDetail from "../../card-tab-detail.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";

class Props {
  title = prop<string>({
    default: "Detail",
    required: true,
    type: String
  });
  isManagement = prop<boolean>({
    default: false,
    required: false,
    type: Boolean
  });
  detailData = prop<any>({
    default: "",
    type: Object
  })
}

@Options({
  emits: ["action"],
  components: {
    DetailBranchClient,
    DetailTaxInfo,
    DetailContactInfo,
    DetailBillingInfo,
    DetailCoopAgreement,
    DetailBankInfo,
    DetailBankSettlement,
    CardTabDetail
  }
})
export default class ClientDetailCard extends Vue.with(Props) {
  tabModels = [
    { slotName: "klien", name: "Klien" },
    { slotName: "pajak", name: "Pajak" },
    { slotName: "kontak", name: "Kontak" },
    { slotName: "pembayaran", name: "Pembayaran" },
    { slotName: "billing", name: "Billing" },
    {
      slotName: "perjanjian-kerjasama",
      name: "Perjanjian kerjasama"
    },
    { slotName: "bank", name: "Bank" },
    { slotName: "bank-settlement", name: "Bank settlement" }
  ];

  get clientPaymentMethod() {
    if (this.isManagement) {
      return ClientManagementController.detailClientManagement
        .clientPaymentMethod.name;
    } else {
      return ClientRegistrationController.clientRegistrationDetail
        .clientPaymentMethod.name;
    }
  }
}
