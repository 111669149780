
import { prop, Vue } from "vue-class-component";

export type ContactInfoSectionProp = {
  name: string;
  position: string;
  phone: string;
  email: string;
};

class Props {
  modelValue = prop<ContactInfoSectionProp>({
    default: {
      name: "",
      position: "",
      phone: "",
      email: "",
    },
    required: true,
    type: Object,
  });
  error = prop<any>({
    required: true,
  });
}

export default class ClientBranchSection extends Vue.with(Props) {

  validateEmail(value: string) {
    this.error.contactEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validatePhone(value: string) {
    this.error.contactPhone = !/^\d+$/.test(value) || value.length < 8;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }
}
