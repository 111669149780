import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_Title, {
      class: "mb-4",
      label: "Informasi kontak",
      fontSize: 20
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        label: "Nama",
        asterisk: "",
        fontSize: 14,
        style: {"flex":"1"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LpInput, {
            placeholder: "Masukkan nama",
            modelValue: _ctx.formModel.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formModel.name = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Jabatan",
        asterisk: "",
        fontSize: 14,
        style: {"flex":"1"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LpInput, {
            placeholder: "Masukkan jabatan",
            modelValue: _ctx.formModel.level,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formModel.level = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "No. telepon",
        asterisk: "",
        fontSize: 14,
        style: {"flex":"1"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LpInput, {
            placeholder: "Contoh: 8123456789012",
            errorCaption: "Masukan No. telepon dengan benar",
            prefix: "+62",
            format: _ctx.formatNumber,
            error: _ctx.formErrorModel.contactPhone,
            modelValue: _ctx.formModel.phone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formModel.phone = $event)),
            onChange: _ctx.validatePhone
          }, null, 8, ["format", "error", "modelValue", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Email",
        asterisk: "",
        fontSize: 14,
        style: {"flex":"1"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_LpInput, {
            placeholder: "Contoh: nama@email.com",
            errorCaption: "Masukan Email dengan benar",
            error: _ctx.formErrorModel.contactEmail,
            modelValue: _ctx.formModel.email,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formModel.email = $event)),
            onChange: _ctx.validateEmail
          }, null, 8, ["error", "modelValue", "onChange"])
        ]),
        _: 1
      })
    ])
  ]))
}