import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, { label: "Detail Induk Client" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Nama Induk Client",
        value: _ctx.detailData.clientParentName
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Nama Partner POS",
        value: _ctx.detailData.clientPartnerName
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Komisi POS",
        value: `${_ctx.detailData.clientPartnerCommissionAmount} %`
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12 capitalize",
        label: "Tipe Komisi",
        value: 
        _ctx.detailData.clientPartnerQuantifierCommission ===
        'publish_rate_shipping_surcharge'
          ? 'Publish Rate + Shipping Surcharge'
          : _ctx.startCase(
              _ctx.detailData.clientPartnerQuantifierCommission.replace(/_/g, ' ')
            )
      
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Pembilang Komisi",
        value: 
        _ctx.detailData.clientPartnerNumeratorCommission === 'client-rate'
          ? 'Harga Client'
          : 'Harga Retail'
      
      }, null, 8, ["value"])
    ])
  ], 64))
}