import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row gap-4" }
const _hoisted_2 = {
  class: "flex flex-col",
  style: {"flex":"1","gap":"20px"}
}
const _hoisted_3 = {
  key: 0,
  class: "text-red-lp-100 text-12px xxl:text-14px"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_UploadFile = _resolveComponent("UploadFile")!

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_Title, {
      class: "mb-4",
      label: "Perjanjian kerjasama",
      fontSize: 20
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          label: "Dimulai dari",
          asterisk: "",
          fontSize: 16
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DatePicker, {
              placeholder: "Pilih tanggal dimulai",
              class: "mt-2",
              modelValue: _ctx.formModel.startDate,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formModel.startDate = $event))
            }, null, 8, ["modelValue"]),
            (_ctx.invalidDate)
              ? (_openBlock(), _createBlock("div", _hoisted_3, " Tanggal mulai tidak boleh lebih dari tanggal akhir "))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Sampai",
          asterisk: "",
          fontSize: 16
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DatePicker, {
              placeholder: "Pilih tanggal sampai",
              class: "mt-2",
              modelValue: _ctx.formModel.endDate,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formModel.endDate = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_DataWrapper, {
        label: "File lampiran",
        asterisk: "",
        fontSize: 16,
        style: {"flex":"2"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UploadFile, {
            titleChange: "Ubah Berkas",
            type: "",
            modelValue: _ctx.formModel.attachment,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formModel.attachment = $event)),
            caption: ".pdf maksimum file 16 MB",
            allowed: ".pdf",
            customClass: "py-6",
            maxSize: 16,
            isDraggable: false,
            url: _ctx.formModel.attachmentUrl,
            name: _ctx.formModel.fileNameAttachment,
            onChange: _cache[4] || (_cache[4] = 
            () => {
              _ctx.formModel.attachmentUrl = '';
            }
          )
          }, null, 8, ["modelValue", "url", "name"])
        ]),
        _: 1
      })
    ])
  ]))
}