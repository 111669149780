
import { Options, Vue, prop } from "vue-class-component";
import RadioButton from "primevue/radiobutton";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";

class Props {
  modelValue = prop<boolean>({
    default: false
  });
  name = prop<string>({
    default: ""
  });
  id = prop<number>({
    default: 0
  });
  type = prop<string>({
    default: "Client Induk"
  });
  status = prop<boolean>({
    default: false
  });
}

@Options({
  emits: ["update:modelValue", "success"],
  components: {
    RadioButton
  }
})
export default class FreezeUnfreezePopup extends Vue.with(Props) {
  mounted() {
    this.statusClient = this.typeModal;
  }

  get isFormValid() {
    if (this.statusClient === "freeze") {
      return !!this.freezeReason;
    }
    return true;
  }

  // client data
  get clientName() {
    return this.name;
  }
  get clientId() {
    return this.id;
  }
  get clientType() {
    return this.type === "Client Induk" ? "induk" : "cabang";
  }
  get typeModal() {
    return this.status ? "active" : "freeze";
  }

  freezeReason = "";

  showingDialog = true;
  toggleSoftShow(bool: boolean) {
    this.showingDialog = bool;
  }
  togglePopup(bool: boolean) {
    this.$emit("update:modelValue", bool);
  }

  statusClient = "";
  onChangeStatus(val: string) {
    if (val === "active") {
      this.freezeReason = "";
    }
  }

  confirmationChangeStatus() {
    this.toggleSoftShow(false);
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: `Konfirmasi ${
          this.statusClient === "active" ? "Pengaktifan" : "Pembekuan"
        }`,
        message: `Anda yakin ingin melakukan ${
          this.statusClient === "active" ? "pengaktifan" : "pembekuan"
        } Client “${this.clientName}” ?`,
        textCancel: "Kembali",
        textSuccess: `${
          this.statusClient === "active" ? "Unfreeze" : "Freeze"
        }`,
        onSubmit: () => this.submitChangeStatus(),
        onClose: () => {
          this.toggleSoftShow(true);
          MainAppController.closeMessageModal();
        },
        image: "are-you-sure"
      })
    );
  }

  async submitChangeStatus() {
    MainAppController.closeMessageModal();
    MainAppController.showLoading();
    this.toggleSoftShow(false);
    const resp = await ClientManagementController.freezeUnfreezeClient({
      id: this.clientId,
      reason: this.freezeReason,
      type: this.statusClient === "freeze" ? "freeze" : "unfreeze"
    });
    MainAppController.closeLoading();
    if (resp) {
      MainAppController.setSnackbarMessage(
        `${
          this.statusClient === "active" ? "Pengaktifan" : "Pembekuan"
        } Client “${this.clientName}” berhasil !`
      );
      MainAppController.setSnackbar(true);
      this.togglePopup(false);
      this.$emit("success");
    } else {
      this.toggleSoftShow(true);
    }
  }
}
