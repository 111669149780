
import { Options, prop, Vue } from "vue-class-component";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import debounce from "lodash/debounce";
import ToggleV2 from "@/app/ui/components/toggle-v2/index.vue";

export type SttReturnErrorSectionProp = {
  phone: boolean;
};

// codRej: {
//       receiverName: "",
//       receiverAddress: "",
//       district: "",
//       commision: "",
//       padCommision: "",
//       phone: "",
//       codAmountType: "",
//       codShipmentDiscount: ""
//     }

class Props {
  modelValue = prop<any>({
    required: true,
    type: Object,
  });
  form = prop<any>({
    required: true,
    type: Object,
  });
  error = prop<any>({
    default: {
      phone: false,
    },
  });
  isEdit = prop<boolean>({
    default: false,
  });
  cacheEdit = prop<any>({
    type: Object,
    default: {
      receiverName: "",
      receiverAddress: "",
      district: "",
      phone: "",
    },
  });
}

@Options({
  components: {
    ToggleV2,
  },
})
export default class ClientBranchSection extends Vue.with(Props) {
  autoFillColumnDisabled = false;
  isFill = false;
  openFill = false;

  mounted(): void {
    this.fetchDistrictList("");
  }

  // district list
  fetchDistrictList = debounce((search: string) => {
    ClientRegistrationController.getDistrictList({
      search: search,
    });
  }, 250);

  get districtList() {
    return ClientRegistrationController.districtList;
  }

  get loadingDistrictList() {
    return ClientRegistrationController.loadingDistrictList;
  }

  validatePhone(value: string) {
    this.error.codRejPhone = !/^\d+$/.test(value) || value.length < 8;
  }

  formatNumber(value: string) {
    const newValue = value.replace(/^62|^0|[\D]+/g, "");
    return newValue.startsWith("0")
      ? newValue.slice(1, newValue.length)
      : newValue;
  }

  autoFill() {
    this.error.codRejPhone = false;
    this.openFill = false;
    this.isFill = !this.isFill;
    if (this.isFill) {
      this.autoFillColumnDisabled = true;
      this.modelValue.receiverName = this.form.companyName;
      this.modelValue.receiverAddress = this.form.address;
      this.modelValue.district = this.form.district;
      this.modelValue.phone = this.form.phone;
      this.$emit("update:modelValue", { ...this.modelValue });
    } else {
      if (this.isEdit) {
        this.modelValue.receiverName = this.cacheEdit.receiverName;
        this.modelValue.receiverAddress = this.cacheEdit.receiverAddress;
        this.modelValue.district = this.cacheEdit.district;
        this.modelValue.phone = this.cacheEdit.phone;
      } else {
        this.modelValue.receiverName = "";
        this.modelValue.receiverAddress = "";
        this.modelValue.district = "";
        this.modelValue.phone = "";
      }
      this.autoFillColumnDisabled = false;
      this.$emit("update:modelValue", { ...this.modelValue });
    }
  }
}
