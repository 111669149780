
import { Options, prop, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import BankModal from "./bank-modal.vue";
import { BankDetail } from "@/domain/entities/ClientRegistration";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";

class Props {
  isClearDefault = prop<boolean>({
    type: Boolean,
    default: true
  });
  type = prop<string>({
    type: String,
    default: "registration"
  });
  formType = prop<string>({
    type: String,
    default: ""
  });
}

@Options({
  components: {
    BankModal,
    Title
  }
})
export default class BankSection extends Vue.with(Props) {
  created() {
    this.setBankDetail();
    if (this.isClearDefault) {
      this.onDeleteBank();
    }
  }
  get isAddBank() {
    return ClientRegistrationController.isAddBank;
  }
  onAddBank() {
    ClientRegistrationController.setAddBank(true);
  }
  get bankDetail() {
    return ClientRegistrationController.bankDetail;
  }

  // delete
  isDelete = false;
  onConfirmDelete(value: boolean) {
    this.isDelete = value;
  }
  onDeleteBank() {
    this.onConfirmDelete(false);
    ClientRegistrationController.setBankDetail(new BankDetail());
  }

  setBankDetail() {
    ClientRegistrationController.setBankDetail(
      this.type === "registration"
        ? ClientRegistrationController.bankDetail
        : ClientManagementController.detailClientManagement.bankDetail
    );
  }
}
