
import { Options, Vue } from "vue-class-component";
import Parent from "./create-parent.vue";
import Branch from "./create-branch.vue";

@Options({
  components: {
    Parent, 
    Branch
  },
})
export default class AddClientRegistration extends Vue {

  get getFormType() {
    return this.$route.meta.form;
  }
}
