
import { Options, Vue } from "vue-class-component";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

@Options({
  emits: ["update:isShow", "success"],
  components: {},
  props: {
    isShow: {
      default: false,
      type: Boolean
    },
    typeConfirm: {
      default: 0,
      type: Number
    },
    clientName: {
      default: "",
      type: String
    },
    renewRatesId: {
      default: 0,
      type: Number,
      required: true
    }
  },

  computed: {
    isDisabled() {
      return this.props.typeConfirm === 1 && this.selectedReason === "";
    },
    headerSection() {
      return {
        title: this.confirmValue[this.typeConfirm].title,
        message: this.confirmValue[this.props.typeConfirm].message,
        textSuccess: this.confirmValue[this.props.typeConfirm].textSuccess,
        textCancel: this.confirmValue[this.props.typeConfirm].textCancel
      };
    }
  }
})
export default class Confirmation extends Vue {
  props: any = this.$props;
  confirmValue = [
    {
      title: "Konfirmasi Persetujuan",
      message:
        "Sebelum menyetujui permintaan ini, pastikan seluruh data Client sudah benar dan sesuai.",
      textSuccess: "Setujui",
      textCancel: "Kembali",
      custom: false
    },
    {
      title: "Konfirmasi Penolakan",
      message:
        "Masukan alasan penolakan agar mempermudah dalam perbaikan aktivitas.",
      textSuccess: "Tolak",
      textCancel: "Kembali",
      custom: true
    }
  ];

  // handling
  confirmationCancel() {
    this.$emit("update:isShow", false);
  }

  messageFinish = "";
  async confirmationSubmit() {
    this.confirmationCancel();

    if (this.props.typeConfirm === 0) {
      this.messageFinish = `Persetujuan pembaruan harga “${this.props.clientName}” berhasil !`;
    } else {
      this.messageFinish = `Penolakan pembaruan harga Client “${this.props.clientName}” berhasil !`;
    }

    let resp: boolean;
    if (this.props.typeConfirm === 0) {
      resp = await ClientManagementController.approveRenewRates({
        id: this.props.renewRatesId
      });
    } else {
      resp = await ClientManagementController.rejectRenewRates({
        id: this.props.renewRatesId,
        rejectReason: this.selectedReason
      });
    }
    if (resp) {
      MainAppController.setSnackbarMessage(this.messageFinish);
      MainAppController.setSnackbarPositivity(this.props.typeConfirm === 0);
      MainAppController.setSnackbar(true);
      this.$emit("success");
    }
  }

  // select
  openSelect = false;
  rejectedReasons = [
    {
      name: "Pilih alasan penolakan",
      value: ""
    },
    {
      name: "Diskon Tidak Disetujui",
      value: "Diskon Tidak Disetujui"
    },
    {
      name: "Custom Rates Tidak Disetujui",
      value: "Custom Rates Tidak Disetujui"
    },
    {
      name: "Diskon & Custom Rate Tidak Disetujui",
      value: "Diskon & Custom Rate Tidak Disetujui"
    }
  ];
  selectedReason = "";
}
