
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import FormatPhoneNumber from "@/app/infrastructures/misc/common-library/FormatPhoneNumber";

class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement()
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailContactInformation extends Vue.with(Props) {
  formatPhoneNumber(val: any) {
    return FormatPhoneNumber(val);
  }
}
