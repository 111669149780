
  import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";
  import AddClientRegistrationV1 from "../clients/client-registration/component/add.vue";
  import AddClientRegistrationV2 from "../clients-v2/registration/create/index.vue";
  import { Options, Vue } from "vue-class-component";
  @Options({
    components: {
      AddClientRegistrationV1,
      AddClientRegistrationV2,
    },
  })
  export default class AddClientRegistration extends Vue {
    get isClientVersionV2() {
      return FlagsPermissionClient.client_v2_enable.isEnabled();
    }
  
    get renderedPage(): string {
      return this.isClientVersionV2
        ? "AddClientRegistrationV2"
        : "AddClientRegistrationV1";
    }
  }
  