
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { ClientRegistrationDetail } from "@/domain/entities/ClientRegistration";
class Props {
  detailData = prop<ClientRegistrationDetail>({
    required: true,
    default: new ClientRegistrationDetail()
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailTaxInformation extends Vue.with(Props) {}
