
import { Options, prop, Vue } from "vue-class-component";
import CodType from "./cod-type.vue";

class Props {
  modelValue = prop<any>({
    required: true
  });
  error = prop<any>({
    required: true
  });
}

@Options({
  emits:["onCheckDO", "onCheckCOD"],
  components: {
    CodType: CodType,
  },
})
export default class DeliveryService extends Vue.with(Props) {
  onCodCheckChange(value: any) {
    this.modelValue.isCod = value;
    this.$emit("onCheckCOD");
  }

  onClickDO() {
    this.$emit("onCheckDO");
  }

  onDoCheckChange(value: any) {
    this.modelValue.isDo = value;
  }
}
