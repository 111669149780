
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import FormatPhoneNumber from "@/app/infrastructures/misc/common-library/FormatPhoneNumber";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import { convertPhoneNumber } from "@/app/infrastructures/misc/Utils";

class Props {
  isManagement = prop<boolean>({
    required: false,
    default: false,
    type: Boolean
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailContactInformation extends Vue.with(Props) {
  get detailDataManagement() {
    return ClientManagementController.detailClientManagement;
  }

  get detailDataRegistration() {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  formatPhoneNumber(val: any) {
    return `+${convertPhoneNumber(val)}`;
  }

  get clientContact() {
    if (this.isManagement) {
      return {
        name: this.detailDataManagement.clientContactName,
        jobTitle: this.detailDataManagement.clientContactJobTitle,
        email: this.detailDataManagement.clientContactEmail,
        phone: this.detailDataManagement.clientContactPhone
      }
    }

    return {
      name: this.detailDataRegistration.clientContact.clientContactName,
      jobTitle: this.detailDataRegistration.clientContact.clientContactJobTitle,
      email: this.detailDataRegistration.clientContact.clientContactEmail,
      phone: this.detailDataRegistration.clientContact.clientContactPhone,
    }

  }
}
