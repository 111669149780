import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailBranchClient = _resolveComponent("DetailBranchClient")!
  const _component_DetailTaxInfo = _resolveComponent("DetailTaxInfo")!
  const _component_DetailContactInfo = _resolveComponent("DetailContactInfo")!
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_DetailBillingInfo = _resolveComponent("DetailBillingInfo")!
  const _component_DetailCoopAgreement = _resolveComponent("DetailCoopAgreement")!
  const _component_DetailBankInfo = _resolveComponent("DetailBankInfo")!
  const _component_DetailBankSettlement = _resolveComponent("DetailBankSettlement")!
  const _component_CardTabDetail = _resolveComponent("CardTabDetail")!

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_CardTabDetail, {
      tabs: _ctx.tabModels,
      title: _ctx.title,
      onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('action'))),
      detailClient: _ctx.detailData
    }, {
      klien: _withCtx(() => [
        _createVNode(_component_DetailBranchClient, { isManagement: _ctx.isManagement }, null, 8, ["isManagement"])
      ]),
      pajak: _withCtx(() => [
        _createVNode(_component_DetailTaxInfo, { isManagement: _ctx.isManagement }, null, 8, ["isManagement"])
      ]),
      kontak: _withCtx(() => [
        _createVNode(_component_DetailContactInfo, { isManagement: _ctx.isManagement }, null, 8, ["isManagement"])
      ]),
      pembayaran: _withCtx(() => [
        _createVNode(_component_Title, {
          class: "mb-4",
          label: "Informasi Pembayaran"
        }),
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_DataWrapper, {
            class: "w-1/2",
            label: "Metode Pembayaran",
            value: _ctx.clientPaymentMethod
          }, null, 8, ["value"])
        ])
      ]),
      billing: _withCtx(() => [
        _createVNode(_component_DetailBillingInfo, { isManagement: _ctx.isManagement }, null, 8, ["isManagement"])
      ]),
      "perjanjian-kerjasama": _withCtx(() => [
        _createVNode(_component_DetailCoopAgreement, { isManagement: _ctx.isManagement }, null, 8, ["isManagement"])
      ]),
      bank: _withCtx(() => [
        _createVNode(_component_DetailBankInfo, { isManagement: _ctx.isManagement }, null, 8, ["isManagement"])
      ]),
      "bank-settlement": _withCtx(() => [
        _createVNode(_component_DetailBankSettlement, { isManagement: _ctx.isManagement }, null, 8, ["isManagement"])
      ]),
      _: 1
    }, 8, ["tabs", "title", "detailClient"])
  ]))
}