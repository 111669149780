
import { Vue, prop } from "vue-class-component";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
class Props {
  isManagement = prop<boolean>({
    required: false,
    default: false,
    type: Boolean
  });
}

export default class DetailBranchClient extends Vue.with(Props) {
  get detailData() {
    if (this.isManagement) {
      return ClientManagementController.detailClientManagement;
    }

    return ClientRegistrationController.clientRegistrationDetail;
  }

  get idClient() {
    return String(this.detailData.id);
  }

  formatPhoneNumber(val: any) {
    return `+${convertPhoneNumber(val)}`;
  }
}
