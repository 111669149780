
import { formatDate } from "@/app/infrastructures/misc/Utils";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { BankDetail } from "@/domain/entities/ClientRegistration";
import { Options, Vue } from "vue-class-component";
import ConfirmationApprovalRejectClient from "../../global-components/confirmation-approval-reject-client.vue";
import ClientDetailCardBranch from "../../global-components/detail/branch/card-detail-client.vue";
import ClientDetailCardParent from "../../global-components/detail/parent/client-detail.card.vue";
import InfoDetailClient from "../../registration/component/info-detail-client/index.vue";
import InfoPriceClient from "../../registration/component/info-price-client/index.vue";

@Options({
  components: {
    InfoDetailClient,
    InfoPriceClient,
    ConfirmationApprovalRejectClient,
    ClientDetailCardParent,
    ClientDetailCardBranch
  }
})
export default class ClientRegistrationDetail extends Vue {
  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  get isParent() {
    return this.detailData.clientParentId === 0;
  }

  get title() {
    return this.detailData.clientName;
  }

  get detailData() {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  get createdAt() {
    return formatDate(this.detailData.clientCreatedAt);
  }

  get updatedAt() {
    return formatDate(this.detailData.clientUpdatedAt);
  }

  get isRequested() {
    if (this.isParent) {
      return (
        this.detailData.clientCurrentRate.clientStatusCustomRate.toLowerCase() ===
        "requested"
      );
    } else {
      return this.detailData.clientStatus.toLowerCase() === "waiting";
    }
  }

  async fetchDetail() {
    await ClientRegistrationController.getDetailV2(Number(this.id));
  }

  goBack() {
    this.$router.push("/client/client-registration");
  }

  get isLoading() {
    return ClientRegistrationController.isLoading;
  }

  get isError() {
    return ClientRegistrationController.isError;
  }

  get errorCause() {
    return ClientRegistrationController.isErrorCause;
  }
}
