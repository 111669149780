
  import { Options, Vue } from "vue-class-component";
  
  @Options({
    components: {}
  })
  export default class ClientsV2 extends Vue {
    // Tabs
    tabs = [
      { name: "client-registration", title: "Pendaftaran klien" },
      { name: "client-management", title: "Manajemen klien" }
    ];
  
    changeTabs(value: string) {
      this.$router.push({
        name: value
      });
    }
  
    isTabActive(name: string) {
      return name === this.$route.name;
    }
  }
  