
import { Options, prop, Vue } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import debounce from "lodash/debounce";
import CardSelector from "@/app/ui/components/card-selector/index.vue";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { DetailClientForm, File } from "@/domain/entities/ClientManagement";
import { RequestUpdateCurrentRates } from "@/data/payload/api/ClientManagementApiRequest";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";

class Props {
  isAdd = prop<boolean>({
    default: false,
    type: Boolean
  });
  isManagement = prop<boolean>({
    default: true,
    type: Boolean
  });
  isUpdateRates = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  emits: ["close", "success"],
  components: {
    ModalForm,
    Title,
    InputAdornment,
    CardSelector,
    UploadFile,
    Tooltip
  }
})
export default class extends Vue.with(Props) {
  mounted() {
    this.modalRenewRates = true;
    this.onDefaultValue();
  }

  get title() {
    if (this.isAdd) {
      return "Harga Baru";
    }
    if (this.isUpdateRates) {
      return "Perbarui Harga Saat Ini";
    }
    if (!this.isAdd) {
      return "Perbarui Harga";
    }
    return "";
  }

  onDefaultValue() {
    if (!this.isAdd) {
      this.updateBasicInfo();
      this.updateRatesAndFile();
    }
    this.updateRatesToApi();
  }

  updateBasicInfo() {
    this.modelValue.id = this.detailData.id;
    this.modelValue.companyName = this.detailData.clientName;
    this.modelValue.email = this.detailData.clientEmail;
    this.modelValue.phone = this.detailData.clientPhone;
    this.modelValue.address = this.detailData.clientAddress;
    this.modelValue.taxNumber = this.detailData.clientTaxNumber;
  }

  updateRatesAndFile() {
    this.modelValue.versionBasic = !this.isManagement
      ? this.detailDataClientRegistration.clientCurrentRate
          .clientVersionBaseRate
      : this.detailData.clientRenewalRate?.clientVersionBaseRate;
    this.modelValue.isCustomRate = this.priceTypeRenewRates;
    this.modelValue.discount = !this.isManagement
      ? this.detailDataClientRegistration.clientCurrentRate
          .clientDiscountBaseRate
      : this.detailData.clientRenewalRate?.clientDiscountBaseRate;
    this.modelValue.versionName = !this.isManagement
      ? this.detailDataClientRegistration.clientCurrentRate
          .clientVersionCustomRate
      : this.detailData.clientRenewalRate?.clientVersionCustomRate || "";
    this.modelValue.archiveFile = "";
    this.modelValue.rateVersionId = !this.isManagement
      ? this.detailDataClientRegistration.clientCurrentRate.clientCustomRateId
      : this.detailData.clientRenewalRate?.clientCustomRateId || 0;
    this.modelValue.fileCustomRate = !this.isManagement
      ? {
          file: this.detailDataClientRegistration.clientCurrentRate
            .clientFileCustomRate,
          name: this.detailDataClientRegistration.clientCurrentRate
            .clientFileNameCustomRate
        }
      : {
          file: this.detailClient.clientRenewalRate?.clientFileCustomRate || "",
          name:
            this.detailClient.clientRenewalRate?.clientFileNameCustomRate || ""
        };
  }

  updateRatesToApi() {
    if (this.isUpdateRates && !this.isAdd) {
      const currentRates = this.detailData.clientCurrentRate;

      this.modelValue = new DetailClientForm({
        versionBasic: currentRates.clientVersionBaseRate,
        versionName: currentRates.clientVersionCustomRate,
        currentDiscount: currentRates.clientDiscountBaseRate,
        isCustomRate: this.ratesType
      });
      ClientManagementController.setRenewalRateStart(
        currentRates.clientRateversionStartDate
      );
      ClientManagementController.setRenewalRateEnd(
        currentRates.clientRateVersionEndDate
      );
    }
  }

  get ratesType() {
    const currentRates = this.detailData.clientCurrentRate;
    if (
      currentRates?.clientVersionBaseRate &&
      currentRates?.clientVersionCustomRate
    ) {
      return "mix";
    } else if (currentRates.isBaseRate) {
      return "basic";
    } else if (currentRates.isCustomRate) {
      return "custom";
    }
    return "";
  }

  get isAbleUpload() {
    return this.isMixRateBool || this.isCustomRateBool;
  }

  get priceTypeRenewRates() {
    if (!this.isAdd) {
      if (this.isManagement) {
        this.priceTypeRenewRatesManagement();
      } else {
        this.priceTypeRenewRatesNonManagement();
      }
    }
    return "basic";
  }

  priceTypeRenewRatesManagement() {
    if (
      this.detailData.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "mix";
    }
    if (
      this.detailData.clientRenewalRate?.clientVersionBaseRate &&
      !this.detailData.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "basic";
    }

    if (
      !this.detailData.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "custom";
    }
  }

  priceTypeRenewRatesNonManagement() {
    if (
      this.detailDataClientRegistration.clientCurrentRate
        .clientVersionBaseRate &&
      this.detailDataClientRegistration.clientCurrentRate
        .clientVersionCustomRate
    ) {
      return "mix";
    }
    if (
      this.detailDataClientRegistration.clientCurrentRate
        .clientVersionBaseRate &&
      !this.detailDataClientRegistration.clientCurrentRate
        .clientVersionCustomRate
    ) {
      return "basic";
    }

    if (
      !this.detailDataClientRegistration.clientCurrentRate
        .clientVersionBaseRate &&
      this.detailDataClientRegistration.clientCurrentRate
        .clientVersionCustomRate
    ) {
      return "custom";
    }
  }

  props: any = this.$props;
  modelValue: DetailClientForm = new DetailClientForm({
    id: 0,
    companyName: "",
    email: "",
    phone: "",
    address: "",
    taxNumber: "",
    versionBasic: "",
    isCustomRate: "basic",
    currentDiscount: "",
    discount: "",
    versionName: "",
    archiveFile: "",
    rateVersionId: 0,
    fileCustomRate: new File({
      file: "",
      name: ""
    }),
    rateActiveDate: ""
  });
  // misc variable
  toolTipDiscount = false;
  modalRenewRates = true;

  error = {
    versionBasic: false,
    discount: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    versionName: false
  };

  // Detail Data
  get detailClient() {
    return ClientManagementController.cacheClientManagementDetail;
  }

  // version rates
  fetchVersionRates = debounce((search: string) => {
    ClientManagementController.setErrorVersionBasic(false);
    ClientRegistrationController.getVersionRateList({
      search: search
    });
  }, 250);

  resetVersionRates() {
    ClientManagementController.setErrorVersionBasic(false);
    ClientRegistrationController.setVersionRatesList([]);
    ClientRegistrationController.setLoadingVersionRatesList(true);
  }

  get versionRates() {
    return ClientRegistrationController.versionRateList;
  }

  get loadingVersionRates() {
    return ClientRegistrationController.loadingVersionRateList;
  }

  // Harga Custom
  customPriceOption = [
    {
      label: "Harga Normal",
      value: "basic",
      description:
        "Harga akan menyesuaikan dengan versi harga normal yang tersedia."
    },
    {
      label: "Harga Khusus",
      value: "custom",
      description:
        "Versi harga normal dengan harga khusus untuk rute-rute tertentu."
    },
    {
      label: "Harga Kombinasi",
      value: "mix",
      description: "Penggabungan harga normal dan harga khusus."
    }
  ];

  // Date
  invalidStartDate = false;
  invalidEndDate = false;

  get minDateRenewalRateStart() {
    const date = new Date();
    return new Date(date.setDate(date.getDate() + 1));
  }
  get minDateRenewalRateEnd() {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 2
    );
  }
  get renewalRateStart(): any {
    if (this.isManagement) {
      return ClientManagementController.convertRenewalRateStart;
    } else {
      return ClientRegistrationController.contractStartEdit;
    }
  }
  get renewalRateEnd(): any {
    if (this.isManagement) {
      return ClientManagementController.convertRenewalRateEnd;
    } else {
      return ClientRegistrationController.contractEndEdit;
    }
  }
  inputDateStart(param: Date) {
    if (this.isManagement) {
      ClientManagementController.setRenewalRateStart(param);
    } else {
      ClientRegistrationController.setContractStartEdit(param);
    }
  }

  inputDateEnd(param: Date) {
    if (this.isManagement) {
      ClientManagementController.setRenewalRateEnd(param);
    } else {
      ClientRegistrationController.setContractEndEdit(param);
    }
  }

  get invalidDate() {
    const startDate = new Date(
      formatDateWithoutTime(this.renewalRateStart)
    ).getTime();
    const endDate = new Date(
      formatDateWithoutTime(this.renewalRateEnd)
    ).getTime();

    const nowDate = new Date(formatDateWithoutTime(new Date())).getTime();
    this.error.priceRateStartDate =
      startDate >= endDate || (!this.isUpdateRates && startDate <= nowDate);
    this.error.priceRateEndDate =
      endDate <= startDate || (!this.isUpdateRates && endDate <= nowDate);
    return this.error.priceRateStartDate || this.error.priceRateEndDate;
  }

  get resetSelectSearch() {
    return ClientManagementController.resetSelectSearch;
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]/, "")
      .replace(/[.]+|[,]+/g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  validateDiscount(value: string) {
    value = value.replace(/,/g, ".");
    this.error.discount = Number(value) < 0 || Number(value) > 30;
  }

  get errorVersionBasic() {
    return ClientManagementController.errorVersionBasic;
  }

  // template file custom rates
  templateFile = "Download.csv";
  templateFileName = "template-client-custom-rate.csv";
  templateFileLink = `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-client-custom-rate-20210628.csv`;
  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    downloadFile(url, newFileName, ".csv");
  }

  get isFormValid() {
    switch (this.modelValue.isCustomRate) {
      case "basic":
        if (this.isUpdateRates) {
          return !!this.modelValue.archiveFile;
        } else {
          return (
            this.modelValue.versionBasic &&
            this.modelValue.discount &&
            !this.invalidDate &&
            !this.error.discount &&
            !this.errorVersionBasic &&
            !this.modelValue.rateActiveDate
          );
        }
      case "custom":
        if (this.isUpdateRates) {
          return !!this.modelValue.archiveFile;
        } else {
          return (
            this.modelValue.versionName &&
            this.modelValue.archiveFile &&
            !this.invalidDate &&
            !this.modelValue.rateActiveDate
          );
        }
      case "mix":
        if (this.isUpdateRates) {
          return !!this.modelValue.archiveFile || !!this.modelValue.discount;
        }
        {
          return (
            this.modelValue.versionBasic &&
            this.modelValue.discount &&
            this.modelValue.versionName &&
            this.modelValue.archiveFile &&
            !this.invalidDate &&
            !this.error.discount &&
            !this.errorVersionBasic &&
            !this.modelValue.rateActiveDate
          );
        }
      default:
        return false;
    }
  }

  get isBasicRateBool() {
    return this.modelValue.isCustomRate === "basic";
  }
  get isMixRateBool() {
    return this.modelValue.isCustomRate === "mix";
  }
  get isCustomRateBool() {
    return this.modelValue.isCustomRate === "custom";
  }

  onSelectRatesType(val: string) {
    if (this.modelValue.isCustomRate === val) {
      return;
    }
    this.modelValue.archiveFile = "";
    this.modelValue.versionName = "";
    this.modelValue.versionBasic = "";
    this.modelValue.discount = "";
    this.modelValue.fileCustomRate = {
      file: "",
      name: ""
    };
  }

  onSubmit() {
    this.modalRenewRates = false;
    if (this.isAdd) {
      this.submitRenewRates();
    } else if (this.isUpdateRates) {
      this.submitUpdateCurrentRates();
    } else {
      this.submitEditRenewRates();
    }
  }

  async submitRenewRates() {
    const params = {
      clientId: this.id,
      versionBasic: this.modelValue.versionBasic,
      discount: this.modelValue.discount,
      isCustomRate: this.isMixRateBool,
      versionName: this.modelValue.versionName,
      archiveFile: this.modelValue.archiveFile,
      startDate: this.startDate,
      endDate: this.endDate,
      isCustomRateOnly: this.isCustomRateBool,
      onCallback: () => {
        this.modalRenewRates = true;
      }
    };
    const resp = await ClientManagementController.addRenewRates(params);
    if (resp) {
      MainAppController.setSnackbarMessage(
        `Pembaruan harga Client “${this.detailClient.clientName}” berhasil. Mohon menunggu sampai Admin menyetujui permintaan Anda.`
      );
      MainAppController.setSnackbar(true);
      this.$emit("close", false);
      this.$emit("success");
    }
  }

  get startDate(): any {
    let date;
    if (this.isManagement) {
      date = ClientManagementController.convertRenewalRateStart;
    } else {
      date = ClientRegistrationController.contractStartEdit;
    }

    date = new Date(date.setHours(0, 0, 0, 0));
    date = new Date(date.setDate(date.getDate() + 1));
    return JSON.stringify(date).slice(1, 11);
  }
  get endDate(): any {
    let date;
    if (this.isManagement) {
      date = ClientManagementController.convertRenewalRateEnd;
    } else {
      date = ClientRegistrationController.contractEndEdit;
    }

    date = new Date(date.setHours(0, 0, 0, 0));
    date = new Date(date.setDate(date.getDate() + 1));
    return JSON.stringify(date).slice(1, 11);
  }

  async submitUpdateCurrentRates() {
    const isSuccess = await ClientManagementController.updateCurrentRates({
      data: new RequestUpdateCurrentRates({
        clientId: this.id,
        versionBasic: this.modelValue.versionBasic,
        discount: this.modelValue.discount,
        archiveFile: this.modelValue.archiveFile,
        rateActiveDate: this.$moment(this.modelValue.rateActiveDate).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      }),
      onCallback: () => {
        this.modalRenewRates = true;
      }
    });

    if (isSuccess) {
      MainAppController.setSnackbarMessage(
        "Pembaruan harga saat ini berhasil diubah"
      );
      MainAppController.setSnackbarTimeout(2000);
      MainAppController.setSnackbar(true);
      this.$emit("close", false);
      this.$emit("success");
    } else {
      this.$emit("close", false);
    }
  }

  async submitEditRenewRates() {
    let resp = false;
    if (this.isManagement) {
      resp = await this.submitEditRenewRatesManagement();
    } else {
      resp = await this.submitEditRenewRatesNonManagement();
    }

    if (resp) {
      MainAppController.setSnackbarMessage("Pembaruan harga berhasil diubah");
      MainAppController.setSnackbarTimeout(2000);
      MainAppController.setSnackbar(true);
      this.$emit("close", false);
      this.$emit("success");
    } else {
      this.$emit("close", false);
    }
  }

  async submitEditRenewRatesManagement() {
    let resp = false;
    const renewalRate: any = this.modelValue;
    resp = await ClientManagementController.editRenewRate({
      id: this.id,
      companyName: this.detailData.clientName,
      email: this.detailData.clientEmail,
      phone: this.detailData.clientPhone,
      address: this.detailData.clientAddress,
      taxNumber: this.detailData.clientTaxNumber,
      versionBasic: renewalRate.versionBasic.rateVersionName
        ? renewalRate.versionBasic.rateVersionName
        : renewalRate.versionBasic,
      isCustomRate: this.isMixRateBool,
      discount: renewalRate.discount,
      priceRateStartDate: this.startDate,
      priceRateEndDate: this.endDate,
      versionName: renewalRate.versionName,
      archiveFile:
        typeof renewalRate.archiveFile === "object"
          ? renewalRate.archiveFile
          : "",
      isUpdateCustom: typeof renewalRate.archiveFile === "object",
      rateVersionId: renewalRate.rateVersionId,
      isCustomRateOnly: this.isCustomRateBool,
      onCallback: () => {
        this.modalRenewRates = true;
      }
    });
    return resp;
  }

  async submitEditRenewRatesNonManagement() {
    let resp = false;
    const renewalRate: any = this.modelValue;
    resp = await ClientRegistrationController.editClientRegistration({
      isOnlyEditInfo: false,
      id: this.detailDataClientRegistration.id,
      companyName: this.detailDataClientRegistration.clientName,
      email: this.detailDataClientRegistration.clientEmail,
      phone: `62${this.detailDataClientRegistration.clientPhone}`,
      address: this.detailDataClientRegistration.clientAddress,
      district: this.detailDataClientRegistration.clientDistrict?.code,
      taxNumber: this.detailDataClientRegistration.clientTaxNumber,
      versionBasic:
        typeof this.modelValue.versionBasic === "object"
          ? this.modelValue.versionBasic.rateVersionName
          : this.modelValue.versionBasic,
      isCustomRate: this.isMixRateBool,
      discount: this.modelValue.discount,
      priceRateStartDate: this.startDate,
      priceRateEndDate: this.endDate,
      versionName: renewalRate.versionName,
      archiveFile:
        typeof renewalRate.archiveFile === "object"
          ? renewalRate.archiveFile
          : "",
      isUpdateCustom:
        typeof renewalRate.archiveFile === "object" ? true : false,
      clientType:
        this.detailDataClientRegistration.clientType === "Client Induk"
          ? "parent"
          : "branch",
      category: this.detailDataClientRegistration.clientCategory,
      bankName: this.detailDataClientRegistration.clientBankLabel,
      bankAccountName: this.detailDataClientRegistration.clientBankName,
      bankAccountNumber: this.detailDataClientRegistration.clientBankNumber,
      bankAccountEmail: this.detailDataClientRegistration.clientBankEmail,
      bankAccountLabel: this.detailDataClientRegistration.clientBankLabel,
      contactName: this.detailDataClientRegistration.clientContact
        .clientContactName,
      contactJobTitle: this.detailDataClientRegistration.clientContact
        .clientContactJobTitle,
      contactEmail: this.detailDataClientRegistration.clientContact
        .clientContactEmail,
      contactPhone: `62${this.detailDataClientRegistration.clientContact.clientContactPhone}`,
      posId: this.detailDataClientRegistration.clientPartner.clientPartnerId,
      posCommission: this.detailDataClientRegistration.clientPartner
        .clientPartnerCommissionAmount,
      posQuantifier: this.detailDataClientRegistration.clientPartner
        .clientPartnerQuantifierCommission,
      isDo: this.detailDataClientRegistration.clientIsDo,
      isCod: this.detailDataClientRegistration.clientIsCod,
      branchReturnCodName: this.detailDataClientRegistration.clientCodReject
        .clientCodName,
      branchReturnCodPhone: this.detailDataClientRegistration.clientCodReject
        .clientCodPhone,
      branchReturnCodAddress: this.detailDataClientRegistration.clientCodReject
        .clientCodAddress,
      branchReturnCodCity: this.detailDataClientRegistration.clientCodReject
        .clientCodDistrict.cityCode,
      branchReturnCodDistrict: this.detailDataClientRegistration.clientCodReject
        .clientCodDistrict.code,
      branchReturnCodFee: Number(
        this.detailDataClientRegistration.clientCodReject.clientCodCommission
      ),
      isCustomRateOnly: this.isCustomRateBool
    });
    return resp;
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get detailData() {
    return ClientManagementController.detailClientManagement;
  }

  get detailDataClientRegistration() {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  get columnToValidate() {
    return [
      {
        column: "start_weight",
        type: "number",
      },
      {
        column: "end_weight",
        type: "number",
      },
      {
        column: "publish_rate",
        type: "number",
      },
      {
        column: "shipping_surcharge",
        type: "number",
      },
      {
        column: "document_surcharge",
        type: "number",
      },
    ];
  }
}
