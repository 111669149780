
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement()
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailBankSettlementInformation extends Vue.with(Props) {}
