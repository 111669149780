
import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";
import ClientsV1 from "../clients/index.vue";
import ClientsV2 from "../clients-v2/index.vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: {
    ClientsV1,
    ClientsV2,
  },
})
export default class EntryClient extends Vue {
  get isClientVersionV2() {
    return FlagsPermissionClient.client_v2_enable.isEnabled();
  }

  get renderedPage(): string {
    return this.isClientVersionV2 ? "ClientsV2" : "ClientsV1";
  }
}
