
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import FormatPhoneNumber from "@/app/infrastructures/misc/common-library/FormatPhoneNumber";
class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement()
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailBranchClient extends Vue.with(Props) {
  get idClient() {
    return String(this.detailData.id);
  }

  formatPhoneNumber(val: any) {
    return FormatPhoneNumber(val);
  }
}
