import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, {
      class: "mb-4",
      label: "Informasi Pajak"
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "NPWP/KTP",
          value: _ctx.detailData.clientTaxNumber || '-'
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "w-6/12",
          label: "Nama sesuai NPWP/KTP",
          value: _ctx.detailData.clientTax.taxPayerName || '-'
        }, null, 8, ["value"])
      ]),
      _createVNode(_component_DataWrapper, {
        class: "w-7/12",
        label: "Alamat sesuai NPWP/KTP",
        value: _ctx.detailData.clientTax.taxPayerAddress || '-'
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-7/12",
        label: "Kode transaksi faktur pajak",
        value: _ctx.taxTransactionCode
      }, null, 8, ["value"])
    ])
  ], 64))
}