import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_Modal = _resolveComponent("Modal")!

  return _withDirectives((_openBlock(), _createBlock(_component_Modal, {
    title: _ctx.headerSection.title,
    message: _ctx.headerSection.message,
    image: "warning",
    isDisabled: _ctx.isDisabled,
    textSuccess: _ctx.headerSection.textSuccess,
    textCancel: _ctx.headerSection.textCancel,
    onClose: _ctx.confirmationCancel,
    onSubmit: _ctx.confirmationSubmit
  }, _createSlots({ _: 2 }, [
    (_ctx.typeConfirm === 1)
      ? {
          name: "custom",
          fn: _withCtx(() => [
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_lp_input, {
                "text-area-rows": "1",
                modelValue: _ctx.selectedReason,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedReason = $event)),
                type: "textarea",
                placeholder: "Tulis alasan penolakan"
              }, null, 8, ["modelValue"])
            ])
          ])
        }
      : undefined
  ]), 1032, ["title", "message", "isDisabled", "textSuccess", "textCancel", "onClose", "onSubmit"])), [
    [_vShow, _ctx.isShow]
  ])
}