import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col px-3 py-5 rounded-xl border border-gray-lp-1300" }
const _hoisted_2 = { class: "w-full flex flex-row gap-4" }
const _hoisted_3 = { class: "w-full flex-1" }
const _hoisted_4 = { class: "w-full flex-1" }
const _hoisted_5 = { class: "w-full flex-1" }
const _hoisted_6 = { class: "w-full flex flex-row gap-4" }
const _hoisted_7 = { class: "w-1/3" }
const _hoisted_8 = { class: "w-1/3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Select = _resolveComponent("Select")!
  const _component_lp_input = _resolveComponent("lp-input")!

  return (_openBlock(), _createBlock("section", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Title, {
        class: "mb-4",
        fontSize: 20,
        label: "Detail klien induk"
      }),
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_DataWrapper, {
            label: "Nama klien induk",
            asterisk: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectSearch, {
                isDisabled: _ctx.isEdit,
                options: _ctx.clientList,
                onFilter: _ctx.filterClient,
                keyName: "name",
                keyValue: "id",
                placeholder: "Masukan atau pilih induk client",
                modelValue: _ctx.modelValue.parentName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue.parentName = $event)),
                isLoading: _ctx.isLoadingClient,
                class: "my-2"
              }, null, 8, ["isDisabled", "options", "onFilter", "modelValue", "isLoading"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DataWrapper, {
            label: "Nama partner POS",
            optional: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectSearch, {
                options: _ctx.posList,
                onFilter: _ctx.filterPOS,
                keyName: "name",
                keyValue: "id",
                placeholder: "Pilih partner POS",
                modelValue: _ctx.modelValue.posName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.modelValue.posName = $event)),
                isLoading: _ctx.isLoadingPOS,
                class: "my-2"
              }, null, 8, ["options", "onFilter", "modelValue", "isLoading"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_DataWrapper, {
            label: "Pembilang Komisi",
            asterisk: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Select, {
                onOpenSelect: _ctx.onOpenSelectNumeratorCommission,
                onCloseSelect: _ctx.onCloseSelectNumeratorCommission,
                isOpenMenu: _ctx.isOpenNumeratorCommission,
                value: _ctx.modelValue.numeratorCommission,
                onChooseSelect: _ctx.onSelectNumeratorCommission,
                dataSelect: _ctx.numeratorCommissionList,
                class: "w-full whitespace-no-wrap my-2"
              }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect"])
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_DataWrapper, {
            label: "Tipe Komisi",
            asterisk: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Select, {
                onOpenSelect: _ctx.onOpenSelectCommissionType,
                onCloseSelect: _ctx.onCloseSelectCommissionType,
                isOpenMenu: _ctx.isOpenCommissionType,
                value: _ctx.modelValue.commisionType,
                onChooseSelect: _ctx.onSelectCommissionType,
                dataSelect: _ctx.commisionTypes,
                class: "whitespace-no-wrap my-2"
              }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_8, [
          _createVNode(_component_DataWrapper, {
            label: "Komisi POS",
            asterisk: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_lp_input, {
                format: _ctx.formatFloat,
                modelValue: _ctx.modelValue.commision,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.modelValue.commision = $event)),
                error: _ctx.error.commision,
                onChange: _ctx.validateCommision,
                errorCaption: _ctx.errorCaptionCommision,
                placeholder: "e.g 20",
                min: "0",
                max: "100",
                suffix: "%"
              }, null, 8, ["format", "modelValue", "error", "onChange", "errorCaption"])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}