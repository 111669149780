
import { Options, prop, Vue } from "vue-class-component";

class Props {
  modelValue = prop<any>({
    required: true,
  });
}
@Options({
  emits: ["update:modelValue"],
})
export default class ClientBranchSection extends Vue.with(Props) {
  get deliveryOrderPaymentType() {
    return this.modelValue?.match(/free/g) ? "Gratis" : "Berbayar";
  }

  dOOptions = [
    {
      name: "Gratis",
      value: "free",
    },
    {
      name: "Berbayar",
      value: "paid",
    },
  ];
  onSelectDO(option: any) {
    this.$emit("update:modelValue", option.value);
  }
}
