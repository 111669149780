import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Parent = _resolveComponent("Parent")!
  const _component_Branch = _resolveComponent("Branch")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.getFormType === 'parent')
      ? (_openBlock(), _createBlock(_component_Parent, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.getFormType === 'branch')
      ? (_openBlock(), _createBlock(_component_Branch, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}