import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-10/12 grid grid-cols-2 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, { label: "Pengembalian Delivery Order" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        label: "Tipe Pembayaran Delivery Order",
        value: _ctx.deliveryOrderPaymentType,
        asterisk: _ctx.isForm
      }, {
        default: _withCtx(() => [
          (_ctx.isForm)
            ? (_openBlock(), _createBlock(_component_CustomDropdown, {
                key: 0,
                selectedItem: _ctx.detailData.clientBranchDoPaymentType,
                options: _ctx.dOOptions,
                onSelect: _ctx.onSelectDO,
                placeholder: "Pilih Tipe DO",
                class: "w-full my-2"
              }, null, 8, ["selectedItem", "options", "onSelect"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["value", "asterisk"])
    ])
  ], 64))
}