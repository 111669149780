
import { Options, prop, Vue } from "vue-class-component";
import RadioButton from "primevue/radiobutton";
import ToggleV2 from "@/app/ui/components/toggle-v2/index.vue";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  error = prop<any>({
    required: true,
  });
}

@Options({
  emits: ["update:modelValue"],
  components: {
    RadioButton,
    ToggleV2,
  },
})
export default class CodType extends Vue.with(Props) {
  tooltipCODShipmentDiscount = `<ol style="list-style: number; padding-left: 15px;">
    <li>Klien akan dibebankan biaya booking STT COD sebesar <strong>ongkir klien setelah <br />diskon.</strong></li>
    <li>Tipe COD amount harga barang + ongkir akan menggunakan <strong>ongkir klien <br/>sebelum diskon</strong></li>
  </ol>`;

  onChangeDeliveryFeeType(val: string) {
    this.modelValue.codRej.codAmountType = val;
  }

  onDFODChange(value: boolean) {
    this.modelValue.clientIsDfod = value;
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.|\.,|,,|,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  errorCaptionCodRejCommision = "";
  validateCodRejCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.codRejCommision = true;
      this.errorCaptionCodRejCommision = "Biaya COD tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.codRejCommision = true;
      this.errorCaptionCodRejCommision =
        "Biaya COD tidak boleh lebih dari 100%";
    } else {
      this.error.codRejCommision = false;
    }
  }

  errorCaptionCodRejShipmentDiscount = "";
  validateCodRejShipmentDiscount(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) > 100) {
      this.error.codRejShipmentDiscount = true;
      this.errorCaptionCodRejShipmentDiscount =
        "Persentase Diskon COD tidak boleh lebih dari 100%";
    } else {
      this.error.codRejShipmentDiscount = false;
      this.errorCaptionCodRejShipmentDiscount = "";
    }
  }
}
