
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { formatDateWithoutTime } from "@/app/infrastructures/misc/Utils";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { translateStatusClient } from "@/app/ui/views/sales/clients/client-management/utils-client-management";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { CLIENT } from "@/app/infrastructures/misc/RolePermission";
import FreezeUnfreezePopup from "@/app/ui/views/sales/clients/client-management/component/freeze-unfreeze-popup.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
  components: {
    EmptyState,
    FreezeUnfreezePopup
  },

  computed: {
    isDetailAble() {
      return checkRolePermission(CLIENT.MANAGEMENT_DETAIL);
    },
    isActionAble() {
      return checkRolePermission(CLIENT.MANAGEMENT_EDIT);
    }
  }
})
export default class ClientManagement extends Vue {
  mounted() {
    ClientManagementController.initClientManagement();
  }
  onRequest() {
    ClientManagementController.fetchClientManagementList();
  }
  get firstRequest() {
    return ClientManagementController.firstRequest;
  }
  get clientManagement() {
    return ClientManagementController.clientManagementData.data;
  }

  // filter Search
  onSearch(value: string) {
    ClientManagementController.searchAction(value);
  }
  get searchValue() {
    return ClientManagementController.search;
  }
  clearSearch() {
    ClientManagementController.clear();
  }

  // rates
  statusSelect = false;
  statusName = "";
  statusRates = [
    { name: "Semua Harga", value: "" },
    { name: "Active", value: "active" },
    { name: "Pending", value: "pending" },
    { name: "Nearly Expired", value: "nearly_expired" },
    { name: "Expired", value: "expired" }
  ];
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    ClientManagementController.selectStatusRatesAction({
      name: name,
      value: value
    });
    this.statusName = name;
    this.onCloseStatusSelect();
  }

  // renewal
  statusSelectRenewal = false;
  statusNameRenewal = "";
  statusDataRenewal = [
    { name: "Semua Status Client", value: "" },
    { name: "Dibekukan", value: "freeze" },
    { name: "Aktif", value: "non-freeze" }
  ];
  onOpenStatusSelectRenewal() {
    this.statusSelectRenewal = true;
  }
  onCloseStatusSelectRenewal() {
    this.statusSelectRenewal = false;
  }
  onSelectStatusRenewal(name: string, value: string) {
    ClientManagementController.selectStatusRenewalAction({
      name: name,
      value: value
    });
    this.statusNameRenewal = name;
    this.onCloseStatusSelectRenewal();
  }

  // type client
  typeSelect = false;
  typeName = "";
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  onSelectType(name: string, value: string) {
    ClientManagementController.selectTypeAction({
      name: name,
      value: value
    });
    this.typeName = name;
    this.onCloseTypeSelect();
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = () => null;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter() {
    this.onSelectStatus("", "");
    this.onSelectStatusRenewal("", "");
    this.onSelectType("", "");
  }
  advancedFilterData: any = null;

  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }

  typeData = [
    { name: "Semua Tipe Client", value: "" },
    { name: "Client Induk", value: "parent" },
    { name: "Client Cabang", value: "branch" }
  ];

  get countFilter() {
    return (
      (ClientManagementController.filterStatusRates ? 1 : 0) +
      (ClientManagementController.filterStatusRenewal ? 1 : 0) +
      (ClientManagementController.filterType ? 1 : 0)
    );
  }

  // handling
  get isLoading() {
    return ClientManagementController.isLoading;
  }
  get isError() {
    return ClientManagementController.isError;
  }
  get errorCause() {
    return ClientManagementController.isErrorCause;
  }
  onClickRow(item: any) {
    this.$router.push(
      `/client/client-management/${item.clientId}?params=${item.clientCode}`
    );
  }
  get pagination() {
    return ClientManagementController.clientManagementData.pagination;
  }

  statusRenewalChips(status: string) {
    switch (status) {
      case "approved":
        return "check-circle";
      case "requested":
        return "timer";
      case "rejected":
        return "x-circle";
      default:
        return "check-circle";
    }
  }

  iconRenewalChip(status: string) {
    return require(`@/app/ui/assets/svg/${this.statusRenewalChips(
      status
    )}.svg`);
  }

  columns = [
    {
      name: "ID Client",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.clientId}</span>`;
      }
    },
    {
      name: "Nama Client",
      styleHead: "w-56 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.clientCompanyName}</span>`;
      }
    },
    {
      name: "Kode Client",
      styleHead: "w-32 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.clientCode}</span>`;
      }
    },
    {
      name: "Tipe Client",
      styleHead: "w-36 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.clientType}</span>`;
      }
    },
    {
      name: "Periode Harga",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${formatDateWithoutTime(
          item.rateVersionStartDate
        )} - ${formatDateWithoutTime(item.rateVersionEndDate)}</div>`;
      }
    },
    {
      name: "Status Client",
      styleHead: "w-36 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `
          <div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center ${
            translateStatusClient(item.clientStatus).color
          } ${translateStatusClient(item.clientStatus).textColor}"
        ><span class="capitalize">${
          translateStatusClient(item.clientStatus).label
        }</span></div></div>`;
      }
    },
    {
      name: "Status Harga",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `
          <div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center ${
            translateStatusClient(item.rateVersionStatus.toLowerCase()).color
          } ${
          translateStatusClient(item.rateVersionStatus.toLowerCase()).textColor
        }"
        ><span class="capitalize">${
          translateStatusClient(item.rateVersionStatus.toLowerCase()).label
        }</span></div></div>`;
      }
    },
    {
      name: "Status Pembaruan",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: (item: any) => {
        if (item.rateVersionStatusRenewal !== "") {
          return `
          <div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center ${
            translateStatusClient(item.rateVersionStatusRenewal.toLowerCase())
              .color
          } ${
            translateStatusClient(item.rateVersionStatusRenewal.toLowerCase())
              .textColor
          }"
        ><span class="capitalize">${
          translateStatusClient(item.rateVersionStatusRenewal.toLowerCase())
            .label
        }</span></div></div>`;
        } else {
          return ``;
        }
      }
    },
    {
      name: "Action",
      key: "button_column",
      styleHead: "w-50 text-left",
      styleButton: (item: any) => {
        if (item.clientStatus === "freeze") {
          return {
            iconLeft: "circle-green",
            textColor: "green-lp-100",
            blankIcon: true,
            flat: true,
            compact: true,
            title: "Unfreeze",
            customClass: ``,
            clickFunction: (e: any) => {
              MainAppController.setSnackbar(false);
              this.clientSelected = {
                id: e.clientId,
                name: e.clientCompanyName,
                type: e.clientType,
                status: true
              };
              this.freezeUnFreezeModal = true;
            }
          };
        }
        return {
          flat: true,
          compact: true,
          iconLeft: "circle-red",
          blankIcon: true,
          class: "w-full",
          textColor: "red-lp-100",
          customClass: ``,
          title: "Freeze",
          clickFunction: (e: any) => {
            MainAppController.setSnackbar(false);
            this.clientSelected = {
              id: e.clientId,
              name: e.clientCompanyName,
              type: e.clientType,
              status: false
            };
            this.freezeUnFreezeModal = true;
          }
        };
      }
    }
  ];

  // freeze un freeze
  freezeUnFreezeModal = false;
  clientSelected: any = "";
}
