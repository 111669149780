
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import {
  formatDateNormal,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import { Options, prop, Vue } from "vue-class-component";
import PopupRenewRate from "../../../global-components/popup-renew-rate.vue";
import { FlagsPermissionClient } from "@/feature-flags/flags-permission-client";
class Props {
  detailData = prop<DetailClientManagement>({
    default: new DetailClientManagement(),
    required: true,
    type: Object
  });
}

@Options({
  components: {
    PopupRenewRate
  }
})
export default class RenewalCardCard extends Vue.with(Props) {
  letterCodeModal = false;

  get renewalCustomRate() {
    return this.detailData.clientRenewalRate;
  }

  get isEmpty() {
    return !this.renewalCustomRate.clientCustomRateId;
  }

  showModalRenewRate = false;
  onShowPopupRenewRate() {
    this.showModalRenewRate = !this.showModalRenewRate;
  }

  get status() {
    return this.renewalCustomRate.clientStatusCustomRate;
  }

  get isRequested() {
    return this.status.toLowerCase() == "requested";
  }

  get isApproved() {
    return this.status.toLowerCase() == "approved";
  }

  get isRejected() {
    return this.status.toLowerCase() == "rejected";
  }

  get isExpired() {
    return this.status.toLowerCase() == "expired";
  }

  get isUploading() {
    return this.status.toLowerCase() == "uploading";
  }

  get priceType() {
    return this.detailData.clientRenewalRate.clientRatePriceType;
  }

  get priceTypeRenewRates() {
    if (this.priceType.toLowerCase() === "retail") {
      return "Harga Retail";
    } else if (
      this.priceType.toLowerCase() === "normal" ||
      this.priceType.toLowerCase() === "normal_custom_3lc"
    ) {
      return "Harga Normal";
    } else if (
      this.priceType.toLowerCase() === "combination" ||
      this.priceType.toLowerCase() === "combination_custom_3lc"
    ) {
      return "Harga Kombinasi";
    } else if (
      this.priceType.toLowerCase() === "custom_client" ||
      this.priceType.toLowerCase() === "custom_client_retail_district"
    ) {
      return "Harga Khusus";
    } else if (this.priceType.toLowerCase().includes("legacy")) {
      return this.priceTypeLegacyRenewRates;
    } else {
      return "Harga retail";
    }
  }

  get priceTypeLegacyRenewRates() {
    if (
      this.detailData?.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData?.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "mix";
    }
    if (
      !this.detailData?.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData?.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "custom";
    }
    return "basic";
  }

  get statusRenewalCaptions() {
    if (this.isRejected) {
      return this.detailData.clientRenewalRate.clientStatusUpdatedReason;
    }

    return "";
  }

  get statusRenewalUpdateAt() {
    return formatDate(this.detailData.clientRenewalRate.clientStatusUpdatedAt);
  }

  get statusRenewalUpdatedBy() {
    return this.detailData.clientRenewalRate.clientStatusUpdatedBy;
  }

  get renewalRatePeriod() {
    return this.formateRatePeriod(
      this.detailData.clientRenewalRate.clientRateversionStartDate,
      this.detailData.clientRenewalRate.clientRateVersionEndDate
    );
  }

  get renewalCustomRateValue() {
    const isCustomRate = this.detailData.clientRenewalRate.clientRatePriceType
      .split("-")[0]
      .includes("custom");
    if (this.detailData.clientIsCustomRate || isCustomRate) {
      return this.detailData.clientRenewalRate.clientVersionCustomRate || "-";
    }

    return "-";
  }

  get renewalRate() {
    return this.detailData.clientRenewalRate.clientVersionBaseRate || "-";
  }

  get lcOptions() {
    return this.detailData.clientRenewalRate.clientRateVersionCustom3lc
      ? this.detailData.clientRenewalRate.clientRateVersionCustom3lc.split(",")
      : [];
  }

  get isCustomRate() {
    return (
      this.detailData.clientRenewalRate.clientRatePriceType.split("_")[0] ===
      "custom"
    );
  }

  get isCustomRateFile() {
    return this.detailData.clientRenewalRate.clientFileCustomRate || (this.detailData.clientIsCustomRate && this.detailData.clientCurrentRate.clientRatePriceType.toLowerCase() === "legacy");
  }

  get isErrorCustomRateFile() {
    return (
      this.detailData.clientRenewalRate.clientErrorFileCustomRate &&
      this.detailData.clientRenewalRate.clientErrorNameCustomRate !==
        "error_log_.csv"
    );
  }

  // download file
  download(url: string, fileName: string): void {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    const fileExt = fileName.split(".").pop();

    downloadFile(url, newFileName, `.${fileExt}`).catch((error: any) => {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Download file gagal!!")
      );
    });
  }

  formateRatePeriod(start: string, end: string) {
    const isSameMonthYear =
      formatDateNormal(start, "MMMM-YYYY") ===
      formatDateNormal(end, "MMMM-YYYY");

    return `${formatDateNormal(
      start,
      isSameMonthYear ? "DD" : "DD MMMM YYYY"
    )} - ${formatDateNormal(end, "DD MMMM YYYY")}`;
  }

  get insuranceRateCalculation() {
    const insuranceValuePercentage = this.detailData.clientRenewalRate.clientRateInsuranceValuePercentage
      .toString()
      .replace(".", ",");
    switch (
      this.detailData.clientRenewalRate.clientRateInsuranceType.toLowerCase()
    ) {
      case "":
        return "Retail (Tiering)";
      case "bundling":
        return "Bundling(No Insurance Fee)";
      case "custom":
        return "Custom Rates " + insuranceValuePercentage + "%";
      default:
        return "-";
    }
  }

  get ableToRenewRatesByFlags() {
    return FlagsPermissionClient.permission_client_management_renew_rate.isEnabled();
  }

  get ableToUpdateLiveRateByFlags() {
    return FlagsPermissionClient.permission_client_management_update_live_rate.isEnabled();
  }
}
