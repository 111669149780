
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { optionTaxTransactionCode } from "@/app/infrastructures/misc/Constants";
class Props {
  isManagement = prop<boolean>({
    required: false,
    default: false,
    type: Boolean
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailTaxInformation extends Vue.with(Props) {
  get detailData() {
    if (this.isManagement) {
      return ClientManagementController.detailClientManagement;
    }

    return ClientRegistrationController.clientRegistrationDetail;
  }

  get taxTransactionCode() {
    const transactionCode = this.detailData.clientTax.transactionCode.substr(
      0,
      2
    );

    return (
      optionTaxTransactionCode.find(tc => {
        return tc.value.substr(0, 2) === transactionCode;
      })?.name || this.detailData.clientTax.transactionCode
    );
  }
}
