
import {
  formatDate,
  formatDateNormal,
  formatPriceRP,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { EditRenewRatesClientManagementApiRequestV2 } from "@/data/payload/api/ClientManagementApiRequest";
import { OptionsClass } from "@/domain/entities/MainApp";
import { Options, Vue } from "vue-class-component";
import ConfirmationApprovalRejectClient from "../../global-components/confirmation-approval-reject-client.vue";
import ClientDetailCardBranch from "../../global-components/detail/branch/card-detail-client.vue";
import ClientDetailCardParent from "../../global-components/detail/parent/client-detail.card.vue";
import RenewalRateCard from "../../global-components/detail/parent/renewal-rate-card.vue";
import PriceTypeForm from "../../global-components/price-type-form.vue";
import InfoDetailClient from "../../registration/component/info-detail-client/index.vue";
import InfoPriceClient from "../../registration/component/info-price-client/index.vue";

@Options({
  components: {
    InfoDetailClient,
    InfoPriceClient,
    RenewalRateCard,
    PriceTypeForm,
    ConfirmationApprovalRejectClient,
    ClientDetailCardBranch,
    ClientDetailCardParent
  }
})
export default class ClientManagementDetail extends Vue {
  renewRateDialog = false;
  isRenewRateConfirmation = false;

  form = {
    isCustomRate: "retail",
    isInsurance: false,
    insurance: "",
    customRates: "",
    priceRateStartDate: "",
    priceRateEndDate: "",
    originCities: [] as OptionsClass[],
    discount: "",
    versionName: "",
    archiveFile: "" as any,
    retailBasedDistrict: false,
    versionBasic: "" as any,
    rateVersionId: ""
  };

  error = {
    isCustomRate: false,
    isInsurance: false,
    insurance: false,
    customRates: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    originCities: false,
    discount: false,
    versionName: false,
    archiveFile: false,
    retailBasedDistrict: false,
    versionBasic: false
  };

  customPriceOption = [
    {
      disabled: false,
      label: "Harga retail",
      description: "Harga akan mengikuti harga retail",
      value: "retail"
    },
    {
      disabled: false,
      label: "Harga normal",
      description: "Harga akan mengikat selama periode harga yang ditentukan.",
      value: "normal"
    },
    {
      disabled: false,
      label: "Harga khusus",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload.",
      value: "khusus"
    },
    {
      disabled: false,
      label: "Harga kombinasi",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload & harga retail.",
      value: "kombinasi"
    }
  ];

  mounted() {
    this.fetchDetail();
  }

  get id() {
    return this.$route.params.id;
  }

  get title() {
    return this.detailData.clientName;
  }

  get isParent() {
    return this.detailData.clientParentId === 0;
  }

  get detailData() {
    return ClientManagementController.detailClientManagement;
  }

  get balance() {
    return formatPriceRP(this.detailData.clientBalance);
  }

  get createdAt() {
    return formatDate(this.detailData.clientCreatedAt);
  }

  get updatedAt() {
    return formatDate(this.detailData.clientUpdatedAt);
  }

  get isRequested() {
    return (
      this.detailData.clientRenewalRate.clientStatusCustomRate.toLowerCase() ===
      "requested"
    );
  }

  get clientStatus() {
    return (
      this.detailData?.clientCurrentRate?.clientStatusCustomRate.toLowerCase() ||
      ""
    );
  }

  get errorMessage() {
    return this.detailData.clientRenewalRate.clientStatusUpdatedReason;
  }

  async fetchDetail() {
    await ClientManagementController.getDetailV2(Number(this.id));

    if (this.detailData.clientParentId === 0) {
      BeneficiaryAccountController.getBeneficiaryAccList({
        page: 1,
        limit: 1,
        bankAccType: "beneficiary",
        actortype: "client",
        actorId: this.detailData.id
      });
    }
  }

  goBack() {
    this.$router.push("/client/client-management");
  }

  get isLoading() {
    return ClientManagementController.isLoading;
  }

  get isError() {
    return ClientManagementController.isError;
  }

  get errorCause() {
    return ClientManagementController.isErrorCause;
  }

  get isBasicRateBool() {
    return this.form.isCustomRate === "normal";
  }
  get isMixRateBool() {
    return this.form.isCustomRate === "kombinasi";
  }
  get isCustomRateBool() {
    return this.form.isCustomRate === "khusus";
  }

  onRenewRate() {
    this.renewRateDialog = true;
  }

  renewRate() {
    this.isRenewRateConfirmation = true;
  }

  confirmRenewRateConfirmation() {
    this.isRenewRateConfirmation = false;
    this.renewRateDialog = true;
  }

  closeRenewRateConfirmation() {
    this.isRenewRateConfirmation = false;
  }

  get isValidRenewRate() {
    let result = false;
    if (this.form.isCustomRate === "retail") {
      if (
        !this.form.isInsurance ||
        (this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    if (this.form.isCustomRate === "normal") {
      if (
        (this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          !this.form.isInsurance) ||
        (this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    if (this.form.isCustomRate === "khusus") {
      if (
        (this.form.versionName &&
          this.form.archiveFile.name &&
          !this.form.isInsurance) ||
        (this.form.versionName &&
          this.form.archiveFile.name &&
          this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    if (this.form.isCustomRate === "kombinasi") {
      if (
        (this.form.versionName &&
          this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          this.form.archiveFile.name &&
          !this.form.isInsurance) ||
        (this.form.versionName &&
          this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          this.form.archiveFile.name &&
          this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    return (
      result && !this.error.priceRateStartDate && !this.error.priceRateEndDate
    );
  }

  async submitRenewRate() {
    this.isRenewRateConfirmation = false;
    const payload = new EditRenewRatesClientManagementApiRequestV2({
      clientId: Number(this.id),
      versionBasic: this.form.versionBasic.rateVersionName
        ? this.form.versionBasic.rateVersionName
        : this.form.versionBasic,
      isCustomRate: this.isMixRateBool,
      discount: this.form.discount
        ? parseFloat(this.form.discount.replace(",", "."))
        : 0,
      startDate: formatDateNormal(this.form.priceRateStartDate, "YYYY-MM-DD"),
      endDate: formatDateNormal(this.form.priceRateEndDate, "YYYY-MM-DD"),
      versionName: this.form.versionName,
      archiveFile:
        typeof this.form.archiveFile === "object" ? this.form.archiveFile : "",
      rateVersionId: this.detailData.clientCurrentRate.clientCustomRateId,
      isCustomRateOnly: this.isCustomRateBool
    });

    MainAppController.showLoading();
    await ClientManagementController.editRenewRateV2(payload)
      .then(() => {
        this.renewRateDialog = false;
        MainAppController.setSnackbarMessage(
          `Perubahan harga klien induk “${this.detailData.clientName}” berhasil!`
        );
        MainAppController.setSnackbarTimeout(2000);
        MainAppController.setSnackbar(true);
        ClientManagementController.getDetailV2(this.detailData.id);
        this.onResetForm();
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            `Perubahan harga klien induk “${this.detailData.clientName}” gagal!`
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  onResetForm(): void {
    this.form = {
      archiveFile: "",
      customRates: "",
      discount: "",
      insurance: "",
      isCustomRate: "retail",
      isInsurance: false,
      originCities: [],
      priceRateEndDate: "",
      priceRateStartDate: "",
      retailBasedDistrict: false,
      versionBasic: "",
      versionName: "",
      rateVersionId: ""
    };
  }
}
