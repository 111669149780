import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Title, { label: "Informasi Bank" }),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Nama Rekening",
        value: _ctx.beneficiaryDetail[0]?.bankAccountName
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12 capitalize",
        label: "Nama Bank",
        value: _ctx.beneficiaryDetail[0]?.bankName
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "No. Rekening",
        value: _ctx.beneficiaryDetail[0]?.bankAccountNumber
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Email",
        value: _ctx.beneficiaryDetail[0]?.bankAccountEmail
      }, null, 8, ["value"])
    ])
  ], 64))
}