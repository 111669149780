
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import startCase from "lodash/startCase";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
class Props {
  detailData = prop<DetailClientManagement>({
    required: true,
    default: new DetailClientManagement()
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailParentBranch extends Vue.with(Props) {
  startCase(str: string) {
    return startCase(str);
  }
}
