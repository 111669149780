
import { Options, Vue } from "vue-class-component";
import ContactInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/contact-information.vue";
import Card from "@/app/ui/components/card/index.vue";
import DetailClientParent from "@/app/ui/views/sales/clients-v2/global-components/form-sections/client-parent.vue";
import Tax from "@/app/ui/views/sales/clients-v2/global-components/form-sections/tax.vue";
import PaymentInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/payment-information.vue";
import BillingInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/billing-information.vue";
import Agreement from "@/app/ui/views/sales/clients-v2/global-components/form-sections/agreement.vue";
import BankInfo from "@/app/ui/views/sales/clients-v2/global-components/form-sections/bank-information.vue";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import {
  convertArrayToString,
  convertPhoneNumber,
  formatDateNumber,
  optionBillingSchedule,
  optionCompanySize,
  optionRangeTimePayment,
  optionTaxTransactionCode,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { EditClientRegistrationApiRequestV2 } from "@/data/payload/api/ClientRegistrationApiRequest";
import { capitalize } from "vue";
import { BankDetail } from "@/domain/entities/ClientRegistration";

@Options({
  components: {
    Card,
    DetailClientParent,
    Tax,
    ContactInfo,
    PaymentInfo,
    BillingInfo,
    Agreement,
    BankInfo
  }
})
export default class EditParent extends Vue {
  form = {
    clientParent: {
      id: 0,
      companyName: "",
      clientCategory: "",
      companySize: "" as any,
      address: "",
      district: "" as any,
      email: "",
      phone: "",
      isNeedAssessment: false
    },
    tax: {
      NIKAddress: "",
      NIKName: "",
      taxNumber: "",
      transactionCode: "" as any
    },
    contact: {
      name: "",
      level: "",
      email: "",
      phone: ""
    },
    payment: {
      paymentMethod: "" as any
    },
    billing: {
      noWhatsApp: "",
      billingSchedule: "" as any,
      timeRangePayment: "" as any,
      email: [] as any
    },
    agreement: {
      startDate: "",
      endDate: "",
      attachment: "",
      fileNameAttachment: "",
      attachmentUrl: ""
    }
  };

  error = {
    clientParent: {
      email: false,
      phone: false
    },
    tax: {
      taxNumber: false
    },
    contact: {
      contactEmail: false,
      contactPhone: false
    },
    billing: {
      emailBilling: false,
      contactWhatsApp: false
    },
    agreement: {
      invalidDate: false
    }
  };

  async created() {
    await this.fetchDetail();
  }

  async fetchDetail() {
    const res = !this.isClientManagement
      ? await ClientRegistrationController.getDetailV2(Number(this.id))
      : await ClientManagementController.getDetailV2(Number(this.id));
    if (res) {
      this.onSetValueClient();
    }
  }

  onSetValueClient() {
    const companySize = optionCompanySize.find(
      size =>
        size.value ===
        (this.isClientManagement
          ? this.detailClientManagement.clientCompanySize.toLowerCase()
          : this.detailClient.clientCompanySize.toLowerCase())
    );

    const transactionCode = optionTaxTransactionCode.find(tc => {
      return (
        tc.value.substr(0, 2) ===
        (this.isClientManagement
          ? this.detailClientManagement.clientTax.transactionCode.substr(0, 2)
          : this.detailClient.clientTax.transactionCode.substr(0, 2))
      );
    });

    const billingSchedule = optionBillingSchedule.find(b => {
      return (
        b.value.toLowerCase() ===
        (this.isClientManagement
          ? this.detailClientManagement.clientBilling.billingSchedule
          : this.detailClient.clientBilling.billingSchedule)
      );
    });

    const timeRangePayment = optionRangeTimePayment.find(time => {
      return (
        time.value ===
        (this.isClientManagement
          ? this.detailClientManagement.clientBilling.timeRangePayment
          : this.detailClient.clientBilling.timeRangePayment)
      );
    });

    (this.form.clientParent.id = this.isClientManagement
      ? this.detailClientManagement.id
      : this.detailClient.id),
      (this.form.clientParent.companyName = this.isClientManagement
        ? this.detailClientManagement.clientName
        : this.detailClient.clientName);
    this.form.clientParent.clientCategory = this.isClientManagement
      ? this.detailClientManagement.clientCategory
      : this.detailClient.clientCategory;
    this.form.clientParent.companySize = companySize;
    this.form.clientParent.address = this.isClientManagement
      ? this.detailClientManagement.clientAddress
      : this.detailClient.clientAddress;
    this.form.clientParent.district = this.isClientManagement
      ? this.detailClientManagement.clientDistrict
      : this.detailClient.clientDistrict;
    this.form.clientParent.email = this.isClientManagement
      ? this.detailClientManagement.clientEmail
      : this.detailClient.clientEmail;
    this.form.clientParent.phone = this.isClientManagement
      ? this.detailClientManagement.clientPhone.replace(/^0|^62/g, "")
      : this.detailClient.clientPhone.replace(/^0|^62/g, "");
    this.form.clientParent.isNeedAssessment = this.isClientManagement
      ? this.detailClientManagement.clientIsNeedAssessment
      : this.detailClient.clientIsNeedAssessment;

    this.form.tax.NIKAddress = this.isClientManagement
      ? this.detailClientManagement.clientTax.taxPayerAddress
      : this.detailClient.clientTax.taxPayerAddress;
    this.form.tax.NIKName = this.isClientManagement
      ? this.detailClientManagement.clientTax.taxPayerName
      : this.detailClient.clientTax.taxPayerName;
    this.form.tax.taxNumber = this.isClientManagement
      ? this.detailClientManagement.clientTax.taxNumber
      : this.detailClient.clientTax.taxNumber;
    this.form.tax.transactionCode = transactionCode;

    this.form.contact.email = this.isClientManagement
      ? this.detailClientManagement.clientContactEmail
      : this.detailClient.clientContact.clientContactEmail;
    this.form.contact.level = this.isClientManagement
      ? this.detailClientManagement.clientContactJobTitle
      : this.detailClient.clientContact.clientContactJobTitle;
    this.form.contact.name = this.isClientManagement
      ? this.detailClientManagement.clientContactName
      : this.detailClient.clientContact.clientContactName;
    this.form.contact.phone = this.isClientManagement
      ? this.detailClientManagement.clientContactPhone.replace(/^0|^62/g, "")
      : this.detailClient.clientContact.clientContactPhone.replace(
          /^0|^62/g,
          ""
        );

    this.form.payment.paymentMethod = this.isClientManagement
      ? this.detailClientManagement.clientPaymentMethod
      : this.detailClient.clientPaymentMethod;

    this.form.billing.billingSchedule = billingSchedule;
    this.form.billing.email = this.isClientManagement
      ? this.detailClientManagement.clientBilling.email.map((item: any) => {
          return {
            id: item,
            name: item,
            isError: false
          };
        })
      : this.detailClient.clientBilling.email.map((item: any) => {
          return {
            id: item,
            name: item,
            isError: false
          };
        });
    this.form.billing.noWhatsApp = this.isClientManagement
      ? this.detailClientManagement.clientBilling.noWhatsApp
      : this.detailClient.clientBilling.noWhatsApp;
    this.form.billing.timeRangePayment = timeRangePayment;

    this.form.agreement.attachmentUrl = this.isClientManagement
      ? this.detailClientManagement.clientBilling.attachmentUrl
      : this.detailClient.clientBilling.attachmentUrl;
    this.form.agreement.fileNameAttachment = this.isClientManagement
      ? this.detailClientManagement.clientBilling.attachmentUrl
      : this.detailClient.clientBilling.attachmentUrl;
    this.form.agreement.startDate = this.isClientManagement
      ? this.detailClientManagement.clientBilling.startDate
      : this.detailClient.clientBilling.startDate;
    this.form.agreement.endDate = this.isClientManagement
      ? this.detailClientManagement.clientBilling.endDate
      : this.detailClient.clientBilling.endDate;

    ClientRegistrationController.setBankDetailSettlement(
      new BankDetail({
        accountName: this.isClientManagement
          ? ClientManagementController.detailClientManagement.bankSettlement
              .accountName
          : ClientRegistrationController.clientRegistrationDetail.bankSettlement
              .accountName,
        accountNo: this.isClientManagement
          ? ClientManagementController.detailClientManagement.bankSettlement
              .accountNo
          : ClientRegistrationController.clientRegistrationDetail.bankSettlement
              .accountNo,
        bankLabel: this.isClientManagement
          ? ClientManagementController.detailClientManagement.bankSettlement
              .bankLabel
          : ClientRegistrationController.clientRegistrationDetail.bankSettlement
              .bankLabel,
        bankName: this.isClientManagement
          ? ClientManagementController.detailClientManagement.bankSettlement
              .bankName
          : ClientRegistrationController.clientRegistrationDetail.bankSettlement
              .bankName,
        email: this.isClientManagement
          ? ClientManagementController.detailClientManagement.bankSettlement
              .email
          : ClientRegistrationController.clientRegistrationDetail.bankSettlement
              .email,
        logo: this.isClientManagement
          ? ClientManagementController.detailClientManagement.bankSettlement
              .email
          : ClientRegistrationController.clientRegistrationDetail.bankSettlement
              .logo
      })
    );
  }

  get isClientManagement() {
    return this.$route.params?.params === "management";
  }

  get id() {
    return this.$route.params.id;
  }

  get isLoading() {
    return (
      ClientRegistrationController.isLoading ||
      ClientManagementController.isLoading
    );
  }

  get isError() {
    return (
      ClientRegistrationController.isError || ClientManagementController.isError
    );
  }

  get errorCause() {
    return (
      ClientRegistrationController.isErrorCause ||
      ClientManagementController.isErrorCause
    );
  }

  get detailClient() {
    return ClientRegistrationController.clientRegistrationDetail;
  }

  get detailClientManagement() {
    return ClientManagementController.detailClientManagement;
  }

  goBack() {
    this.$router.back();
  }

  getTitle() {
    return this.$route.meta.name;
  }

  get isFormValidParent() {
    return (
      !!this.form.clientParent.address &&
      !!this.form.clientParent.clientCategory &&
      !!this.form.clientParent.companyName &&
      !!this.form.clientParent.companySize &&
      !!this.form.clientParent.district &&
      !!this.form.clientParent.email &&
      !!this.form.clientParent.phone &&
      !!this.form.tax.NIKAddress &&
      !!this.form.tax.NIKName &&
      !!this.form.tax.taxNumber &&
      !!this.form.tax.transactionCode &&
      !!this.form.billing.billingSchedule &&
      !!this.form.billing.email &&
      !!this.form.billing.noWhatsApp &&
      !!this.form.billing.timeRangePayment &&
      !!this.form.contact.email &&
      !!this.form.contact.level &&
      !!this.form.contact.name &&
      !!this.form.contact.phone &&
      !!this.form.payment.paymentMethod &&
      (!!this.form.agreement.fileNameAttachment ||
        !!this.form.agreement.attachmentUrl ||
        !!this.form.agreement.attachment) &&
      !!this.form.agreement.startDate &&
      !!this.form.agreement.endDate &&
      !this.error.clientParent.email &&
      !this.error.clientParent.phone &&
      !this.error.tax.taxNumber &&
      !this.error.contact.contactEmail &&
      !this.error.contact.contactPhone &&
      !this.error.billing.emailBilling &&
      !this.error.billing.contactWhatsApp &&
      !this.error.agreement.invalidDate
    );
  }

  showPopupRegistParent() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-confirmation-general",
        title: "Ubah Detail Klien Induk?",
        message: `Pastikan data yang Anda ubah sudah sesuai untuk menghindari data ditolak.`,
        textCancel: "Kembali",
        textSuccess: "Ya, Ubah",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: async () => {
          MainAppController.closeMessageModal();
          await this.createClientParent();
        }
      })
    );
  }

  async createClientParent() {
    MainAppController.closeErrorMessage();
    const payload = new EditClientRegistrationApiRequestV2({
      // client parent
      id: Number(this.id),
      companyName: this.form.clientParent.companyName,
      category: this.form.clientParent.clientCategory,
      companySize: capitalize(this.form.clientParent.companySize.value),
      address: this.form.clientParent.address,
      districtCode: this.form.clientParent.district.code,
      email: this.form.clientParent.email,
      phone: convertPhoneNumber(this.form.clientParent.phone),
      isNeedAssessment: this.form.clientParent.isNeedAssessment,
      // tax
      taxpayerName: this.form.tax.NIKName,
      taxpayerAddress: this.form.tax.NIKAddress,
      taxNumber: this.form.tax.taxNumber,
      transactionCode: this.form.tax.transactionCode.value,
      // contact
      contactName: this.form.contact.name,
      contactJobTitle: this.form.contact.level,
      contactEmail: this.form.contact.email,
      contactPhone: convertPhoneNumber(this.form.contact.phone),
      // payment
      paymentMethod: this.form.payment.paymentMethod.value,
      // billing
      billingWaNumber: convertPhoneNumber(this.form.billing.noWhatsApp),
      billingScheduleSo: this.form.billing.billingSchedule.value.startsWith(
        "bi"
      )
        ? "Bi-Weekly"
        : capitalize(this.form.billing.billingSchedule.value),
      billingPaymentPeriod: `${this.form.billing.timeRangePayment.value} Days`,
      billingEmail: convertArrayToString(this.form.billing.email, "name"),
      // agreement
      contractStartDate: formatDateNumber(this.form.agreement.startDate),
      contractEndDate: formatDateNumber(this.form.agreement.endDate),
      contractAttachment: this.form.agreement.attachment,

      // price
      versionBasic: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientVersionBaseRate
        : this.detailClient.clientCurrentRate.clientVersionBaseRate,
      isCustomRate: this.isClientManagement
        ? this.detailClientManagement.clientIsCustomRate
        : this.detailClient.clientIsCustomRate,
      isCustomRateOnly: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientRatePriceType ===
          "custom_client"
        : this.detailClient.clientCurrentRate.clientRatePriceType ===
          "custom_client",
      discount: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientDiscountBaseRate
        : this.detailClient.clientCurrentRate.clientDiscountBaseRate,
      priceRateStartDate: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate
            .clientRateversionStartDate
        : this.detailClient.clientCurrentRate.clientRateVersionStartDate,
      priceRateEndDate: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientRateVersionEndDate
        : this.detailClient.clientCurrentRate.clientRateVersionEndate,
      versionName: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientVersionCustomRate
        : this.detailClient.clientCurrentRate.clientVersionCustomRate,
      archiveFile: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientFileBaseRate
        : this.detailClient.clientCurrentRate.clientFileBaseRate,
      priceType: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate.clientRatePriceType
        : this.detailClient.clientCurrentRate.clientRatePriceType,
      insuranceType: this.isClientManagement
        ? this.detailClientManagement.insuranceType
        : this.detailClient.insuranceType,
      insurancePercentage: this.isClientManagement
        ? this.detailClientManagement.insuranceValuePercentage
        : this.detailClient.insuranceValuePercentage,
      letterCodeOrigin: this.isClientManagement
        ? this.detailClientManagement.clientCurrentRate
            .clientRateVersionCustom3lc
        : this.detailClient.clientCurrentRate.clientRateVersionCustom3lc,
      // other
      clientType: "parent",
      type: "parent",
      isReuploadContract: typeof this.form.agreement.attachment !== "string",

      //bank
      bankName: this.bankDetail.bankName?.value || this.bankDetail.bankName,
      bankAccountName: this.bankDetail.accountName,
      bankAccountNumber: this.bankDetail.accountNo,
      bankAccountEmail: this.bankDetail.email,
      bankAccountLabel: this.bankDetail.bankLabel,
      //bank settlememt
      vaBankName:
        this.bankDetailSettlement.bankName?.name ||
        this.bankDetailSettlement.bankName ||
        "",
      vaBankAccountName: this.bankDetailSettlement.accountName ?? "",
      vaBankNumber: this.bankDetailSettlement.accountNo ?? "",
      vaBankEmail: this.bankDetailSettlement.email,
      vaBankLabel: this.bankDetailSettlement.bankLabel
        ? `${this.bankDetailSettlement.bankLabel.toUpperCase()} Virtual Account`
        : ""
    });

    MainAppController.showLoading();
    ClientRegistrationController.editClientParentV2(payload)
      .then(() => {
        this.$router
          .push(
            `/client/client-${
              this.isClientManagement ? "management" : "registration"
            }/${this.id}`
          )
          .then(() => {
            MainAppController.setSnackbarMessage(
              `Perubahan data klien induk "${this.form.clientParent.companyName}" berhasil!`
            );
            MainAppController.setSnackbarTimeout(4000);
            MainAppController.setSnackbarPositivity(true);
            MainAppController.setSnackbar(true);
          });
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", () =>
            this.createClientParent()
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  get bankDetail(): any {
    return ClientRegistrationController.bankDetail;
  }

  get bankDetailSettlement(): any {
    return ClientRegistrationController.bankDetailSettlement;
  }
}
